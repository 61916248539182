import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import MuiAlert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
//
import EventsList from '../Events/EventsList.jsx'
import { getAssetBearersIncidents } from '../../selectors/incidents'
import { getAssetBearersToTable, getAssetBearersUpdateStatus, getAssetBearer } from '../../selectors/assetBearers'
import { getWalletsToTable, getAssetsList } from '../../selectors/assets'
import AssetBearerForm from '../AssetBearers/AssetBearerForm'
import { CRUD_OPERATIONS } from '../../consts'
import AssetsList from '../Assets/AssetsList'
import AssetBearerModal from '../AssetBearers/AssetBearerModal'
import AddAsset from '../MiniForm/AddAsset'
import { getAssetBearerById } from '../../actions/assetBearers'
const styles = {
  root: {
    marginLeft: '30%',
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    fontWeight: '400',
    backgroundColor: '#f4f4f4',
    color: '#314091',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.1)'
  },
  title: {
    borderBottom: 'solid 2px rgba(0,0,0,0.15)',
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#666',
    letterSpacing: '0.01071em',
    marginTop: '50px',
    marginBottom: '40px',
    paddingBottom: '8px'
  },
  button: {
    margin: '20px auto 0',
    display: 'block'
  }
}

function Message (props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
class EditAssetBearer extends React.Component {

  state = {
    openModal: false,
    _await: true,
    assetBearer: null
  }

  componentDidMount () {
    this.props.getAssetBearerById(this.props.selectedAssetBearerId)
    setTimeout(() => this.setState({ _await: false }), 1000)
  }

  componentWillUnmount () {}

  openModal = () => {
    this.setState({openModal: true})
  }

  handleCloseModal = () => {
    this.setState({openModal: false})
  };

  getAssetBearer = (val) => {
    let filter = this.props.assetBearers.filter(assetBearer => assetBearer._id === val)
    let selectedAssetBearer = filter[0] ? filter[0] : this.props.assetBearer
    return selectedAssetBearer
  }
  onDelete = () => {
    this.props.history.push(`/assetBearers`)
  }

  getAssetBearerIncidents = (assets) => {
    const { incidents } = this.props
    const assetsIds = assets.map(asset => asset._id)
    let filter = incidents.filter(incident => assetsIds.includes(incident.asset)  )
    return filter.length > 0 ? filter.map(obj => ({ ...obj, dismissed: obj.dismissedByUser ? 'dismissed' : 'not dismissed' })) : null
  }

  render () {
    const { classes, assets, wallets, selectedAssetBearerId} = this.props
    let assetBearer = this.getAssetBearer(selectedAssetBearerId)
    let incidentsByAssetBearer = this.getAssetBearerIncidents(assets)
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <h6 className={classes.title}>Person</h6>
          <AssetBearerForm selectedAssetBearer={ assetBearer } operation={CRUD_OPERATIONS.edit} onDelete={this.onDelete}/>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {typeof assetBearer._id !== 'undefined'
            ? <>
              <h6 className={classes.title}>Assets</h6>
              {this.state._await
                ? <LinearProgress />
                : assets.length > 0
                  ? <AssetsList
                    assets={assets}
                    wallets={wallets}
                  />
                  : <Message severity="success" className={classes.message}>There are not associated assets</Message>
              }
              {!this.state._await && <Button 
                type="button" 
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => this.openModal()}
                >Add Asset</Button>
              }
              </>
            : null
          }
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {typeof assetBearer._id !== 'undefined'
            ? <>
              <h6 className={classes.title}>Incidents</h6>
              {this.state._await
                ? <LinearProgress />
                : incidentsByAssetBearer!== null
                  ? <EventsList
                    assetBearerLink={!1}
                    incidents={incidentsByAssetBearer}
                  />
                  : <Message severity="success" className={classes.message}>There are no associated incidents</Message>
              }
              </>
            : null
          }
        </GridItem>
        <AssetBearerModal 
          operation={ 'addAsset' }
          open={ this.state.openModal } 
          handleClose={ this.handleCloseModal }>
            <AddAsset assetBearerId={selectedAssetBearerId} onComplete={this.handleCloseModal}></AddAsset>
        </AssetBearerModal>
      </GridContainer>
    )
  }
}
const mapStateToProps = state => ({
  assetBearers: getAssetBearersToTable(state),
  assetBearer: getAssetBearer(state),
  incidents: getAssetBearersIncidents(state),
  assets: getAssetsList(state),
  wallets: getWalletsToTable(state),
  status: getAssetBearersUpdateStatus(state),
})

const mapDispatchToProps = {
  getAssetBearerById
}

EditAssetBearer.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAssetBearerId: PropTypes.string.isRequired
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EditAssetBearer)

import React, { useState } from 'react'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import TitleWithHelp from 'components/Titles/TitleWithHelp'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const styles = {
  form: {
    padding: '0 5%'
  },
  save: {
    width: '100px',
    marginTop: '70px'
  },
  checkboxLabel: {
    color: '#555555'
  },
  input: {
    width: '100%',
    marginTop: '20px'
  },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
}

const ClaimAWalletForm = ({ values, updateFunction, classes }) => {

  console.log(values)

  const initialFormData = {
    regex: values?.regex,
    claimAWalletEnabled: values?.claimAWalletEnabled,
    oktaIssuer: values?.oktaIssuer,
    certificate: values?.certificate,
    emailList: values?.emailList || []
  }

  const [formData, setFormData] = useState(initialFormData)
  const [emailToList, setEmailToList] = useState('')
  const [wrongEmail, setWrongEmail] = useState(false)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleEmailChange = (event) => {
    if (/^\S+@\S+\.\S+$/.test(event.target.value)) {
      setWrongEmail(false)
    } else {
      setWrongEmail(true)
    }
    setEmailToList(event.target.value)
  }

  const handleFile = (event) => {
    const fileReader = new FileReader();
    const file = event.target.files[0]

    fileReader.onload = function (event) {
      const csvOutput = event.target.result;
      setFormData({
        ...formData,
        emailList: csvOutput.split('\n')
      })
    };

    fileReader.readAsText(file);
  }
  const deleteEmail = (email) => {
    const newList = formData.emailList.filter(listEmail => listEmail !== email)
    setFormData({
      ...formData,
      emailList: newList
    })
  }

  const addEmailToList = () => {
    if (!formData?.emailList.includes(emailToList) && emailToList.length > 0) {
      setFormData({
        ...formData,
        emailList: [...formData.emailList, emailToList]
      })
      setEmailToList('')
    }
  }

  const handleCheckboxChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    })
  }

  return (<ValidatorForm
    onSubmit={() => updateFunction(formData)}
    className={classes.form}
    instantValidate={true} >
    <TitleWithHelp
      title='CLAIM A WALLET'
      description='Configuration for Claim a Wallet feature'
    />
    <GridContainer>
      <GridItem xs={8} sm={8} md={6}>
        <FormControlLabel
          className={classes.checkboxLabel}
          control={<Checkbox
            name="claimAWalletEnabled"
            onChange={handleCheckboxChange}
            checked={formData?.claimAWalletEnabled}
            label="Enable Claim a Wallet feature"
            color="primary" />}
          label="Enable claim a wallet feature"
        />
        <TextValidator
          label='Regex'
          onChange={handleChange}
          name='regex'
          value={formData?.regex}
          className={classes.input}
          validators={['required']}
          errorMessages={['this field is required']}
        />
        <TextValidator
          label='Okta Issuer Value'
          onChange={handleChange}
          name='oktaIssuer'
          value={formData?.oktaIssuer}
          className={classes.input}
          validators={['required']}
          errorMessages={['this field is required']}
        />
        <TitleWithHelp
          title='CLAIM A WALLET EMAIL WHITELIST'
          description='Configuration for Claim a Wallet feature'
        />
        <input
          className={classes.input}
          type={'file'}
          accept='.csv'
          onChange={handleFile}
        >
        </input>
        <TextField error={wrongEmail}
          label='Add Email To List'
          onChange={handleEmailChange}
          name='emailToList'
          value={emailToList}
          className={classes.input}
        />
        <IconButton>
          <AddIcon onClick={() => addEmailToList()}></AddIcon>
        </IconButton>
        <List className={classes.list}>
          {formData.emailList.map(email => <ListItem key={email}>
            <ListItemText>{email}</ListItemText>
            <ListItemSecondaryAction onClick={() => deleteEmail(email)}>
              <IconButton>
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)}
        </List>
      </GridItem>


      <GridItem xs={8} sm={8} md={6}>


        <TextValidator
          label='Certificate'
          onChange={handleChange}
          name='certificate'
          value={formData?.certificate}
          className={classes.input}
          multiline
          minRows={10}
          maxRows={10}
          variant='filled'
          validators={['required']}
          errorMessages={['this field is required']}
        />

      </GridItem>


      <Button
        className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
        color='primary'
        variant='contained'
        type='submit'
      >
        {'Save'}
      </Button>
    </GridContainer>
  </ValidatorForm>)

}

export default withStyles(styles)(ClaimAWalletForm)

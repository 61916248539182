import { createSelector } from 'reselect'

const getSummary = (state) => state.summary

export const getLastSummary = createSelector(
  [getSummary],
  (summary) => {
    return Array.isArray(summary) && summary.length ? summary.slice(-1)[0] : {}
  })

export const getAssetsSummary = createSelector(
  [getLastSummary],
  (summary) => {
    return summary.assets || {}
  }
)

export const getPeoplesSummary = createSelector(
  [getLastSummary],
  (summary) => {
    return summary.people || {}
  }
)

export const getHistoryAssets = createSelector(
  [getSummary],
  (summary) => {
    return (Object.values(summary).map(summary => ({
      date: summary.createdAt,
      value: summary.assets?.protected,
      total: summary.assets?.total,
      estimate: summary.assets?.estimated
    })))
  })

export const getHistoryPeople = createSelector(
  [getSummary],
  (summary) => {
    return (Object.values(summary).map(summary => ({
      date: summary.createdAt,
      value: summary.people?.protected,
      total: summary.people?.total,
      estimate: summary.people?.estimated
    })))
  })

import * as actions from 'consts/action-types'
import { setUpdateAssetStatus, setUiDialog } from 'actions/ui'
import * as schema from 'lib/schema'
import { mergeAsset } from './assets'

export const setWallets = (payload) => ({
  type: actions.SET_WALLETS,
  payload
})

export const mergeWallet = (payload) => ({
  type: actions.WALLET_MERGE,
  payload
})

export const notifyInstall = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/assets/${id}/wallet/installed`,
    method: 'POST',
    schema: schema.asset,
    success: ({ entities }) => [
      mergeWallet(entities.wallets)
    ],
    label: 'notify_install',
    data: {
      walletId: id
    }
  }
})

export const notifyAssetInstall = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/assets/${id}/asset/installed`,
    method: 'POST',
    schema: schema.asset,
    success: ({ entities }) => [
      mergeWallet(entities.wallets)
    ],
    label: 'notify_install',
    data: {
      walletId: id
    }
  }
})



export const deleteWallet = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/assets/${id}/wallet`,
    method: 'DELETE',
    schema: schema.asset,
    success: ({ entities }) => [
      setUiDialog(false),
      mergeAsset(entities.assets)
    ],
    error: (message) => setUpdateAssetStatus(message),
    label: 'delete_wallet',
    data: {
      id: id
    }
  }
})

export const deleteTransaction = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/assets/${id}/transaction`,
    method: 'DELETE',
    schema: schema.asset,
    success: ({ entities }) => [
      setUiDialog(false),
      mergeAsset(entities.assets)
    ],
    error: (message) => setUpdateAssetStatus(message),
    label: 'delete_wallet',
    data: {
      id: id
    }
  }
})

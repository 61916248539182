import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import { withRouter } from 'react-router-dom'
import CardBody from '../Card/CardBody'
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle'
import LineChart from '../Chart/LineChart'
import { percentage } from '../../utils'

class TimelineCard extends Component {
  render () {
    const { classes, title, axisTitle, data } = this.props

    return (
      <Card chart>
        <CardHeader color='primary'>
          <LineChart data={this.mapTimeline(data)} axisTitle={axisTitle} />
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>{title}</h4>
        </CardBody>
      </Card>
    )
  }

  mapTimeline (data, interval = 7) {
    return data.map(obj => ({
      date: obj.date,
      value: percentage(obj.value, obj.total, obj.estimate)
    }))
  }
}

TimelineCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(connect()(withStyles(dashboardStyle)(TimelineCard)))

import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from 'consts/action-types'

const initialState = Immutable({})

export default handleActions({
  [actions.SET_WALLETS]: (wallets, { payload }) => Immutable(payload),
  [actions.WALLET_MERGE]: (wallets, { payload }) => wallets.merge(Immutable(payload))
}, initialState)

import React from 'react'
import { withStyles } from '@material-ui/styles'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FileCopyOutlined } from '@material-ui/icons'
import copy from 'copy-to-clipboard';

const styles = {
  listItem: {
    borderRadius: '5px',
    width: '80%',
    margin: '0 10%',
    '& .icon': {
      visibility: 'hidden'
    },
    '&:hover': {
      background: '#3F51B5',
      color: '#fff',
      '& .icon': {
        visibility: 'visible'
      },
    },
  }
}
const WebhookDataSetItem = ({classes, description, text}) => {
  return (
    <ListItem button className={classes.listItem} onClick={() => copy(text)} title={description}>
      <ListItemText primary={text} />
      <FileCopyOutlined className='icon' />
    </ListItem>
  )
}
export default withStyles(styles)(WebhookDataSetItem)

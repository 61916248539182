import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import auth0Client from '../components/Login/auth'
//
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const styles = theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
})

class Callback extends Component {
  async componentDidMount () {
    await auth0Client.handleAuthentication()
    this.props.history.replace('/')
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root} >
        <LinearProgress />
      </div>
    )
  }
}

Callback.propTypes = {
  classes: PropTypes.object.isRequired
}

const enhance = compose(
  withRouter,
  withStyles(styles)
)

export default enhance(Callback)

import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import LinearProgress from '@material-ui/core/LinearProgress'
import withStyles from '@material-ui/core/styles/withStyles'

import auth0Client from '../components/Login/auth'
import Navbar from '../components/Navbars/Navbar.jsx'
import Sidebar from '../components/Sidebar/Sidebar.jsx'
import { getAuthentication } from '../actions/session'
import { ADMIN_ROLE, USER_ROLE } from '../consts'
import getRoutes from '../routes.js'

import 'perfect-scrollbar/css/perfect-scrollbar.css'
import dashboardStyle from '../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'
import image from '../assets/img/sidebar-2.jpg'
import logo from '../assets/img/logo.svg'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image: image,
      color: 'blue',
      hasImage: true,
      fixedClasses: 'dropdown show',
      mobileOpen: false,
      isAdmin: false
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  async componentDidMount() {
    window.addEventListener('resize', this.resizeFunction)
    this.props.getAuthentication()
    this.setState({ isAdmin: await auth0Client.isAdmin()})
  }

  componentDidUpdate (e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeFunction)
  }

  render () {
    const { classes, ...rest } = this.props
    if (!auth0Client.isAuthenticated())
      return (
        <div className={classes.root}>
          <LinearProgress/>
        </div>
      )
    const routes = getRoutes(this.state.isAdmin ? ADMIN_ROLE : USER_ROLE)
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={'Bit trap'}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <Navbar
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              {
                <Switch>
                  {routes.map((prop, key) => {
                    return <Route exact path={prop.path + prop.params} key={key} component={prop.component} />
                  })}
                </Switch>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapDispatchToProps = {
  getAuthentication
}

const mapStateToProps = state => ({
  login: false
})

const enhance = compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)
export default enhance(Dashboard)

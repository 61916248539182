import React from 'react'
import IncidentsTable from 'components/IncidentsTable/IncidentsTable.js'
import SimplePage from '../../components/Templates/SimplePage'

function IncidentsList () {
  return (
    <SimplePage title='Incidents'>
      <IncidentsTable />
    </SimplePage>
  )
}

export default IncidentsList

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, InputAdornment} from '@material-ui/core'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { Error as ErrorIcon, MailOutline } from '@material-ui/icons'
import { CRUD_OPERATIONS } from '../../consts'
import { getFieldsAssetBearer } from './AssetBearerUtils'
import AssetBearerDialog from './AssetBearerDialog'
import { makeStyles } from '@material-ui/core/styles'
import { getAssetBearersUpdateStatus } from '../../selectors/assetBearers'
import { addAssetBearer, updateAssetBearer, deleteAssetBearer } from '../../actions/assetBearers'
import { setAddAssetBearerStatus, setUpdateAssetBearerStatus } from '../../actions/ui'
import phone from 'phone'

const useStyles = makeStyles({
  textBox: {
    width: "100%",
    margin: "5px 0",
  },
  buttonsContainer: {
    display: 'flex'
  },
  button: {
    minWidth:'30%',
    margin: '20px auto 0',
    display: "block"
  },
  icon: {
    color: '#3f51b5'
  },
});

const AssetBearerForm = (props) => {
  const { handleClose, operation, selectedAssetBearer, onDelete} = props

  const [formData, setFormData] = useState()
  const [onOperation, setOnOperation] = useState(false)
  const [dialogResponse, setDialogResponse] = useState(false)

  useEffect(() => {
    selectedAssetBearer && setFormData(selectedAssetBearer)
  },[selectedAssetBearer])

  const cleanStatus = () => {
    ValidatorForm.addValidationRule('isPhone', (value) => {
      if (!value || phone(value).length || phone('+' + value).length) {
          return true;
      }
      return false;
    });
    props.setAddAssetBearerStatus('')
    props.setUpdateAssetBearerStatus('')
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cleanStatus(),[])

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const isStatusOk = () => {
    return props.statusMessage.indexOf('OK') !== -1
  }

  const callbackSuccessDeleteAssetBearer = () => {
    callbackSuccessAssetBearer()
    onDelete && onDelete()
  }

  const callbackSuccessAddAssetBearer = (newAssetBearerId) => {
    callbackSuccessAssetBearer()
    props.history.push(`/assetBearer/${newAssetBearerId}`)
  }

  const callbackSuccessAssetBearer = () => {
    setOnOperation(false)
    handleClose && handleClose()
  }


  const callbackErrorAssetBearer = () => {
    setOnOperation(false)
  }

  const handleSubmit = () => {
    setOnOperation(true);
    operation === CRUD_OPERATIONS.add 
      ? props.addAssetBearer(formData, callbackSuccessAddAssetBearer, callbackErrorAssetBearer )
      : props.updateAssetBearer(getFieldsAssetBearer(formData), () => callbackSuccessAssetBearer, () => callbackErrorAssetBearer)
  }

  const dialogConfirm = (bool) => {
    setDialogResponse( bool )
   }

  const dialogConfirmDelete = () => {
    setDialogResponse(false)
    props.deleteAssetBearer(selectedAssetBearer?._id, () => callbackSuccessDeleteAssetBearer, () => callbackErrorAssetBearer)
  }

  const showSnackbar = () => {
    return !isStatusOk() && props.statusMessage !== ''
  }
  const classes = useStyles();

  return (
    <ValidatorForm
    instantValidate={true}
    onSubmit={handleSubmit}
    >
    { showSnackbar()
      ? <SnackbarContent
        message={props.statusMessage}
        open={!isStatusOk()}
        color='primary'
        icon={ ErrorIcon }
        closeNotification={cleanStatus}
        close
      />
      : null
    }
    <TextValidator
      label='Email *'
      id='email'
      onChange={handleChange}
      name='email'
      value={formData?.email}
      validators={['required','isEmail']}
      errorMessages={['The email is required', 'The email is not valid']}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.email }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <MailOutline className={classes.icon} />
          </InputAdornment>
        )
      }}
    />
    <TextValidator
      label='Name'
      id='name'
      onChange={handleChange}
      name='name'
      value={formData?.name}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.name }}

    />
    <TextValidator
      label='Surname'
      id='surname'
      onChange={handleChange}
      name='surname'
      value={formData?.surname}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.surname }}
    />
    <TextValidator
      label='Address'
      id='address'
      onChange={handleChange}
      name='address'
      value={formData?.address}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.address }}
    />
    <TextValidator
      label='Phone'
      id='phone'
      onChange={handleChange}
      name='phone'
      value={formData?.phone}
      validators={['isPhone', 'matchRegexp:^[0-9-()+ ]*$']}
      errorMessages={['The phone is not valid', 'The phone is not valid']}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.phone }}
    />
    <TextValidator
      label='Estimated number of devices'
      id='estimatedDevices'
      onChange={handleChange}
      name='estimatedDevices'
      value={formData?.estimatedDevices}
      validators={['isNumber']}
      errorMessages={['The estimated number of devices is not valid']}
      className={classes.textBox}
      InputLabelProps={{ shrink: !!formData?.estimatedDevices }}
    />
    <div className={classes.buttonsContainer}>
    <Button 
    type="submit" 
    color="primary"
    variant="contained"
    className={classes.button}
    disabled={onOperation}
    >Save</Button>

    {
    operation === CRUD_OPERATIONS.edit && <Button 
      type="button" 
      color="primary"
      variant="contained"
      className={classes.button}
      disabled={onOperation}
      onClick={() => dialogConfirm(true)}
    >Delete Person</Button>
    }
    </div>
    <AssetBearerDialog
      open={dialogResponse}
      confirm={dialogConfirmDelete}
      cancel={dialogConfirm}
      title={"Really want to delete this person?"}
    />
    </ValidatorForm>
  )
}
const mapStateToProps = state => ({
  statusMessage: getAssetBearersUpdateStatus(state)
})

const mapDispatchToProps = {
  addAssetBearer,
  updateAssetBearer,
  deleteAssetBearer,
  setAddAssetBearerStatus,
  setUpdateAssetBearerStatus
}

AssetBearerForm.propTypes = {
  operation: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  selectedAssetBearer: PropTypes.object
}

export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps))(AssetBearerForm)

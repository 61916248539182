import { createSelector } from 'reselect'
import { sortImmutable } from '../utils'

const getWebhook = (state, id) => getWebhooksToTable(state).filter(x => x._id === id)[0]
const getWebhooks = (state) => state.webhooks
const getUI = state => state.ui

export const getWebhooksToTable = createSelector(
  [getWebhooks],
  (webhooks) => {
    return Object.keys(sortImmutable(webhooks.data)).map(_id => webhooks.data[_id])
  }
)

export const getWebhookById = createSelector(
  [getWebhook],
  (webhook) => {
    return webhook
  }
)

export const getWebhooksUpdateStatus = createSelector(
  [getUI],
  (ui) => {
    return ui.addWebhookStatus
  }
)

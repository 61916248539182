import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Snack from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import snackbarContentStyle from 'assets/jss/material-dashboard-react/components/snackbarContentStyle.jsx'
import Fade from '@material-ui/core/Fade';

const SnackbarContent = ({ classes, message, color, close, open, rtlActive, closeNotification, autoHide = 0, ...otherProps }) => {
  let action = []
  const messageClasses = classNames({
    [classes.iconMessage]: otherProps.icon !== undefined
  })
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => closeNotification()}
      >
        <Close className={classes.close} />
      </IconButton>
    ]
  }

    open && autoHide && setTimeout( () => {
      open && closeNotification();
    }, autoHide);

  return (
    <Fade in={open}>
      <Snack
        message={
          <div>
            { otherProps.icon !== undefined ? <otherProps.icon className={classes.icon} /> : null}
            <span className={messageClasses}>{message}</span>
          </div>
        }
        classes={{
          root: classes.root + ' ' + classes[color],
          message: classes.message,
          action: classNames({ [classes.actionRTL]: rtlActive })
        }}
        open={open}
        action={action}
      />
    </Fade>
  )
}

SnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
  close: PropTypes.bool,
  open: PropTypes.bool,
  icon: PropTypes.object,
  rtlActive: PropTypes.bool,
  autoHide: PropTypes.number,
  closeNotification:PropTypes.func
}

export default withStyles(snackbarContentStyle)(SnackbarContent)

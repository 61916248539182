import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from 'assets/jss/material-dashboard-react.jsx'

const dashboardStyle = {
  cardPercent: {
    display: 'inline-block',
    margin: '0',
    marginLeft: '12px',
    fontSize: '55%',
    color: 'gray'
  },
  successText: {
    color: successColor[0]
  },
  cardIcon:{
    float: 'none!important',
    padding: '15px',
    marginTop: '-20px',
    marginRight: '15px',
    borderRadius: '3px',
    backgroundColor: '#999',
    maxWidth: '90px',
    textAlign: 'center!important'
  },
  empty : {
    backgroundColor:'rgba(0,0,0,0)',
    border:'0',
    height:'20px',
    margin: '0'
  },
  message: {
    fontWeight: '400',
    backgroundColor: '#f4f4f4',
    color: '#314091',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.1)'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0'
  },
  cardCategoryWhite: {
    color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  dashTitle: {
    color: grayColor[3],
    paddingTop: '20px',
    minHeight: '180%',
    fontWeight: '400',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: '14px',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  subtitle: {
    display: 'inline-block',
    fontSize: '1.8em',
    color: 'rgb(136,136,136)',
    textIndent: '0.5em',
    marginTop: '20px',
    marginBottom: '20px'
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1'
    }
  }
}

export default dashboardStyle

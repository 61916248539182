import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updateAsset, deleteAsset } from '../../actions/assets'
import { deleteWallet } from 'actions/wallets'
import SnackbarContent from '../Snackbar/SnackbarContent'
import { setUpdateAssetStatus } from 'actions/ui'
import { getUpdateAssetStatus } from 'reducers/ui'
import Error from '@material-ui/icons/Error'
import Check from '@material-ui/icons/Check'
import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { default as SelectEdit } from '@material-ui/lab/Autocomplete'
import { osOptions } from '../../consts'
import { MSG_STATUS } from '../../consts/assets'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'


const styles = {
  delete:{
    backgroundColor: '#9396bd',
    width:'45%',
    whiteSpace: 'nowrap',
    minWidth:'120px',
    marginTop: '10px',
    textShadow: '1px 1px 5px rgba(15,30,50,.3)'
  },
  save:{
    minWidth:'100px',
    marginRight: '5%',
    width:'45%',
    marginTop: '10px'
  },
  status: {
    display: 'inline-flex',
    fontSize: '12px!important',
    lineHeight: '12px',
    marginBotom: '10px'
  },
  splitter: {
    marginTop: '4rem',
    display: 'block',
    border: '0',
    background: 'transparent'
  },
  margin: {
    width: '100%'
  }
}


class EditAsset extends React.Component {
    constructor(props) {
      super(props);
      ValidatorForm.addValidationRule('isOptionSelectedInList', (value) => {
        if (!osOptions.includes(value)) {
            return false;
        }
        return true;
      });
    }
    state = {
      formData: {
        id: this.props.selectedAsset._id,
        os: this.props.selectedAsset.os,
        name: this.props.selectedAsset.name,
        wallet: this.props.selectedAsset.wallet
      },
      snackbar: true,
      submitted: false,
      confirm: false
    }

    componentDidMount () {
      this.props.setUpdateAssetStatus('')
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleChange = (event) => {
      const { formData } = this.state
      formData[event.target.name] = event.target.value
      this.setState({ formData })
    }

    dialogConfirm = (bool) => {
      this.setState({ confirm: bool })
    }

    dialogConfirmDelete = () => {
      this.setState({ confirm: false, submitted: false })
      this.props.deleteAsset(this.state.formData.id)
    }

    callbackUpdateAsset = () => {
      this._isMounted && this.setState({ submitted: false })
    }

    handleSubmit = () => {
      this.setState({ submitted: true })
      this.props.updateAsset(this.state.formData, () => this.callbackUpdateAsset)
    }

    render () {
      const { formData, submitted, snackbar, confirm } = this.state
      const classes = this.props.classes
      const icon = (
        (this.props.status === MSG_STATUS.CREATED_OK)
          ? Check : Error
      )
      return (
        <span>
          <ValidatorForm
            onSubmit={this.handleSubmit}
            instantValidate={true} >
            {this.props.status !== ''
              ? <SnackbarContent
                message={this.props.status}
                open={snackbar}
                color='primary'
                icon={icon}
                closeNotification={() => this.props.setUpdateAssetStatus('')}
                close
              />
              : null
            }
            <h6 >Edit Asset</h6>
            <TextValidator
              label='Name'
              onChange={this.handleChange}
              name='name'
              value={formData.name}
              className={classes.margin}
              validators={['required']}
              errorMessages={['this field is required']}
            />
            <hr className={classes.splitter} />
            <SelectEdit
              value={formData.os}
              options={osOptions.map((option) => option)}
              onBlur={this.handleChange}
              renderInput={(params) => (
                <TextValidator 
                  {...params} 
                  value={formData.os}
                  name='os'
                  label='OS' 
                  variant='standard' 
                  validators={['required', 'isOptionSelectedInList']}
                  errorMessages={['this field is required', 'Enter an os of the list']}
                />
              )}
            />
            <hr className={classes.splitter} />
            <Button
              className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
              color='primary'
              variant='contained'
              type='submit'
              disabled={submitted}
            >
              {'Save'}
            </Button>
            <Button
              className={`RegularButton-primary-200 RegularButton-button-197 ${classes.delete}`}
              color='primary'
              variant='contained'
              type='button'
              disabled={submitted}
              onClick={() => this.dialogConfirm(true)}
            >
              {'Delete Asset'}
            </Button>
            <Dialog open={confirm} >
              <DialogContent>
                            Really want to delete this asset?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.dialogConfirm(false)} color='primary'>
                                CANCEL
                </Button>
                <Button onClick={() => this.dialogConfirmDelete(false)} color='primary'>
                                CONFIRM
                </Button>
              </DialogActions>
            </Dialog>
          </ValidatorForm>
        </span>
      )
    }
}


const mapStateToProps = state => ({
  assets: Object.keys(state.assets).map(_id => state.assets[_id]),
  status: getUpdateAssetStatus(state)
})

const mapDispatchToProps = {
  updateAsset,
  deleteAsset,
  deleteWallet,
  setUpdateAssetStatus
}
EditAsset.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAsset: PropTypes.object.isRequired
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EditAsset)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import CloseIcon from '@material-ui/icons/Close';
import ActionButton from '../Buttons/ActionButton'
import Slide from '@material-ui/core/Slide';
import {titles} from './AssetBearerUtils'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    margin: "10px 0",
  },
  close: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.54)",
    position: "absolute",
    right: "0",
    top: "0",
  }
}));
const AssetBearerModal = (props) => {
  const { open, handleClose, operation, children} = props
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
    >
      <Slide direction="up" in={open}>
      <div className={classes.paper}>
        <ActionButton handleClick={handleClose} customClass={classes.close}>
          <CloseIcon/>
        </ActionButton>
        <div className={classes.title}>{titles[operation]}</div>
        {children}
      </div>
      </Slide>
    </Modal>
  )
}

AssetBearerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default AssetBearerModal

import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'

const AssetBearerDialog = (props) => {
  const { cancel, confirm, open, title} = props

  return (
    <Dialog open={open}>
    <DialogContent>{title}</DialogContent>
    <DialogActions>
      <Button onClick={() => cancel(false)} color='primary'>CANCEL</Button>
      <Button onClick={() => confirm(false)} color='primary'>CONFIRM</Button>
    </DialogActions>
    </Dialog>

  )
}

AssetBearerDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
export default AssetBearerDialog

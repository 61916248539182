import * as actions from 'consts/action-types'
import { plainOnbjectAndCompareResult } from '../utils'

export const setSummary = (payload) => ({
  type: actions.SET_SUMMARY,
  payload
})

export const fetchSummary = (callback) => {
  return ({
    type: actions.API_WITH_CONTROL,
    payload: {
      url: '/api/summary',
      success: (data, dispatch, getState) => {
        const { summary } = getState()
        data && !plainOnbjectAndCompareResult(summary, data) && dispatch(setSummary(data))
        callback && callback()
      },
      label: 'summary'
    },
    meta: {
      throttle: 1000
    }
  })
}

import { ReactComponent as Apple } from './apple.svg'
import { ReactComponent as Android } from './android.svg'
import { ReactComponent as Linux } from './linux.svg'
import { ReactComponent as Darwin } from './darwin.svg'
import { ReactComponent as Windows } from './windows.svg'
import { ReactComponent as GenericOs } from './generic_os.svg'
import { ReactComponent as Node } from './node.svg'
import { ReactComponent as WalletNotInstalled } from './wallet-not-installed.svg'
import { ReactComponent as WalletInstalled } from './wallet-installed.svg'
import { ReactComponent as Asset } from './asset.svg'
import { ReactComponent as Incident } from './incident.svg'
import { ReactComponent as People } from './people.svg'
import { ReactComponent as PeopleProtected } from './people-protected.svg'
import { ReactComponent as PeopleAlert } from './people-alert.svg'
import { ReactComponent as PeopleSecure } from './people-secure.svg'
import { ReactComponent as PeopleInfo } from './people-info.svg'
import { ReactComponent as Ios } from './ios.svg'

const symbols = {
  people: People,
  'people-protected': PeopleProtected,
  'people-alert': PeopleAlert,
  'people-secure': PeopleSecure,
  'people-info': PeopleInfo,
  other: GenericOs,
  Ios: Ios,
  Windows: Windows,
  Darwin: Darwin,
  Linux: Linux,
  Android: Android,
  macos: Apple,
  MacOS: Apple,
  win32: Windows,
  'win-x86': Windows,
  windows: Windows,
  darwin: Darwin,
  linux: Linux,
  android: Android,
  ios: Ios,
  node: Node,
  'linux-x86': Linux,
  'linux-x64': Linux,
  'mac-x64': Apple,
  'win-x64': Windows,
  'wallet-unprotected': WalletNotInstalled,
  'wallet-protected': WalletInstalled,
  asset: Asset,
  incident: Incident
}

export default symbols

import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Icon from '../../components/Icon/Icon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CircularProgress from '@material-ui/core/CircularProgress'

import Typography from '@material-ui/core/Typography'
import AddAsset from '../../components/MiniForm/AddAsset'
import { grey } from '@material-ui/core/colors'
import auth from '../../components/Login/auth'
import DownloadBlobWithAuth from '../../components/Buttons/DownloadBlobWithAuth'
import { downloadFromLink } from '../../utils'

function TabContainer (props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  toolbar: {
    backgroundColor: grey[200],
    color: grey[900],
    marginTop: '20px'
  },
  avatar: {
    backgroundColor: '#3f51b5',
    color: '#fff'
  },
  textItem: {
    textTransform: 'uppercase'
  }
})

class AssetsTools extends React.Component {
  state = {
    value: 0,
    builderLoading: null
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleBuilderClick = (builder, link, url) => {
    if (link.current.href) return
    const linkRef = link.current
    this.setState({ builderLoading: builder }, async () => await downloadFromLink(linkRef, url, ()=> this.setState({ builderLoading: null })))
  }

  render () {
    const { classes } = this.props
    const { value } = this.state
    const builders = ['mac-x64', 'linux-x86', 'linux-x64', 'win-x86', 'win-x64']
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.toolbar} >
          <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Add Asset" />
            <Tab label="Deployers" />
          </Tabs>
        </AppBar>
        {value === 0 &&
          <TabContainer><AddAsset /></TabContainer>}
        {value === 1 &&
          <TabContainer>
            <List>
              {builders.map(platform => {
                let expirationDate
                let url = `/api/deployer?platform=${platform}`
                if (expirationDate) url.concat(`&expiresAt=${expirationDate}`)
                return (
                  <DownloadBlobWithAuth url={url} filename='bittrap' key={platform} id={platform} onClick={(link, url) => this.handleBuilderClick(platform, link, url)}>

                    <ListItem>
                      <ListItemAvatar>
                        {this.state.builderLoading === platform
                          ? <CircularProgress className={classes.fabProgress} size="40px"/>
                          : <Avatar className={classes.avatar}>
                            <Icon name={platform} color="white" size="1.2em" />
                          </Avatar>
                        }

                      </ListItemAvatar>
                      <ListItemText className={classes.textItem} primary={`${platform}`} />
                    </ListItem>

                  </DownloadBlobWithAuth>
                )
              })}
            </List>
          </TabContainer>}
      </div>
    )
  }
}

AssetsTools.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AssetsTools)

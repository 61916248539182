import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Route, Router, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
// core components
import Admin from './layouts/Admin.jsx'
import Callback from './layouts/Auth0Callback'
import './assets/css/material-dashboard-react.css?v=1.6.0'

const hist = createBrowserHistory()

ReactDOM.render(
  <Provider store={ store }>
    <Router history={ hist }>
      <Switch>
        <Route exact path='/callback' component={ Callback }/>
        <Route path="/" component={ Admin } />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

import { combineReducers } from 'redux'
import wallets from './wallets'
import assets from './assets'
import assetBearers from './assetBearers'
import incidents from './incidents'
import summary from './summary'
import ui from './ui'
import organizations from './organizations'
import settings from './settings'
import webhooks from './webhooks'

export default combineReducers({
  wallets,
  assets,
  assetBearers,
  incidents,
  summary,
  ui,
  organizations,
  settings,
  webhooks
})

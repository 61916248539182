import React from 'react'
import OrganizationSettings from '../../components/Configuration/OrganizationSettings.js'
import SimplePage from '../../components/Templates/SimplePage'

const Configuration = () => (
  <SimplePage title='Configuration'>
    <OrganizationSettings />
  </SimplePage>
)

export default Configuration

import React, { createRef } from 'react'
import { withStyles } from '@material-ui/styles'

const styles = {
  downloadLink: {
    display: 'block',
    cursor: 'pointer',
    borderRadius: '5px',
    height: '58px',
    "&:hover": {
      backgroundColor: '#e9e9e9'
    },
  }
}

const DownloadBlobWithAuth = ({ url, children, onClick, id, classes }) => {
  const link = createRef()
  return (
    <>
      <a role='button' className={`${classes.downloadLink}`} ref={link} onClick={() => onClick(link, url)} id={id}>{children}</a>
    </>
  )
}

export default withStyles(styles)(DownloadBlobWithAuth)

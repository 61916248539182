import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Dns from '@material-ui/icons/Dns'
import ActionButton from '../Buttons/ActionButton'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import tasksStyle from 'assets/jss/material-dashboard-react/components/tasksStyle.jsx'
import { withRouter } from 'react-router-dom'
import { getRiskColor } from '../../utils'

const styles = {
  risk: {
    marginLeft: '45%',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.64)'
  },
  button: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  }
}
class AssetsList extends React.Component {

  render () {
    const { classes, assets } = this.props
    const goToAsset = (assetId) => {
      this.props.history.push('/asset/'+assetId)
    }

    return (
      <Table className={classes.table}>
        <TableBody>
          {assets.map((asset, i) => (
            <TableRow key={i} className={classes.tableRow}>
              <TableCell className={classes.tableCell} >
                <div className={classes.risk} style={{ backgroundColor: getRiskColor(asset) }} />
              </TableCell>
              <TableCell className={classes.tableCell} >
                {asset.name}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <ActionButton handleClick={() => goToAsset(asset._id)} customClass={classes.button} title={"Asset detail"}>
                  <Dns/>
                </ActionButton>
              </TableCell>
             </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

AssetsList.propTypes = {
  classes: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  wallets: PropTypes.array.isRequired,
}

export default withRouter(withStyles(styles,tasksStyle)(AssetsList))

import * as actions from '../consts/action-types'
import { DEFAULT_ROWS_PER_PAGE } from '../consts'
import { MSG_STATUS } from '../consts/assetBearers'
import { setAddAssetBearerStatus, setUpdateAssetBearerStatus } from './ui'
import * as schema from '../lib/schema'
import { CompareObjects, plainOnbjectAndCompareResult } from '../utils'

export const setAssetBearers = (payload) => ({
  type: actions.SET_ASSETBEARERS,
  payload
})

export const setAssetBearersProps = (payload) => ({
  type: actions.SET_ASSETBEARERS_PROPS,
  payload
})

export const setAssetBearersList = (payload) => ({
  type: actions.SET_ASSETBEARERS_LIST,
  payload
})

export const setAssetBearer = (payload) => ({
  type: actions.SET_ASSETBEARER,
  payload
})

export const mergeAssetBearer = (payload) => ({
  type: actions.ASSETBEARERS_MERGE,
  payload
})

export const removeAssetBearer = (payload) => ({
  type: actions.DELETE_ASSETBEARER,
  payload
})

export const setAssetBearerPaginationProps = (payload) => ({
  type: actions.SET_ASSETBEARER_PAGINATION_PROPS,
  payload
})

export const fetchAssetBearersWithPagination = (page = 0, rowsPerPage = DEFAULT_ROWS_PER_PAGE, sortColumn = 'createdAt', sortDirection = 'desc', searchAndFilters, callback) => {
  const paginationProps = { page, rowsPerPage, sortColumn, sortDirection, searchAndFilters }
  const url = '/api/assetBearers/list?' +
            `page=${page}` +
            `&rpp=${rowsPerPage}` +
            `&sortColumn=${sortColumn}` +
            `&sortDirection=${sortDirection}` +
            `&searchAndFilters=${searchAndFilters}`
  return ({
    type: actions.APICOMPLETE,
    payload: {
      url,
      schema: [schema.assetBearer],
      success: ({ data, props }, dispatch, getState) => {
        const { assetBearers, ui } = getState()
        const equalProps = plainOnbjectAndCompareResult(assetBearers.paginationProps, paginationProps)
        const equalAssetBearers = CompareObjects(assetBearers.data, data.entities?.assetBearers)
        !equalProps && dispatch(setAssetBearerPaginationProps(paginationProps))
        if (!equalAssetBearers || !equalProps) { dispatch(setAssetBearers(data.entities?.assetBearers)) }
        !plainOnbjectAndCompareResult(ui.assetBearersProps, props) && dispatch(setAssetBearersProps(props))
        callback()
      },
      label: 'assetBearers'
    },
    meta: {
      throttle: 1000
    }
  })
}
export const fetchAssetBearersList = (resultsPerSearch = 5, search, callback) => {
  const url = '/api/assetBearers/searchByEmail?' +
  `resultsPerSearch=${resultsPerSearch}` +
  `&search=${search}`
  return ({
    type: actions.API_WITH_CONTROL,
    payload: {
      url,
      schema: [schema.assetBearer],
      success: (data, dispatch) => {
      data.entities?.assetBearers ? dispatch(setAssetBearersList(data.entities?.assetBearers)) : dispatch(setAssetBearersList([]))
      callback && callback()
      },
      error: (message) => [
        console.log(message)
      ],
      label: 'assetBearersList'
    },
    meta: {
      throttle: 1000
    }
  })
}
export const fetchAssetBearers = () => (
  {
    type: actions.API,
    payload: {
      url: '/api/assetBearers',
      schema: [schema.assetBearer],
      success: ({ entities }) => [
        setAssetBearers(entities.assetBearers)
      ],
      label: 'assetBearers'
    },
    meta: {
      throttle: 1000
    }
  })

export const getAssetBearerById = (id) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: `/api/assetBearers/${id}`,
    success: (data, dispatch, getState) => {
      const { assetBearers } = getState()
      data ? !CompareObjects(assetBearers.one, data) && dispatch(setAssetBearer(data)) : dispatch(setAssetBearer([]))
    },
    error: (message) => [
      console.log(message)
    ],
    label: 'assetBearer'
  },
  meta: {
    throttle: 1000
  }
})

export const updateAssetBearer = (assetBearerData, callbackSuccess, callbackError) => ({
  type: actions.API,
  payload: {
    url: `/api/assetBearers/${assetBearerData.id}`,
    method: 'PUT',
    schema: schema.assetBearer,
    success: ({ entities }) => [
      setUpdateAssetBearerStatus(MSG_STATUS.UPDATED_OK),
      mergeAssetBearer(entities.assetBearers),
      callbackSuccess()
    ],
    error: (message) => [
      setUpdateAssetBearerStatus(message),
      callbackError()
    ],
    data: {
      ...assetBearerData
    },
    label: 'update_assetBearer'
  }
})

export const deleteAssetBearer = (id, callbackSuccess, callbackError) => ({
  type: actions.API,
  payload: {
    url: `/api/assetBearers/${id}`,
    method: 'DELETE',
    schema: schema.assetBearer,
    success: ({ entities }) => [
      removeAssetBearer(id),
      mergeAssetBearer(entities.assetBearers),
      setUpdateAssetBearerStatus(MSG_STATUS.DELETED_OK),
      callbackSuccess()
    ],
    error: (message) => [
      setUpdateAssetBearerStatus(message),
      callbackError()
    ],
    label: 'delete_assetBearer'
  }
})

export const addAssetBearer = (assetBearerData, callbackSuccess, callbackError) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/assetBearers',
    method: 'POST',
    success: (data, dispatch) => {
      dispatch(setAddAssetBearerStatus(MSG_STATUS.CREATED_OK))
      callbackSuccess(data._id)
    },
    error: (message, dispatch) => [
      dispatch(setAddAssetBearerStatus(message)),
      callbackError()
    ],
    label: 'Add_assetBearer',
    data: {
      ...assetBearerData
    }
  }
})

import * as actions from '../consts/action-types'

export const switchTheme = () => ({
  type: actions.SWITCH_THEME,
  meta: {
    throttle: 1000,
    analytics: 'Theme Change'
  }
})

export const setColumnsTableOrganization = (payload = '') => ({
  type: actions.SET_COLUMNS_TABLEORGANIZATION,
  payload
})

export const setOptionsTableOrganization = (payload = {}) => ({
  type: actions.SET_OPTIONS_TABLEORGANIZATION,
  payload
})

export const setAddOrganizationStatus = (payload = '') => ({
  type: actions.SET_ADDORGANIZATION_STATUS,
  payload
})

export const setUpdateOrganizationStatus = (payload = '') => ({
  type: actions.SET_UPDATEORGANIZATION_STATUS,
  payload
})

export const setAddAssetStatus = (payload = '') => ({
  type: actions.SET_ADDASSET_STATUS,
  payload
})

export const setUpdateAssetStatus = (payload = '') => ({
  type: actions.SET_UPDATEASSET_STATUS,
  payload
})

export const setAddAssetBearerStatus = (payload = '') => ({
  type: actions.SET_ADDASSETBEARER_STATUS,
  payload
})

export const setUpdateAssetBearerStatus = (payload = '') => ({
  type: actions.SET_UPDATEASSETBEARER_STATUS,
  payload
})

export const setAddWebhookStatus = (payload = '') => ({
  type: actions.SET_ADDWEBHOOK_STATUS,
  payload
})

export const setUiModal = (payload) => ({
  type: actions.SET_UI_MODAL,
  payload
})

export const setUiDialog = (payload) => ({
  type: actions.SET_UI_DIALOG,
  payload
})

export const setStatus = (payload) => ({
  type: actions.SET_STATUS,
  payload
})

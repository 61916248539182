import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import OrganizationTable from '../../components/OrganizationTable/OrganizationTable.jsx'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}))

export default function CenteredGrid () {
  const classes = useStyles()
  return (
    <Box m={2}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <OrganizationTable/>
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

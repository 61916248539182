import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx'
import { connect } from 'react-redux'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import Icon from 'components/Icon/Icon'
import Info from 'components/Typography/Info'
import InfoIcon from '@material-ui/icons/Info'
import Danger from 'components/Typography/Danger'
import Warning from '@material-ui/icons/Warning'
import { withRouter } from 'react-router-dom'

class StatsCard extends Component {
  render () {
    const { classes, title, value, percentage, icon, footerLabel, footerIcon} = this.props

    return (
      <Card onClick={this.handleClick} withLink={this.hasLink()}>
        <CardHeader color="primary" stats icon>
          <CardIcon color="primary">
            <Icon name={icon} color="#fff" size="1.15em" />
          </CardIcon>
          <p className={classes.cardCategory}>{title}</p>
          <h2 className={classes.cardTitle}>{this.defaultValue(value)}
            { this.shouldRenderPercentage(percentage)
              ? <p className={classes.cardPercent}>({percentage}%)</p>
              : null
            }
          </h2>
        </CardHeader>
        { footerLabel
          ? <CardFooter stats style={{ justifyContent: 'unset' }}>
            { footerIcon
              ? (
                footerIcon === 'info'
                  ? <Info>
                    <InfoIcon className={classes.stats} />
                  </Info>
                  : <Danger>
                    <Warning />
                  </Danger>
              )
              : null
            }
            <div className={classes.stats}>
              <span>{ footerLabel }</span>
            </div>
          </CardFooter>
          : null
        }
      </Card>
    )
  }

  handleClick = e => {
    const { footerLink } = this.props
    this.props.history.push(footerLink)
  }

  shouldRenderPercentage (percentage) {
    return isNaN(percentage)
      ? false
      : (percentage !== 0)
  }

  defaultValue (value) {
    return (!value && value !== 0) ? '-' : value
  }

  hasLink() {
    const { footerLink } = this.props
    return footerLink ? true : false
  }
}

StatsCard.propTypes = {
  classes: PropTypes.object.isRequired
}


export default withRouter(connect()(withStyles(dashboardStyle)(StatsCard)))

import { createSelector } from 'reselect'
import { sortImmutable } from '../utils'

const getAssetBearers = (state) => state.assetBearers
const getUI = state => state.ui

export const getAssetBearersToTable = createSelector(
  [getAssetBearers],
  (assetBearers) => {
    return Object.keys(assetBearers.data).map(_id => assetBearers.data[_id])
  }
)

export const getAssetBearersList = createSelector(
  [getAssetBearers],
  (assetBearers) => {
    return Object.keys(sortImmutable(assetBearers.list)).map(_id => assetBearers.list[_id])
  }
)

export const getAssetBearer = createSelector(
  [getAssetBearers],
  (assetBearers) => {
    return assetBearers.one
  }
)

export const getAssetBearersProps = createSelector(
  [getUI],
  (ui) => {
    return ui.assetBearersProps
  }
)

export const getModal = createSelector(
  [getUI],
  (ui) => {
    return ui.modal
  }
)

export const getDialog = createSelector(
  [getUI],
  (ui) => {
    return ui.dialog
  }
)

export const getAssetBearersUpdateStatus = createSelector(
  [getUI],
  (ui) => {
    return ui.updateAssetBearerStatus || ui.addAssetBearerStatus
  }
)

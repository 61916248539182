import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Button, InputAdornment } from '@material-ui/core'
import { Link, Delete, Notifications } from '@material-ui/icons'
import { Autocomplete as SelectEdit } from '@material-ui/lab'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/styles'
import { HTTP_METHODS_WEBHOOK } from '../../consts'
import { withRouter } from 'react-router-dom'
import { deleteWebhook } from '../../actions/webhooks'
import DeleteDialog from '../Dialogs/ConfirmCalcelDialog'

const styles = {
  save: {
    minWidth: '100px',
    marginRight: '5%',
    width: '38%',
    marginTop: '10px'
  },
  delete: {
    backgroundColor: '#9396bd',
    width: '14%',
    whiteSpace: 'nowrap',
    marginTop: '10px',
    textShadow: '1px 1px 5px rgba(15,30,50,.3)',
    minWidth: '30px',
  },
  margin: {
    width: '100%'
  },
  splitter: {
    marginTop: '2rem',
    display: 'block',
    border: '0',
    background: 'transparent'
  },
  icon: {
    color: '#3f51b5'
  }
}

const WebhookForm = ({handleSubmit, handleTest, formData, classes, handleChange, handleSelect, submitted, testResult, ...otherProps}) => {
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() =>{
    ValidatorForm.addValidationRule('isOptionSelectedInList', (value) => {
      if (!HTTP_METHODS_WEBHOOK.includes(value)) {
          return false;
      }
      return true;
    });
  }, [])

  const handleDelete = ()=> {
    setShowDialog(true)
  }

  const deleteWebhook = () => {
    otherProps.deleteWebhook(formData?._id)
    setShowDialog(false)
    otherProps.history.push(`/webhooks`)
  }

  const deleteCanceled = () => {
    setShowDialog(false)
  }

  return (
    <ValidatorForm
      onSubmit={ handleSubmit }
      onError={errors => console.log(errors)}
      instantValidate={true} >
      <TextValidator
        name="name"
        disabled={!!formData?._id}
        value={formData?.name || ''}
        validators={['required']}
        errorMessages={['This field is required']}
        className={classes.margin}
        label="Name"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Notifications className={classes.icon} />
            </InputAdornment>
          )
        }}
      />
      <hr className={classes.splitter} />
      <TextValidator
        name="url"
        value={formData?.url || ''}
        validators={['required']}
        errorMessages={['This field is required']}
        className={classes.margin}
        label="Url"
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Link className={classes.icon} />
            </InputAdornment>
          )
        }}
      />
      <hr className={classes.splitter} />
      <SelectEdit
        value={formData?.method || ''}
        options={HTTP_METHODS_WEBHOOK.map((option) => option)}
        renderInput={(params) => (
          <TextValidator 
            {...params}
            label="Method"
            name='method'
            onBlur={handleSelect}
            value={formData?.method || ''}
            validators={['required', 'isOptionSelectedInList']}
            errorMessages={['This field is required', 'Enter a method of the list']}
            variant="standard" />
        )}
      />
      <hr className={classes.splitter} />
      <Switch
        checked={formData?.enabled}
        onChange={handleChange}
        color="primary"
        name="enabled"
        inputProps={{ 'aria-label': formData?.enabled ? true : false }}
      /> {formData?.enabled ? "Enabled" : "Disabled"}
      <hr className={classes.splitter} />
      <Button
        className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
        color="primary"
        variant="contained"
        type="submit"
        disabled={submitted}
      >
        {'Save'}
      </Button>
      <Button
        className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
        color="primary"
        variant="contained"
        type="button"
        disabled={submitted}
        onClick={handleTest}
      >
        {'Test'}
      </Button>
      {
        formData?._id && 
        <>
        <Button
          className={`RegularButton-primary-200 RegularButton-button-197 ${classes.delete}`}
          color="primary"
          variant="contained"
          type="button"
          disabled={submitted}
          onClick={handleDelete}>
          {<Delete/>}
        </Button>
        <DeleteDialog
          onConfirm={deleteWebhook}
          onCancel={deleteCanceled}
          showDialog={showDialog}
          title={"Really want to delete this webhook?"}
        />
        </>
      }
    </ValidatorForm>
  )
}

const mapDispatchToProps = {
  deleteWebhook
}

export default 
withRouter(compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles),
)(WebhookForm))

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
// import { grey, green, purple } from '@material-ui/core/colors'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import auth from '../Login/auth'
//
const useStyles = makeStyles({
  avatar: {
    backgroundColor: '#3f51b5',
    color: '#ffffff'
  },
  confirm: {
    backgroundColor: '#3f51b5'
  }
})
function ListItemLink (props) {
  return <ListItem button component='a' {...props} />
}

export function AssetsDialogV2 (props) {
  const classes = useStyles()
  const { onClose, selectedAsset, open, deleteTransaction } = props

  const handleClose = () => {
    onClose()
  }

  const notifyInstall = value => {
    onClose(value)
  }
  
  const _deleteTransaction = () => {
    deleteTransaction(selectedAsset._id)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
      {!selectedAsset.transactionConfirmed
        ? <><List>
          <ListItem autoFocus button onClick={() => notifyInstall(selectedAsset._id)}>
            <ListItemAvatar>
              <Avatar className={classes.confirm}>
                <CheckCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='CONFIRM' />
          </ListItem>
          <ListItemLink onClick={handleClose} href={auth.getAuthenticatedUri(`/api/assets/${selectedAsset._id}/transaction/install`)}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SaveAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.textItem} primary='DOWNLOAD TRANSACTION' />
          </ListItemLink>
          <ListItemLink onClick={handleClose} href={auth.getAuthenticatedUri(`/api/assets/${selectedAsset._id}/private_key/install`)}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SaveAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.textItem} primary='DOWNLOAD PRIVATE KEY' />
          </ListItemLink>
          <ListItemLink onClick={handleClose} href={auth.getAuthenticatedUri('/api/assets/downloadreadme')}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SaveAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.textItem} primary='DOWNLOAD README' />
          </ListItemLink>
          <ListItem autoFocus button onClick={() => _deleteTransaction()}>
            <ListItemAvatar>
              <Avatar className={classes.confirm}>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='REMOVE' />
          </ListItem>
        </List></>
        : <List>
           <ListItemLink onClick={handleClose} href={auth.getAuthenticatedUri(`/api/assets/${selectedAsset._id}/transaction/install`)}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SaveAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.textItem} primary='DOWNLOAD TRANSACTION' />
          </ListItemLink>
          <ListItemLink onClick={handleClose} href={auth.getAuthenticatedUri('/api/assets/downloadreadme')}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <SaveAltIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.textItem} primary='DOWNLOAD README' />
          </ListItemLink>
          <ListItem autoFocus button onClick={() => _deleteTransaction()}>
            <ListItemAvatar>
              <Avatar className={classes.confirm}>
                <DeleteIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='REMOVE' />
          </ListItem>
        </List>}
    </Dialog>
  )
}

AssetsDialogV2.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedWallet: PropTypes.object.isRequired,
  deleteWallet: PropTypes.func.isRequired
}

import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Check, Error } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import SimplePage from '../../components/Templates/SimplePage'
import SnackbarContent from '../../components/Snackbar/SnackbarContent'
import Snackbar from '../../components/Snackbar/Snackbar'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import WebhookForm from '../../components/Webhooks/WebhookForm'
import WebhookDataSet from '../../components/Webhooks/WebhookDataSet'
import WebhookTabs from '../../components/Webhooks/WebhooksTabs'
import { DEFAULT_DURATION_SNACKBAR, INITIAL_HEADER_JSON, INITIAL_BODY_JSON } from '../../consts'
import { isStatusOk } from '../../utils'
import { addWebhook, updateWebhook, testWebhook } from '../../actions/webhooks'
import { setAddWebhookStatus } from 'actions/ui'
import { getWebhooksUpdateStatus, getWebhookById } from '../../selectors/webhooks'
import { MSG_STATUS } from '../../consts/webhooks'
import API from '../../api'
const api = new API()
const styles = {
  title: {
    borderBottom: 'solid 2px rgba(0,0,0,0.15)',
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#666',
    letterSpacing: '0.01071em',
    paddingBottom: '8px',
    marginBottom: '40px',
    marginTop: '50px'
  }
}

const initialResponse = {
  data: '',
  ok: false
}

const Webhook = ({webhook, status, classes, ...otherProps}) => {
  let webhookInitialData = {
    formData: {
      method: webhook?.method || 'POST',
      name: webhook?.name || '',
      url: webhook?.url || '',
      enabled: true,
      _id: webhook?._id
    },
    headerData: webhook?.headers && JSON.parse(webhook?.headers) || INITIAL_HEADER_JSON,
    bodyData: webhook?.body && JSON.parse(webhook?.body) || INITIAL_BODY_JSON
  }

  const [formData, setFormData] = useState(webhookInitialData.formData)
  const [headerData, setHeaderData] = useState(webhookInitialData.headerData)
  const [bodyData, setBodyData] = useState(webhookInitialData.bodyData)
  const [responseData, setResponseData] = useState(initialResponse)
  const [submitted, setSubmitted] = useState(false)

  const icon = (
    (status === MSG_STATUS.CREATED_OK) ? Check : Error
  )

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value || event.target.checked
    })
    event.target.name === 'url' && setResponseData(initialResponse)
  }

  const handleHeaderChange = (event) => {
    !event.error && setHeaderData(event.jsObject)
    setResponseData(initialResponse)
  }

  const handleBodyChange = (event) => {
    !event.error && setBodyData(event.jsObject)
    setResponseData(initialResponse)
  }

  const handleSelect = (event) => {
    setFormData({...formData, method: event.target.value})
    setResponseData(initialResponse)
  }

  const handleTest = async() => {
    setSubmitted(true)
    try {
      const response = await api.request({
        url: '/api/webhooks/test',
        method: 'POST',
        data: {formData, headerData, bodyData}
      })
      const data = response
      delete data.req
      setResponseData({ data, ok: response.status === 200 })
    } catch (e) {
      setResponseData({ data: { error: ''+e }, status: 'error', ok: false })
    } finally {
      setSubmitted(false)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    const data = {...formData, headers: {...headerData}, body: { ...bodyData }}
    if (data?._id){
      otherProps.updateWebhook(data)
    }else{
      otherProps.addWebhook(data, (data => {
        data?._id && setFormData({...formData, _id: data._id })
      }))
    }
    setTimeout(() => setSubmitted(false), 1100)
  }

  return (
    <SimplePage title='Webhooks'>
      <h6 className={classes.title}>Webhook Data</h6>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
        {status && isStatusOk(status) && <Snackbar
            message={status}
            open={status !== '' }
            color={"success"}
            icon={ Check }
            closeNotification={() => otherProps.setAddWebhookStatus('')}
            close
            autoHide={DEFAULT_DURATION_SNACKBAR}
          />}
        {status && !isStatusOk(status) &&  <SnackbarContent
            message={status}
            open={status !== '' }
            color="primary"
            icon={icon}
            closeNotification={() => otherProps.setAddWebhookStatus('')}
            close
          />
        }
          <WebhookForm
            handleSubmit={handleSubmit}
            handleTest={handleTest}
            formData={formData}
            handleChange={handleChange}
            handleSelect={handleSelect}
            submitted={submitted}
            testResult={responseData.ok}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={5}>
          <WebhookTabs
            headerData={headerData}
            bodyData={bodyData}
            responseData={responseData || {}}
            handleHeaderChange={handleHeaderChange}
            handleBodyChange={handleBodyChange}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <WebhookDataSet />
        </GridItem>
      </GridContainer>
    </SimplePage>
  )
}

const mapStateToProps = (state, props) => {
  return ({
    status: getWebhooksUpdateStatus(state),
    webhook: getWebhookById(state, props?.match?.params?.id)
})}


const mapDispatchToProps = {
  addWebhook,
  updateWebhook,
  setAddWebhookStatus,
  testWebhook,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Webhook)

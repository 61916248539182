import React from 'react'
import List from '@material-ui/core/List';
import WebhookDataSetItem from './WebhookDataSetItem'
import { WEBHOOK_DATASET } from '../../consts'
import { withStyles } from '@material-ui/styles'

const styles = {
  title: {
    borderBottom: 'solid 2px rgba(0,0,0,0.15)',
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#666',
    letterSpacing: '0.01071em',
    paddingBottom: '8px',
    margin: '15px 0 10px'
  }
}

const WebhookDataSet = ({classes}) => {
  return (
    <>
      <h6 className={classes.title}>DATA SET</h6>
      <List component="nav" aria-label="dataSet words">
        {
          Object.keys(WEBHOOK_DATASET).map((e, i) => (
            <WebhookDataSetItem key={i} text={e} description={WEBHOOK_DATASET[e]}/>
          ))
        }
      </List>
    </>
  )
}
export default withStyles(styles)(WebhookDataSet)

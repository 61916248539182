import React from 'react'
import ActionButton from '../Buttons/ActionButton'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
//import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'timeago.js'
import PropTypes from 'prop-types'

const getConfigTable = (newOrg, viewOrg, updateOrg, deleteOrg, onColumnSortChange, onViewColumnsChange, defaultOptions='', columnsView={}) => {
  return {
    count: 0,
    options: {
      filterType: 'textField',
      print: false,
      selectableRows: 'none',
      sortOrder: {
        name: defaultOptions ? defaultOptions.sortOrder.name : 'lastInstalledAsset',
        direction: defaultOptions ? defaultOptions.sortOrder.direction : 'desc'
      },
      onColumnSortChange: onColumnSortChange,
      onViewColumnsChange: onViewColumnsChange,
      rowsPerPageOptions: [10, 25, 50, 100],
      customToolbar: function () {
        return (
          <ActionButton handleClick={newOrg} title={'Add Organization'}>
            <AddIcon/>
          </ActionButton>
        )
      }
    },
    columns: [
      {
        name: 'name',
        label: 'Name',
        options: {
          display: ('name' in columnsView) ? columnsView.name : true
        }
      },
      {
        name: 'funded',
        label: 'Funded',
        options: {
          display: ('funded' in columnsView) ? columnsView.funded : true
        }
      },
      {
        name: 'compromised',
        label: 'Compromised',
        options: {
          display: ('compromised' in columnsView) ? columnsView.compromised : true,
        }
      },
      {
        name: 'totalAssets',
        label: 'Total Assets',
        options: {
          filter: false,
          display: ('totalAssets' in columnsView) ? columnsView.totalAssets : true,
        }
      },
      {
        name: 'estimatedAssets',
        label: 'Estimated Assets',
        options: {
          display: ('estimatedAssets' in columnsView) ? columnsView.estimatedAssets : false,
          filter: false
        }
      },
      {
        name: 'protectedAssets',
        label: 'Protected Assets',
        options: {
          display: ('protectedAssets' in columnsView) ? columnsView.protectedAssets : true,
          filter: false
        }
      },
      {
        name: 'unprotectedAssets',
        label: 'Unprotected Assets',
        options: {
          display: ('unprotectedAssets' in columnsView) ? columnsView.unprotectedAssets : true,
          filter: false
        }
      },
      {
        name: 'compromisedAssets',
        label: 'Compromised Assets',
        options: {
          display: ('compromisedAssets' in columnsView) ? columnsView.compromisedAssets : true,
          filter: false
        }
      },
      {
        name: 'lastInstalledAsset',
        label: 'Last Installed Asset',
        options: {
          customBodyRender: (value) => {
            return value ? format(value) : '-'
          },
          display: ('lastInstalledAsset' in columnsView) ? columnsView.lastInstalledAsset : true,
          filter: false
        }
      },
      {
        name: 'totalPeople',
        label: 'Total People',
        options: {
          display: ('totalPeople' in columnsView) ? columnsView.totalPeople : false,
          filter: false
        }
      },
      {
        name: 'estimatedPeople',
        label: 'Estimated People',
        options: {
          display: ('estimatedPeople' in columnsView) ? columnsView.estimatedPeople : false,
          filter: false
        }
      },
      {
        name: 'protectedPeople',
        label: 'Protected People',
        options: {
          display: ('protectedPeople' in columnsView) ? columnsView.protectedPeople : false,
          filter: false
        }
      },
      {
        name: 'unprotectedPeople',
        label: 'Unprotected People',
        options: {
          display: ('unprotectedPeople' in columnsView) ? columnsView.unprotectedPeople : false,
          filter: false
        }
      },
      {
        name: 'compromisedPeople',
        label: 'Compromised People',
        options: {
          display: ('compromisedPeople' in columnsView) ? columnsView.compromisedPeople : false,
          filter: false
        }
      },
      {
        name: 'totalAccounts',
        label: 'Total Accounts',
        options: {
          display: ('totalAccounts' in columnsView) ? columnsView.totalAccounts : false,
          filter: false
        }
      },
      {
        name: 'newAccounts',
        label: 'New Accounts',
        options: {
          display: ('newAccounts' in columnsView) ? columnsView.newAccounts : false,
          filter: false
        }
      },
      {
        name: 'fundedAccounts',
        label: 'Funded Accounts',
        options: {
          display: ('fundedAccounts' in columnsView) ? columnsView.fundedAccounts : false,
          filter: false
        }
      },
      {
        name: 'compromisedAccounts',
        label: 'Compromised Accounts',
        options: {
          display: ('compromisedAccounts' in columnsView) ? columnsView.compromisedAccounts : false,
          filter: false
        }
      },
      {
        name: 'tokenExpiration',
        label: 'Token Expiration',
        options: {
          customBodyRender: (value) => {
            return value ? format(value) : '-'
          },
          display: ('tokenExpiration' in columnsView) ? columnsView.tokenExpiration : false,
          filter: false
        }
      },
      {
        name: "_id",
        label: 'Actions',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
              <ActionButton handleClick={viewOrg} value={value} title={'View'}>
                <VisibilityIcon/>
              </ActionButton>
              <ActionButton handleClick={updateOrg} value={value} title={'Edit'}>
                <EditIcon/>
              </ActionButton>
              {/*<ActionButton handleClick={deleteOrg} value={value} title={'Delete'}>
                <DeleteIcon/>
            </ActionButton>*/}
              </>
            );
          }
        }
      },
      // TODO: implement Last Login feature. #362
      // {
      //   name: 'last_login',
      //   label: 'Last Login',
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => (
      //       format('2020-09-19T15:03:03.000-03:00')
      //     ),
      //     filter: false,
      //   }
      // }
    ]
  }
}
getConfigTable.propTypes = {
  newOrg: PropTypes.func.isRequired,
  viewOrg: PropTypes.func.isRequired,
  updateOrg:PropTypes.func.isRequired,
  onColumnSortChange: PropTypes.func.isRequired,
  onViewColumnsChange: PropTypes.func.isRequired,
  defaultOptions: PropTypes.object,
  columnsView: PropTypes.object
}

export default getConfigTable

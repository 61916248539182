import * as actions from '../consts/action-types'
import { MSG_STATUS } from '../consts/webhooks'
import { setAddWebhookStatus } from './ui'
import { CompareObjects } from '../utils'
import * as schema from 'lib/schema'

export const setWebhooks = (payload) => ({
  type: actions.SET_WEBHOOKS,
  payload
})

export const removeWebhook = (payload) => ({
  type: actions.DELETE_WEBHOOK,
  payload
})

export const deleteWebhook = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/webhooks/${id}`,
    method: 'DELETE',
    success: () => [
      removeWebhook(id)
    ],
    error: (message) => setAddWebhookStatus(message),
    label: 'Delete webhook'
  }
})

export const fetchWebhooks = (callback) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/webhooks',
    method: 'GET',
    success: (data, dispatch, getState) => {
      const { webhooks } = getState()
      data && !CompareObjects(webhooks?.data, data) && dispatch(setWebhooks(data))
      callback && callback()
    },
    label: 'Fetch webhooks'
  }
})

export const addWebhook = (formData, callback) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/webhooks',
    method: 'POST',
    schema: schema.webhook,
    success: ({ entities }, dispatch) => {
      dispatch(setAddWebhookStatus(MSG_STATUS.CREATED_OK))
      callback && callback(Object.values(entities.webhooks)[0])
    },
    error: (message, dispatch) => [
      dispatch(setAddWebhookStatus(message)),
      callback && callback()
    ],
    label: 'Add webhook',
    data: {
      ...formData
    }
  }
})

export const updateWebhook = (formData, callback) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: `/api/webhooks/${formData._id}`,
    method: 'PATCH',
    schema: schema.webhook,
    success: (data) => {
      return data
    },
    error: (message, dispatch) => [
      dispatch(setAddWebhookStatus(message)),
      callback && callback()
    ],
    data: {
      ...formData
    },
    label: 'Update webhook'
  }
})

export const testWebhook = (formData, callback) => ({
  type: actions.API,
  payload: {
    url: `/api/webhooks/test`,
    method: 'POST',
    schema: schema.webhook,
    success: ({ entities }, dispatch) => {
      callback && callback()
    },
    error: (message, dispatch) => [
      callback && callback()
    ],
    data: formData,
    label: 'Test webhook'
  }
})

import React, {useEffect, useState} from 'react'
import { compose } from 'redux'
import MUIDataTable from 'mui-datatables'
import { withStyles } from '@material-ui/styles'

import { connect } from 'react-redux'
import { fetchOrganizations, deleteOrganization } from '../../actions/organizations'
import { sortImmutable } from '../../utils'

import LinearProgress from '@material-ui/core/LinearProgress'
import getConfigTable from './ConfigTable'
import OrganizationModal from './OrganizationModal'
import OrganizationDeleteDialog from '../Dialogs/ConfirmCalcelDialog'
import {operations} from './OrganizationUtils'
import { DEFAULT_DURATION_SNACKBAR } from '../../consts'
import { setAddOrganizationStatus, setUpdateOrganizationStatus, setOptionsTableOrganization, setColumnsTableOrganization } from '../../actions/ui'
import { getAddOrganizationStatus, getUpdateOrganizationStatus, getOptionsTableOrganization, getColumnsTableOrganization } from '../../reducers/ui'
import ErrorIcon from '@material-ui/icons/Error'
import CheckIcon from '@material-ui/icons/Check'
import Snackbar from '../Snackbar/Snackbar'

const styles = {}

const OrganizationTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [operationModal, setOperationModal] = useState(operations.view);
  const [selectedOrganization, setSelectedOrganization] = useState();

  const [showDialog, setShowDialog] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [tableData, setTableData] = useState();
  const [firstLoad, setFirstLoad] = useState(false);

  const onColumnSortChange = (changedColumn, direction) => {
    const options = Object.assign({}, props.optionsOrganizationTable)
    options.sortOrder = {
      name: changedColumn,
      direction
    }
    props.setOptionsTableOrganization(options)
  }

  const onViewColumnsChange = (changedColumn, action) => {
    props.setColumnsTableOrganization({[changedColumn]: (action === 'add')})
  }

  const getOrganization = (val) => {
    let filter = props.organizations.filter(organization => organization._id === val)
    return filter[0] ? filter[0] : {}
  }

  const newOrg = () => {
    setOperationModal(operations.add);
    setShowModal(true);
  }

  const deleteOrgConfirmed = () => {
    props.deleteOrganization(selectedOrganizationId)
    setShowDialog(false);
  }

  const deleteOrgCanceled = () => {
    setShowDialog(false);
  }

  const deleteOrg = id => {
    setSelectedOrganizationId(id)
    setShowDialog(true);
  }
  
  const viewOrg = id => {
    let selOrg = getOrganization(id)
    setSelectedOrganization(selOrg)
    setOperationModal(operations.view);
    setShowModal(true);
  };

  const updateOrg = id => {
    let selOrg = getOrganization(id)
    setSelectedOrganization(selOrg)
    setOperationModal(operations.edit);
    setShowModal(true);
  };

  useEffect(() => {
    let {options, columns} = getConfigTable(newOrg, viewOrg, updateOrg, deleteOrg, onColumnSortChange, onViewColumnsChange, props.optionsOrganizationTable, props.columnsOrgagizationTable)
    if (firstLoad){
      setFirstLoad(false)
      props.setOptionsTableOrganization(options)
    }
    setTableData({organizations: props.organizations, columns})
  }, [props.organizations]);

  useEffect(() => {
    setFirstLoad(true)
    props.fetchOrganizations()
  }, [])

  useEffect(() => {
    props.status !== '' && setShowSnackbar(true)
  },[props.status])

  const closeModal = (changedData) => {
    changedData === true && props.fetchOrganizations()
    setShowModal(false);
  }

  const isStatusOk = () =>{
    return showSnackbar && props.status.indexOf('ok') !== -1
  }
  const closeSnackbar = () => {
    setShowSnackbar(false);
    props.setAddOrganizationStatus('');
    props.setUpdateOrganizationStatus('');
  }

  return (
    <>
    <OrganizationModal
      open={showModal}
      handleClose={closeModal}
      operation={operationModal}
      selectedOrganization={selectedOrganization}
    />
    <OrganizationDeleteDialog
      onConfirm={deleteOrgConfirmed}
      onCancel={deleteOrgCanceled}
      showDialog={showDialog}
      title={"Really want to delete this organization?"}
    />

    {showSnackbar && <Snackbar
      message={showSnackbar && props.status}
      open={showSnackbar}
      color={ isStatusOk() ? "success" : "danger" }
      icon={ isStatusOk() ? CheckIcon : ErrorIcon }
      closeNotification={() => {closeSnackbar()}}
      close
      autoHide={DEFAULT_DURATION_SNACKBAR}
    />}

    {!firstLoad && tableData?.organizations ?
    <MUIDataTable
      title='Organizations'
      data={tableData.organizations}
      columns={tableData.columns}
      options={{...props.optionsOrganizationTable}}
    />
    :
    <LinearProgress/>
    }
    </>
  )
}

const mapStateToProps = state => {
  return {
    columnsOrgagizationTable: getColumnsTableOrganization(state),
    optionsOrganizationTable: getOptionsTableOrganization(state),
    status: getAddOrganizationStatus(state) + getUpdateOrganizationStatus(state),
    organizations: Object
      .keys(sortImmutable(state.organizations))
      .map(function (_id) {
        const org = state.organizations[_id]
        const people = org.people || {
          total: 0,
          compromised: 0,
          protected: 0,
          unprotected: 0,
          lastInstalled: null
        }
        const assets = org.assets || {
          total: 0,
          compromised: 0,
          protected: 0,
          unprotected: 0,
          lastInstalled: null
        }
        const accounts = org.accounts || {
          total: 0,
          compromised: 0,
          new: 0
        }
        const deploy = org.deploy || {
          expiresAt: null,
        }
        return {
          ...org,
          id: _id,
          totalAssets: assets.total,
          estimatedAssets: assets.estimated,
          compromisedAssets: assets.compromised,
          protectedAssets: assets.protected,
          unprotectedAssets: assets.unprotected,
          lastInstalledAsset: assets.last,
          totalPeople: people.total,
          estimatedPeople: people.estimated,
          compromisedPeople: people.compromised,
          protectedPeople: people.protected,
          unprotectedPeople: people.unprotected,
          totalAccounts: accounts.total,
          compromisedAccounts: accounts.compromised,
          fundedAccounts: accounts.funded,
          newAccounts: accounts.new,
          funded: accounts.total === accounts.funded && accounts.funded > 0 ? 'Yes' : 'No',
          compromised: accounts.compromised > 0 ? 'Yes' : 'No',
          tokenExpiration: deploy.expiresAt,
        }
      }),
  }
}

const mapDispatchToProps = {
  fetchOrganizations,
  deleteOrganization,
  setAddOrganizationStatus,
  setUpdateOrganizationStatus,
  setColumnsTableOrganization,
  setOptionsTableOrganization
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(OrganizationTable)

import React from 'react'
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'

const styles = {
  button: {
    "&:hover": {
      color: "#3f51b5"
    },
  }
}

const ActionButton = ({handleClick, value, children, classes, title, customClass}) => {
  return (
    <IconButton
    title={title}
    className={`${classes.button} ${customClass ? customClass: ''}`}
    onClick={() => handleClick(value)}
    >
      {children}
    </IconButton>
  )
}

ActionButton.propTypes = {
  children: PropTypes.any.isRequired,
  handleClick:PropTypes.func.isRequired,
  value: PropTypes.any,
  title: PropTypes.string,
  customClass: PropTypes.string,
}
export default withStyles(styles)(ActionButton)

import React from 'react'
import { withStyles } from '@material-ui/styles'
import { Clear as DeleteIcon } from '@material-ui/icons';
import ActionButton from '../Buttons/ActionButton'
const styles = {
  list: {
    marginTop: '120px',
    width: '96%',
  },
  item: {
    width: '100%',
    display: 'flex',
    padding: '7px',
    alignItems: 'center',
    backgroundColor: '#eee',
    border: '1px solid #ddd',
    margin: '12px 0',
    borderRadius: '5px',
    overflow: 'hidden'
  },
  enabled: {
    flex: '12%',
    textAlign: 'center',
    marginTop: '6px',
    cursor: 'pointer',
    '& .check':{
      color: '#3CB34D',
    },
    '& svg.pause':{
      color: '#CAC9DE',
    }
  },
  mail: {
    flex: '75%',
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica',
  },
  delete: {
    width: '2.5vw',
    height: '2.5vw',
    textAlign: 'center'
  },
}

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const EmailList = ({classes, emailList=[], deleteEmail}) => (
  <div className={classes.list}>
  {
    emailList.map(item => (
    <div key={item.email} className={classes.item}>
      <div className={classes.mail}>{truncateString(item.email, 40)}</div>
      <ActionButton
        handleClick={() => deleteEmail(item.email)}
        customClass={classes.delete}
        title={'Delete Email'}>
        <DeleteIcon />
      </ActionButton>
    </div>
    ))
  }
  </div>
)

export default withStyles(styles)(EmailList)

import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from '../consts/action-types'
import { omit } from '../utils'

const initialState = Immutable({
  data: {},
  list: {},
  one: {},
  paginationProps: {}
})

export default handleActions({
  [actions.SET_ASSETBEARERS_LIST]: (assetBearers, { payload }) => assetBearers.set('list', payload),
  [actions.SET_ASSETBEARERS]: (assetBearers, { payload }) => assetBearers.set('data', payload || {}),
  [actions.SET_ASSETBEARER]: (assetBearer, { payload }) => assetBearer.set('one', payload),
  [actions.DELETE_ASSETBEARER]: (assetBearer, { payload }) => assetBearer.set('data', omit(assetBearer.data, payload)),
  [actions.ASSETBEARERS_MERGE]: (assetBearers, { payload }) => assetBearers.merge(Immutable(payload)),
  [actions.SET_ASSETBEARER_PAGINATION_PROPS]: (assets, { payload }) => assets.set('paginationProps', payload || {})
}, initialState)

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import MuiAlert from '@material-ui/lab/Alert'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { default as DismissedIcon } from '@material-ui/icons/DoneAll'
import { default as IncidentIcon } from '@material-ui/icons/NotificationImportant'
import { default as AssetIcon } from '@material-ui/icons/Dns'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from 'prop-types'
import { compose } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { fetchIncidents, dismissIncident } from '../../actions/incidents'
import { DEFAULT_TIME_TO_REFETCH_INTERVAL } from '../../consts'
import { formatDateTime, timeAgo, getTablesMuiTheme } from '../../utils'
import { getIncidentsToTable, getAssetsIncidentToTable } from '../../selectors/incidents'
import ActionButton from '../Buttons/ActionButton'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    backgroundColor: '#f4f4f4',
    color: '#314091',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.1)',
    margin: '20px 0',
    fontSize: '1rem',
    fontWeight: '400'
  },
  progress: {
    margin: '30px 0'
  },
  fab: {
    minWidth: '0',
    width: '40px',
    height: '40px',
    backgroundColor: '#eeeeee',
    borderRadius: '50%',
    padding: '0',
    textAlign: 'center'
  },
  fabInner: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'center',
    paddingTop: '6px'
  },
  filterMessage: {
    textTransform: 'none'
  },
  green: {
    color: '#3ac205'
  },
  grey: {
    color: '#666'
  },
  primary: {
    color: '#3f51b5'
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    width: '40px',
    height: '40px',
    padding: 0,
    "&:hover": {
      backgroundColor: '#2f41a5',
      color: '#fff',
    }
  },
  assetColumn: {
    display: 'flex',
    flex: 0.3,
    margin: '5px 10px',
    border: 'none'
  }
})

function Message(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
class IncidentsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      incidents: null,
      tableStatePersist: {
        searchText: '',
        filterList: [],
        columns: [],
        sortOrder: {
          direction: 'desc',
          name: 'createdAt'
        },
        rowsExpanded: []
      },
      _await: true
    }
  }

  handleChange = (action, tableState) => {
    if (action !== 'propsUpdate') {
      this.setState({
        tableStatePersist: {
          searchText: tableState.searchText,
          filterList: tableState.filterList,
          columns: tableState.columns,
          sortOrder: tableState.sortOrder
        }
      })
    }
  }

  handleExpand = (allRowsExpanded) => {
    const { state } = this
    this.setState({
      tableStatePersist: {
        ...state.tableStatePersist,
        rowsExpanded: allRowsExpanded.map(exp => exp.index)
      }
    })
  }

  isDismissed = (id) => {
    let incident = this.getIncident(id)
    return incident.dismissedByUser ? 'dismissed' : 'not dismissed'
  }

  getAssetName = (val) => {
    let filter = this.props.assets.filter(asset => asset._id === val)
    let asset = filter[0] ? filter[0] : {}
    return asset.name ? asset.name : null
  }

  getIncident = (id) => {
    let filter = this.props.incidents.filter(incident => incident._id === id)
    return filter[0] ? filter[0] : {}
  }


  callbackFetchAssets = () => {
    this._isMounted && this.setState({ _await: false })
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.fetchIncidents(this.callbackFetchAssets)
    this.intervalIncidents = setInterval(() => {
      if (this._isMounted === true) {
        this.props.fetchIncidents(this.callbackFetchAssets)
      }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalIncidents)
  }

  getColumns = () => {
    const classes = this.props.classes
    let columns = [
      {
        name: '_id',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          empty: true,
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IncidentIcon style={{ color: '#af2cc5' }} />
            )
          }
        }
      },
      {
        name: 'createdAt',
        label: 'Created',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div title={formatDateTime(value)}>{timeAgo(value)}</div>
            )
          }
        }
      },

      {
        name: 'dismissed',
        label: 'Solved',
        options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {value === 'dismissed'
                  ? <div className={classes.fab}>
                    <div className={classes.fabInner} >
                      <DismissedIcon className={classes.primary} />
                    </div>
                  </div>
                  :
                  <ActionButton
                    handleClick={() => this.props.dismissIncident(tableMeta.rowData['0'])}
                    customClass={classes.button}
                    title="Mark as solved">
                    <DismissedIcon />
                  </ActionButton>
                }
              </>
            )
          }
        }
      }

    ]
    for (let i = 0; i < columns.length; i++) {
      columns[i].options.filterList = this.state.tableStatePersist.filterList[i]
      if (this.state.tableStatePersist.columns[i] !== undefined) {
        if (this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
          columns[i].options.display = this.state.tableStatePersist.columns[i].display
      }
    }
    return columns
  }

  render() {
    const data = this.props.incidents.map(obj => ({ ...obj, dismissed: this.isDismissed(obj._id) }))
    const columns = this.getColumns()
    const { _await, tableStatePersist } = this.state
    const classes = this.props.classes
    const options = {
      filterType: 'dropdown',
      search: false,
      print: false,
      responsive: 'standard',
      rowsPerPageOptions: [10, 25, 50, 100],
      selectableRows: 'none',
      sortOrder: tableStatePersist.sortOrder,
      rowsExpanded: tableStatePersist.rowsExpanded,
      textLabels: {
        body: {
          noMatch: this.props.isLoading
            ? <LinearProgress />
            : <span className={classes.filterMessage}>No Incidents found</span>
        }
      },
      onTableChange: (action, tableState) => this.handleChange(action, tableState),
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: true,
      onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => this.handleExpand(allRowsExpanded),
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <React.Fragment>
            <tr>
              <td colSpan={6}>
                <TableContainer component={Paper}>
                  <Table style={{ minWidth: "650" }} aria-label="simple table">
                    <TableBody>
                      {data[rowMeta.rowIndex].assets.map(asset =>
                        <TableRow className={classes.assetColumn} key={asset}>
                          <div className={classes.assetColumn}>{this.getAssetName(asset)}</div>
                          <ActionButton
                            handleClick={() => this.props.history.push(`/asset/${asset}`)}
                            customClass={classes.button}
                            title={'Asset Details'}>
                            <AssetIcon />
                          </ActionButton>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </React.Fragment>
        )
      }
    }

    return (
      <>
        {data.length === 0
          ? _await
            ? <LinearProgress className={classes.progress} />
            : <Message severity="info" className={classes.message} >
              No Incidents to report
            </Message>
          : <div className='Incidents'>
            <div style={{ maxWidth: '100%' }}>
              <MuiThemeProvider theme={getTablesMuiTheme()}>
                <MUIDataTable
                  title={''}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    incidents: getIncidentsToTable(state),
    assets: getAssetsIncidentToTable(state)
  }
}

const mapDispatchToProps = {
  fetchIncidents,
  dismissIncident
}

IncidentsTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(IncidentsTable)

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import ChartistGraph from 'react-chartist'
import cardStyle from '../../assets/jss/material-dashboard-react/components/cardStyle'
import Tooltip from 'chartist-plugin-tooltips'
import AxisTitle from 'chartist-plugin-axistitle'
import moment from 'moment'
import Chartist from 'chartist'

function BarsChart ({ ...props }) {
  const data = {
    labels: props.data.map(obj => obj.date),
    series: [props.data.map(obj => obj.value)]
  }

  const options = {
    fullWidth: true,
    axisY: {
      onlyInteger: true
    },
    axisX: {
      labelInterpolationFnc: function (value) {
        return moment(value).format('ddd')
      }
    },
    plugins: [
      Chartist.plugins.tooltip({
        transformTooltipTextFnc: function (point) {
          return point
        }
      }),
      Chartist.plugins.ctAxisTitle({
        axisY: {
          axisTitle: props.axisTitle,
          axisClass: 'ct-axis-title'
        }
      })
    ]
  }

  const listener = {
    draw: function (ctx) {
      if (ctx.type === 'bar' && ctx.value.y === 0) {
        // Minimum bar height at 0
        ctx.element.attr({ y1: ctx.y1 - 3 })
      }
    }
  }

  return (
    <div>
      <ChartistGraph listener={listener} data={data} options={options} type={'Bar'} />
    </div>
  )
}

export default withStyles(cardStyle)(BarsChart)

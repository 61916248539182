import React, { useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import TitleWithHelp from '../Titles/TitleWithHelp'
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { DATASET } from '../../consts'
const styles = {
  form: {
    padding: '0px 5% 0'
  },
  save:{
    width:'100px',
    marginTop: '70px'
  },
  input: {
    width: '100%',
    marginTop: '20px'
  },
  select: {
    width: '100%',
    marginTop: '30px'
  },
  textarea: {
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica',
    padding: '10px',
    lineHeight: '26px',
    width: '100%',
    height: '284px',
    resize: 'none',
    boxSizing: 'border-box',
    backgroundColor: '#eee',
    border: '1px solid #ddd',
    borderRadius: '5px'
  },
  titleField: {
    fontSize: '12px',
    fontFamily: 'Roboto, Helvetica',
    lineHeight: '26px',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '15px'
  }
}

const Item = ({ entity: { char } }) => <div>{`${char}`}</div>;

const EmailForm = ({values, updateFunction, classes}) => {
  const initialFormData = {
    subject: values?.subject,
    body: values?.body
  }
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  return (
    <ValidatorForm
      onSubmit={()=> updateFunction({alertTemplate: {...formData}})}
      className={classes.form}
      instantValidate={true} >
      <TitleWithHelp
        title='ALERT TEMPLATE'
        description='Description of alert template'
      />

      <TextValidator
        label='Subject'
        onChange={handleChange}
        name='subject'
        value={formData?.subject}
        className={classes.input}
        validators={['required']}
        errorMessages={['this field is required']}
      />

      <div className={classes.titleField}>Message</div>

      <ReactTextareaAutocomplete
        name='body'
        className={classes.textarea}
        value={formData?.body}
        onChange={handleChange}
        loadingComponent={() => <span>Loading...</span>}
        trigger={{
          '{{': {
          dataProvider: token => {
             return DATASET.map(x => ({ char: x }))
            },
            component: Item,
            output: (item, trigger) => item.char
          }
        }}
      />


      <Button
        className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
        color='primary'
        variant='contained'
        type='submit'
        //disabled={submitted}
      >
        {'Save'}
      </Button>
    </ValidatorForm>
  )
}

export default withStyles(styles)(EmailForm)

import React, { useState } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/styles'
import TitleWithHelp from '../Titles/TitleWithHelp'

const styles = {
  form: {
    padding: '0 5%'
  },
  save:{
    width:'100px',
    marginTop: '70px'
  },
  input: {
    width: '100%',
    marginTop: '20px'
  }
}

const VariablesForm = ({values, updateFunction, classes}) => {

  const initialFormData = {
    agentLost: values?.agentLost,
    estimatedAssetCount: values?.estimatedAssetCount,
    estimatedEmployeeCount: values?.estimatedEmployeeCount
  }

  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  return (
    <ValidatorForm
      onSubmit={() => updateFunction(formData)}
      className={classes.form}
      instantValidate={true} >
      <TitleWithHelp
        title='SETTINGS'
        description='Description of variables'
      />
      <TextValidator
        label='Agent Lost Threshold (days)'
        onChange={handleChange}
        name='agentLost'
        value={formData?.agentLost}
        className={classes.input}
        validators={['required', 'matchRegexp:^[0-9]*$', 'minNumber:1']}
        errorMessages={['this field is required', 'only numbers', 'only positive numbers']}
      />

      <TextValidator
        label='Assets Number (estimated)'
        onChange={handleChange}
        name='estimatedAssetCount'
        value={formData?.estimatedAssetCount}
        className={classes.input}
        validators={['required', 'matchRegexp:^[0-9]*$', 'minNumber:0']}
        errorMessages={['this field is required', 'only numbers', 'only positive numbers']}
      />

      <TextValidator
        label='People Number (estimated)'
        onChange={handleChange}
        name='estimatedEmployeeCount'
        value={formData?.estimatedEmployeeCount}
        className={classes.input}
        validators={['required', 'matchRegexp:^[0-9]*$', 'minNumber:0']}
        errorMessages={['this field is required', 'only numbers', 'only positive numbers']}
      />

      <Button
        className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
        color='primary'
        variant='contained'
        type='submit'
        //disabled={submitted}
      >
        {'Save'}
      </Button>
    </ValidatorForm>
  )
}

export default withStyles(styles)(VariablesForm)

export const ADMIN_ROLE = 'admin'
export const USER_ROLE = 'user'
export const AUTH0_ROLE_KEY = 'https://auth0.bittrap.com/role'
export const DEFAULT_ROWS_PER_PAGE = 10
export const DEFAULT_TIME_TO_REFETCH_INTERVAL = 7000
export const DEFAULT_TIMEOUT_FETCH = 800
export const DEFAULT_LONG_TIMEOUT_FETCH = 1800
export const DEFAULT_DURATION_SNACKBAR = 2000
export const CRUD_OPERATIONS = {
  add: 'add',
  edit: 'edit',
  view: 'view'
}

export const HTTP_METHODS_WEBHOOK = ['POST', 'PATCH', 'PUT']
export const WEBHOOK_DATASET = {
  '{{environment}}': 'The BitTrap environment',
  '{{organization_name}}': 'The name of the organization',
  '{{incident_id}}': 'Identification of the incident',
  '{{tx_id}}': 'Identification of the transaction',
  '{{assets_name}}': 'The name of the affected asset',
  '{{assets_details}}': 'Asset details'
}
export const DATASET = Object.keys(WEBHOOK_DATASET).sort()
export const WEBHOOK_WAIT_AFTER_KEY_PRESS_DEFAULT = 1500
export const INITIAL_HEADER_JSON = {
  'Content-Type': 'application/json'
}

export const INITIAL_BODY_JSON = {
  text: 'Bittrap found an Incident Id: {{incident_id}}, Affected assets: {{assets_name}}, Organization name: {{organization_name}}',
  organizationName: '{{organization_name}}',
  assetName: '{{assets_name}}',
  incidentId: '{{incident_id}}',
  tx: '{{tx_id}}'
}

export const INITIAL_RESPONSE = {
  data: '',
  ok: false
}

export const osOptions = ['Windows', 'MacOS', 'Linux', 'Android', 'IOS', 'Unknown']
export const osOptionsList = { ios: 1, win32: 2, windows: 3, linux: 4, android: 5, macos: 6, darwin: 7 }
export const osColorList = { ios: '#6A6B6D', win32: '#3f51b5', windows: '#3f51b5', linux: '#333', android: '#A4C639', macos: '#CFD0D4', darwin: '#3f51b5' }

export const TIMEZONE_DEFAULT = 'en-US'

const unprotected = {
  text: 'Asset Unprotected',
  color: '#ffd800',
  type: 'warning'
}

export const RISKDATA = {
  protected: {
    text: 'Asset Protected',
    color: '#3ac205',
    type: 'success'
  },
  agent_lost: {
    text: 'Agent Lost',
    color: '#999c98',
    type: 'success'
  },
  unprotected,
  compromised: {
    text: 'Asset Compromised',
    color: '#f24205',
    type: 'error'
  },
  partially_compromised: {
    text: 'Partially Compromised',
    color: '#f24205',
    type: 'error'
  }
}

import * as actions from '../consts/action-types'
import { DEFAULT_ROWS_PER_PAGE } from '../consts'
import { MSG_STATUS } from '../consts/assets'
import { setAddAssetStatus, setUpdateAssetStatus, setUiModal } from './ui'
import * as schema from 'lib/schema'
import { setWallets } from './wallets'
import { CompareObjects, plainOnbjectAndCompareResult } from '../utils'
import auth0Client from 'components/Login/auth'

const ORG_NAME_PROP = 'https://auth0.bittrap.com/organizationName'

export const setAssets = (payload) => ({
  type: actions.SET_ASSETS,
  payload
})

export const setAssetsProps = (payload) => ({
  type: actions.SET_ASSETSPROPS,
  payload
})

export const setAsset = (payload) => ({
  type: actions.SET_ASSET,
  payload
})

export const cleanAsset = () => ({
  type: actions.CLEAN_ASSET
})

export const mergeAsset = (payload) => ({
  type: actions.ASSET_MERGE,
  payload
})

export const removeAsset = (payload) => ({
  type: actions.DELETE_ASSET,
  payload
})

export const setAssetsList = (payload) => ({
  type: actions.SET_ASSETS_LIST,
  payload
})

export const setAssetsPaginationProps = (payload) => ({
  type: actions.SET_ASSETS_PAGINATION_PROPS,
  payload
})

export const fetchAssetsWithPagination = (page = 0, rowsPerPage = DEFAULT_ROWS_PER_PAGE, sortColumn = 'name', sortDirection = 'desc', searchAndFilters, callback) => {
  const paginationProps = { page, rowsPerPage, sortColumn, sortDirection, searchAndFilters }
  const url = '/api/assets?' +
            `page=${page}` +
            `&rpp=${rowsPerPage}` +
            `&sortColumn=${sortColumn}` +
            `&sortDirection=${sortDirection}` +
            `&searchAndFilters=${searchAndFilters}`
  return ({
    type: actions.APICOMPLETE,
    payload: {
      url,
      schema: [schema.asset],
      success: ({ data, props }, dispatch, getState) => {
        const { assets, wallets, ui } = getState()
        const equalProps = plainOnbjectAndCompareResult(assets.paginationProps, paginationProps)
        const equalAssets = CompareObjects(assets.data, data.entities?.assets)
        !equalProps && dispatch(setAssetsPaginationProps(paginationProps))
        if (!equalAssets || !equalProps) { dispatch(setAssets(data.entities?.assets)) }
        !plainOnbjectAndCompareResult(ui.assetsProps, props) && dispatch(setAssetsProps(props))
        !CompareObjects(wallets, data.entities?.wallets) && dispatch(setWallets(data.entities?.wallets))
        callback()
      },
      error: (message) => [
        console.log(message)
      ],
      label: 'assets'
    },
    meta: {
      throttle: 1000
    }
  })
}

export const fetchAssetsOfBearer = (assetBearerId, callback) => (
  {
    type: actions.API_WITH_CONTROL,
    payload: {
      url: '/api/assets/' + assetBearerId + '/assetbearer',
      schema: [schema.asset],
      success: ({ entities }, dispatch, getState) => {
        const { assets, wallets } = getState()
        !CompareObjects(assets, entities?.assets) && dispatch(setAssetsList(entities?.assets))
        !CompareObjects(wallets, entities?.wallets) && dispatch(setWallets(entities?.wallets))
        callback && callback()
      },
      error: (message) => [
        console.log(message)
      ],
      label: 'assetBearersassets'
    },
    meta: {
      throttle: 1000
    }
  })

export const getAssetById = (id) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: `/api/assets/${id}`,
    success: (data, dispatch, getState) => {
      const { assets } = getState()
      data ? !CompareObjects(assets.one, data) && dispatch(setAsset(data)) : dispatch(setAsset([]))
    },
    error: (message) => [
      console.log(message)
    ],
    label: 'asset'
  },
  meta: {
    throttle: 1000
  }
})

export const updateAsset = (assetData, callback) => ({
  type: actions.API,
  payload: {
    url: `/api/assets/${assetData.id}`,
    method: 'PUT',
    schema: schema.asset,
    success: ({ entities }) => [
      setUpdateAssetStatus(MSG_STATUS.UPDATED_OK),
      setUiModal(false),
      mergeAsset(entities.assets),
      callback()
    ],
    error: (message) => [
      setUpdateAssetStatus(message),
      callback()
    ],
    data: {
      name: assetData.name,
      os: assetData.os
    },
    label: 'update_asset'
  }
})

export const deleteAsset = (id) => {
  return (
    {
      type: actions.API,
      payload: {
        url: `/api/assets/${id}`,
        method: 'DELETE',
        schema: schema.asset,
        success: ({ entities }) => [
          removeAsset(id),
          mergeAsset(entities.assets),
          setUpdateAssetStatus(MSG_STATUS.DELETED_OK),
          setUiModal(false)
        ],
        error: (message) => setUpdateAssetStatus(message),
        label: 'delete_asset'
      }
    })
}

export const addAsset = (formData, callback) => {
  const url = '/api/assets/v2'
  return ({
  type: actions.API,
  payload: {
    url,
    method: 'POST',
    schema: schema.asset,
    success: ({ entities }) => [
      setAddAssetStatus(MSG_STATUS.CREATED_OK),
      setUiModal(false),
      callback()
    ],
    error: (message) => [
      setAddAssetStatus(message),
      callback()
    ],
    label: 'asset',
    data: {
      name: formData.name,
      os: formData.os,
      bearer: formData.bearer,
      bearerId: formData.bearerId
    }
  }
})
}

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Chartist from 'chartist'
import ChartistGraph from 'react-chartist'
import cardStyle from '../../assets/jss/material-dashboard-react/components/cardStyle'
import moment from 'moment'

function LineChart ({ ...props }) {
  const series = [{
    data: []
  }]

  if (props.data) {
    series[0].data = props.data.map(point => {
      return {
        x: new Date(point.date).getTime(),
        y: point.value
      }
    })
  }

  const options = {
    // showPoint is set to true so the Chartist tooltip
    // plugin works. We hide the points later from css with:
    // .ct-series-a .ct-point {
    //   stroke: transparent;
    // }
    showPoint: true,
    fullWidth: true,
    high: 100,
    low: 0,
    axisX: {
      labelInterpolationFnc: function (value) {
        return moment(value).format('MMM D')
      },
      divisor: 7,
      type: Chartist.FixedScaleAxis
    },
    axisY: {
      onlyInteger: true
    },
    plugins: [
      Chartist.plugins.tooltip({
        transformTooltipTextFnc: function (point) {
          return parseFloat(point.split(',')[1]).toFixed(2)
        }
      }),
      Chartist.plugins.ctAxisTitle({
        axisY: {
          axisTitle: props.axisTitle,
          axisClass: 'ct-axis-title'
        }
      })
    ]
  }

  return (
    <div>
      <ChartistGraph data={{ series: series }} options={options} type='Line' />
    </div>
  )
}

export default withStyles(cardStyle)(LineChart)

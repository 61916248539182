import auth0 from 'auth0-js'
import { AUTH0_ROLE_KEY, ADMIN_ROLE } from '../../consts'
import API from '../../api'
const api = new API()

class Auth {
  constructor () {
    const domain = process.env.REACT_APP_AUTH_DOMAIN
    const clientID = process.env.REACT_APP_AUTH_CLIENT_ID
    this.auth0 = new auth0.WebAuth({
      domain,
      audience: `https://${domain}/userinfo`,
      clientID,
      redirectUri: window.location.origin + '/callback',
      responseType: 'id_token',
      scope: 'openid profile'
    })

    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getProfile () {
    return process.env.REACT_APP_AUTH_ENABLED === 'true'
      ? this.profile
      : { name: 'satoshin@gmx.com', nickname: 'Satoshi' }
  }

  getIdToken () {
    return this.idToken
  }

  getAuthenticatedUri (uri) {
    if (this.isAuthenticated()) {
      return uri + (uri.includes('?') ? '&' : '?') + `clid=${encodeURIComponent(this.getIdToken())}`
    } else {
      return uri // no changes if not authenticated
    }
  }

  isAuthenticated () {
    return process.env.REACT_APP_AUTH_ENABLED === 'true'
      ? new Date().getTime() < this.expiresAt
      : true
  }

  async isAdmin () {
    const profile = this.getProfile() || {}
    if (!profile[AUTH0_ROLE_KEY]) {
      if (this.isAuthenticated()) { // This hack is for local environment, since the token does not provide the role.
        const data = await api.request({ url: '/api/users' })
        return data?.role ? data.role === ADMIN_ROLE : false
      }
      return false
    }
    return this.isAuthenticated() && profile[AUTH0_ROLE_KEY] === ADMIN_ROLE
  }

  signIn () {
    this.auth0.authorize()
  }

  handleAuthentication () {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        // eslint-disable-next-line eqeqeq
        if (process.env.REACT_APP_E2E_ENABLED) {
          localStorage.setItem('token', authResult.idToken)
        }
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000
        resolve()
      })
    })
  }

  signOut () {
    this.idToken = null
    this.profile = null
    this.expiresAt = null
    this.auth0.logout({ returnTo: window.location.origin + '/logout' })
  }
}

const auth0Client = new Auth()

export default auth0Client

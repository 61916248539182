import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { LinearProgress, Button } from '@material-ui/core'
import { Add as AddIcon, Check as CheckIcon, Settings, Visibility as VisibilityIcon   } from '@material-ui/icons'
import AssetBearerModal from './AssetBearerModal'
import ActionButton from '../Buttons/ActionButton'
import Snackbar from '../Snackbar/Snackbar'
import { debounceSearchRender } from '../TeblesCommon/DebounceSearchRender';
import { getAssetBearersToTable, getAssetBearersProps, getAssetBearersUpdateStatus } from '../../selectors/assetBearers'
import { setAddAssetBearerStatus, setUpdateAssetBearerStatus } from '../../actions/ui'
import { fetchAssetBearersWithPagination } from '../../actions/assetBearers'
import { formatDateTime, encodeSearchAndFilters, timeAgo, getTablesMuiTheme } from '../../utils'
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_TIME_TO_REFETCH_INTERVAL, DEFAULT_DURATION_SNACKBAR, CRUD_OPERATIONS } from '../../consts'
import AssetBearerForm from './AssetBearerForm'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  progress: {
    margin: '0'
  },
  filterMessage: {
    textTransform: 'none'
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    width: '40px',
    height: '40px',
    padding: 0,
    "&:hover": {
      backgroundColor: '#2f41a5',
      color: '#fff',
    },
  },
  addButton: {
    color: "#3f51b5",
    '& a': {
      color: "#3f51b5",
    }
  }
})
class AssetBearersTable extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortOrder: {
        direction: 'desc',
        name: 'createdAt'
      },
      open: false,
      openModal: false,
      filtersToApply: {},
      tableStatePersist: {
        searchText: '',
        filterList: [],
        columns: []
      },
      _await: true,
      operationModal: CRUD_OPERATIONS.add
    }
    this.selectedAssetBearer = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assetBearers !== this.props.assetBearers) {
      this.setState({
        _await: false,
      })
    }
  };

  changePage = (page, sortOrder, rowsPerPage=DEFAULT_ROWS_PER_PAGE, searchText = '', filters={}) => {
    this.setState({
      _await: true,
      page,
      sortOrder,
      rowsPerPage,
      searchText,
      filtersToApply: filters
    });

    let searchAndFiltersEncoded = encodeSearchAndFilters(filters, searchText)

    this.props.fetchAssetBearersWithPagination(page, rowsPerPage,sortOrder.name, sortOrder.direction, searchAndFiltersEncoded, this.callbackFetchAssetBearers)
  };

  callbackFetchAssetBearers = () => {
    this._isMounted && this.setState({ _await: false })
  }

  handleChange = (action, tableState) => {
    switch (action) {
      case 'propsUpdate':
        return null
      case 'search':
      case 'changeRowsPerPage':
      case 'changePage':
      case 'sort':
        this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage, tableState.searchText, this.state.filtersToApply);
        break;
      default:
    }

    this.setState({
      tableStatePersist: {
        searchText: tableState.searchText,
        filterList: tableState.filterList,
        columns: tableState.columns
      }
    })
  }

  getColumns = () => {
    const classes = this.props.classes
    let columns = [
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'surname',
        label: 'Surname',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'address',
        label: 'Address',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'phone',
        label: 'Phone',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'estimatedDevices',
        label: 'Estimated Devices',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'createdAt',
        label:  'Created',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div title={formatDateTime(value)}>{ timeAgo(value) }</div>
            )
          }
        }
      },
      {
        name: '_id',
        label: 'Settings',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
            <ActionButton 
              handleClick={() => this.handleOpenModal(value)}
              customClass={classes.button}
              title={'Edit Person'}>
              <Settings/>
            </ActionButton>
            )
          }
        }
      },
      {
        name: '_id',
        label: 'Detail',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <ActionButton 
              handleClick={() => this.props.history.push(`/assetBearer/${value}`)}
              customClass={classes.button}
              title={'Person Details'}>
              <VisibilityIcon/>
            </ActionButton>

            )
          }
        }
      }
    ]

    for (let i = 0; i < columns.length; i++) {
      columns[i].options.filterList = this.state.tableStatePersist.filterList[i]
      if (this.state.tableStatePersist.columns[i] !== undefined) {
        if (this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
          columns[i].options.display = this.state.tableStatePersist.columns[i].display
      }
    }
    return columns
  }

  getSearchText = () => {
    return this.state.tableStatePersist.searchText
  }

  getAssetBearer = (val) => {
    let filter = this.props.assetBearers.filter(assetBearer => assetBearer._id === val)
    return filter[0] ? filter[0] : {}
  }

  setSelectedAssetBearer = (val) => {
    this.selectedAssetBearer = this.getAssetBearer(val)
  }

  assetBearerId = (val) => {
    return this.getAssetBearer(val)._id
  }

  handleOpenModal = val => {
    this.setSelectedAssetBearer(val)
    val ? this.setState({operationModal: CRUD_OPERATIONS.edit}) : this.setState({operationModal: CRUD_OPERATIONS.add})
    this.openModal()
  };

  handleCloseModal = () => {
    this.setState({openModal: false})
    const {page, rowsPerPage, sortOrder, searchText, filtersToApply} = this.state
    let searchAndFiltersEncoded = encodeSearchAndFilters(filtersToApply, searchText)
    this.props.fetchAssetBearersWithPagination(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchAndFiltersEncoded, this.callbackFetchAssetBearers)
  };

  openModal = () => {
    this.setState({openModal: true})
  }

  componentDidMount () {
    this._isMounted = true;
    const {page, rowsPerPage, sortOrder} = this.state
    this.setState({
      filtersToApply: {status:this.props.status}
    });
    let searchAndFilters = encodeSearchAndFilters({status:this.props.status}) //add the filter of the dashboard cards of status
    this.props.fetchAssetBearersWithPagination(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchAndFilters, this.callbackFetchAssetBearers)

    this.interval = setInterval(() => {
      if (this._isMounted === true){
        const {page, rowsPerPage, sortOrder, searchText, filtersToApply} = this.state
        let searchAndFiltersEncoded = encodeSearchAndFilters(filtersToApply, searchText)
        this.props.fetchAssetBearersWithPagination(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchAndFiltersEncoded, this.callbackFetchAssetBearers)
      }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval)
  }

  handleFilterSubmit = (applyFilters, columns) => {
    let filterList = applyFilters();
    let filtersToApply = {}
    for (let index = 0; index < filterList.length; ++index) {
      if (filterList[index][0] !== undefined){
        filtersToApply[columns[index].name] = filterList[index][0]
      }
    }
    const {page, sortOrder, rowsPerPage, searchText} = this.state
    this.changePage(page, sortOrder, rowsPerPage, searchText, filtersToApply)
  };

  isFirstLoadingData = () => {
    return !this.props.assetBearersProps.totalCount && this.props.assetBearersProps.totalCount !== 0
  }

  closeSnackbar() {
    this.props.setAddAssetBearerStatus('')
    this.props.setUpdateAssetBearerStatus('')
  }

  isStatusOk() {
    return this.props.statusMessage.indexOf('OK') !== -1
  }

  render () {
    const { _await } = this.state
    const classes = this.props.classes
    const columns = this.getColumns()
    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      filter: false,
      print: false,
      count: this.props.assetBearersProps.totalCount,
      rowsPerPage: this.props.assetBearersProps.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
      rowsPerPageOptions: [10, 25, 50, 100],
      serverSide: true,
      searchText: this.getSearchText(),
      selectableRows: 'none',
      sortOrder: this.state.sortOrder,
      confirmFilters: true,
      customToolbar: () => {
        return (
          <ActionButton handleClick={() => this.handleOpenModal(null)} title={'Add Person'} customClass={classes.addButton}>
            <AddIcon/>
          </ActionButton>
        )
      },
      setFilterChipProps: (colIndex, colName, data) => {
        return {
          label: colName.toUpperCase()+': '+data
        };
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant='contained' onClick={() => this.handleFilterSubmit(applyNewFilters, columns)}>Apply Filters</Button>
          </div>
        );
      },

      onFilterChange: (column, filterList, type) => {
        if (type === 'chip') { //When close a chip
          var newFilters = () => (filterList);
          this.handleFilterSubmit(newFilters, columns);
        }
      },
      textLabels: {
        body: {
          noMatch: <span className={classes.filterMessage}>No Person found</span>
        }
      },
      onTableChange: (action, tableState) => this.handleChange(action, tableState),
      customSearchRender: debounceSearchRender(500),
    }
    return (
      <>
        <MuiThemeProvider theme={getTablesMuiTheme()}>
          <div className='AssetBearers'>
          {this.props.statusMessage && this.isStatusOk() && <Snackbar
            message={this.props.statusMessage}
            open={this.props.statusMessage !== '' }
            color={'success'}
            icon={ CheckIcon }
            closeNotification={() => this.closeSnackbar()}
            close
            autoHide={DEFAULT_DURATION_SNACKBAR}
          />}
            <div style={{ maxWidth: '100%' }}>
              <LinearProgress className={ classes.progress } style={{visibility:!_await&&'hidden'}} />
              { 
              this.isFirstLoadingData()
                ? <LinearProgress />
                : <MUIDataTable
                    title=''
                    options={options}
                    columns={columns}
                    data={this.props.assetBearers}
                  />
             }
            </div>
            <AssetBearerModal 
              operation={ this.state.operationModal }
              open={ this.state.openModal } 
              hideNotifications={this.closeSnackbar}
              handleClose={ this.handleCloseModal }>
                <AssetBearerForm selectedAssetBearer={this.selectedAssetBearer} operation={this.state.operationModal} handleClose={this.handleCloseModal}/>  
            </AssetBearerModal>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

const mapStateToProps = state => ({
  assetBearers: getAssetBearersToTable(state),
  assetBearersProps: getAssetBearersProps(state),
  statusMessage: getAssetBearersUpdateStatus(state)
})

const mapDispatchToProps = {
  fetchAssetBearersWithPagination,
  setAddAssetBearerStatus,
  setUpdateAssetBearerStatus
}

AssetBearersTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withRouter,connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AssetBearersTable)

import Immutable from 'seamless-immutable'
import * as timeago from 'timeago.js'
import auth from './components/Login/auth'
import { RISKDATA, TIMEZONE_DEFAULT } from './consts'
import { createTheme } from '@material-ui/core/styles'
export const sortImmutable = (immutable) => {
  const sortable = []

  for (const key in immutable) {
    sortable.push([key, immutable[key]])
  }

  sortable.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
  return Immutable(Object.fromEntries(sortable))
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const percentage = (count, total, estimated) => {
  // We take max between real and estimates values to return
  // correct results in case estimates were not updated properly
  return percent(count, Math.max(total, estimated))
}

export const percent = (n, m) => {
  return m ? ((n / m) * 100).toFixed(1) : 0
}

export const formatDateTime = (dateTime, timezone = TIMEZONE_DEFAULT, viewOptions = { hour12: false }, separateString = ' at') => {
  return new Date(dateTime).toLocaleString(timezone, viewOptions).replace(',', separateString)
}

export const timeAgo = (value, timezone = TIMEZONE_DEFAULT) => {
  return timeago.format(value, timezone)
}

export const omit = (obj, omitKey) => {
  return Object.keys(obj).reduce((result, key) => {
    if (key !== omitKey) {
      result[key] = obj[key]
    }
    return result
  }, {})
}

export const CompareObjects = (object1, object2, prop = 'updatedAt') => {
  object1 = CleanObjectOfState(object1)
  object2 = CleanObjectOfState(object2)
  if (!object1 || !object2) {
    return false
  }
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length || keys2.length === 0) {
    return false
  }

  for (const assetId of keys1) {
    if (!object1[assetId] || !object2[assetId]) {
      return false
    }

    if (object1[assetId][prop] !== object2[assetId][prop]) {
      return false
    }
  }
  return true
}

export const CleanObjectOfState = (obj) => {
  if (obj?.asMutable) {
    // eslint-disable-next-line camelcase
    const { asMutable, getIn, merge, replace, set, setIn, setPrototypeOf, update, updateIn, without, _inmutable_invariants_hold, ...res } = obj
    return res
  }
  return obj
}

export const plainOnbjectAndCompareResult = (object1, object2) => {
  object1 = CleanObjectOfState(object1)
  object2 = CleanObjectOfState(object2)
  if (!object1 || !object2) {
    return false
  }
  const strObject1 = JSON.stringify(object1)
  const strObject2 = JSON.stringify(object2)
  return strObject1 === strObject2
}

export const CompareAllObject = (object1, object2) => {
  object1 = CleanObjectOfState(object1)
  object2 = CleanObjectOfState(object2)
  if (!object1 || !object2) {
    return false
  }
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length || keys2.length === 0) {
    return false
  }

  for (const assetId of keys1) {
    if (object1[assetId] !== object2[assetId]) {
      return false
    }
  }
  return true
}

export const objectIsEmpty = (obj) => {
  return Object.keys(obj).length !== 0
}

const percentToByte = p => {
  return String.fromCharCode(parseInt(p.slice(1), 16))
}

const toBase64 = value => {
  return window.btoa(encodeURIComponent(JSON.stringify(value)).replace(/%[0-9A-F]{2}/g, percentToByte))
}

export const encodeSearchAndFilters = (filters = {}, searchText = '') => {
  const searchAndFilters = { ...filters, search: searchText }
  return toBase64(searchAndFilters)
}

export const sortData = (data, sortOrder) => {
  const sortField = sortOrder.name
  const sortDir = sortOrder.direction
  let sortedData = data
  if (sortField) {
    sortedData = data.sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return -1 * (sortDir === 'asc' ? -1 : 1)
      } else if (a[sortField] > b[sortField]) {
        return 1 * (sortDir === 'asc' ? -1 : 1)
      } else {
        return 0
      }
    })
  }
  return sortedData
}

export const getRiskColor = (asset) => {
  return RISKDATA[asset.status].color
}

export const getRisk = (asset) => {
  return RISKDATA[asset.status]
}

export const parseAssetStatus = (status) => {
  // replace spaces with `_`
  return status ? status.replace(/ /g, '_').toLowerCase() : status
}

export const downloadFromLink = async (linkRef, url, callback) => {
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${auth.getIdToken()}`
    }
  })
  if (!response.ok) {
    callback && callback()
    return
  }

  const blob = await response.blob()
  const href = window.URL.createObjectURL(blob)

  let fileName
  if (response.headers.has('Content-Disposition')) {
    [, fileName] = response.headers.get('Content-Disposition').split('filename=')
  }
  linkRef.download = fileName ? fileName.replace(/"/g, '') : 'bittrap'
  linkRef.href = href
  linkRef.click()
  callback && callback()
}

export const isStatusOk = (status) => {
  return status.indexOf('OK') !== -1
}

export const getTablesMuiTheme = () => createTheme({
  overrides: {
    MUIDataTable: {
      root: {
        backgroundColor: '#AAF'
      },
      paper: {
        boxShadow: 'none'
      }
    },
    MUIDataTableToolbar: {
      icon: {
        color: '#3f51b5'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        borderBottom: '4px solid rgba(0,0,0,.15)',
        paddingBottom: '6px',
        textTransform: 'uppercase',
        fontWeight: '300',
        color: '#666',
        fontSize: '.8rem'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        backgroundColor: '#fff'
      }
    }
  }
})

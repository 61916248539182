import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close';
import ActionButton from '../Buttons/ActionButton'
import { connect } from 'react-redux'
import { addOrganization, updateOrganization } from '../../actions/organizations'
import Slide from '@material-ui/core/Slide';
import {titles, operations, getFieldsOrganization} from './OrganizationUtils'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textBox: {
    width: "100%",
    margin: "5px 0",
  },
  button: {
    width:'30%',
    margin: '20px auto 0',
    display: "block"
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    margin: "10px 0",
  },
  close: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.54)",
    position: "absolute",
    right: "0",
    top: "0",
  }
}));
const OrganizationModal = (props) => {
  const { open, handleClose, operation, selectedOrganization} = props
  const [formData, setFormData] = useState()
  const [onOperation, setOnOperation] = useState(false)
  const classes = useStyles();

  useEffect(() => {
    selectedOrganization && setFormData(selectedOrganization)
  },[selectedOrganization])

  useEffect(() => {
    if (open && operation === operations.add){
      setFormData()
    }else{
      setFormData(selectedOrganization)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const closeModal = (changedData) => {
    handleClose(changedData)
  }

  const handleSubmit = () => {
    if (operation !== operations.view) {
      setOnOperation(true);
      operation === operations.add ? props.addOrganization(formData) : props.updateOrganization(getFieldsOrganization(formData))
      setOnOperation(false)
      closeModal(true)
    }else{
      closeModal(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modal}
    >
      <Slide direction="up" in={open}>
      <div className={classes.paper}>
        <ActionButton handleClick={closeModal} customClass={classes.close}>
          <CloseIcon/>
        </ActionButton>
        <div className={classes.title}>{titles[operation]}</div>
        <ValidatorForm
            instantValidate={true}
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
        >
          <TextValidator
              label='Name *'
              id='name'
              onChange={handleChange}
              name='name'
              value={formData?.name}
              validators={['required']}
              errorMessages={['The name is required']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <TextValidator
              label='Accounts batch size *'
              id='accountsBatchSize'
              onChange={handleChange}
              name='accountsBatchSize'
              value={formData?.accountsBatchSize}
              validators={['required', 'isNumber','minNumber:0']}
              errorMessages={['The accounts batch size is required', 'Enter a number', 'Must be greater than 0']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <TextValidator
              label='Accounts per wallet *'
              id='accountsPerWallet'
              onChange={handleChange}
              name='accountsPerWallet'
              value={formData?.accountsPerWallet}
              validators={['required', 'isNumber','minNumber:0']}
              errorMessages={['The accounts per wallet is required', 'Enter a number', 'Must be greater than 0']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <TextValidator
              label='Unassigned wallets pool size *'
              id='unassignedWalletsPoolSize'
              onChange={handleChange}
              name='unassignedWalletsPoolSize'
              value={formData?.unassignedWalletsPoolSize}
              validators={['required', 'isNumber','minNumber:0']}
              errorMessages={['The anassigned wallets pool size is required', 'Enter a number', 'Must be greater than 0']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <TextValidator
              label='Wallets per batch *'
              id='walletsPerBatch'
              onChange={handleChange}
              name='walletsPerBatch'
              value={formData?.walletsPerBatch}
              validators={['required', 'isNumber','minNumber:0']}
              errorMessages={['The wallets per batch is required', 'Enter a number', 'Must be greater than 0']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <TextValidator
              label='Max collision tolerance *'
              id='maxCollisionTolerance'
              onChange={handleChange}
              name='maxCollisionTolerance'
              value={formData?.maxCollisionTolerance}
              validators={['required', 'isNumber','minNumber:0']}
              errorMessages={['The max collision tolerance is required', 'Enter a number', 'Must be greater than 0']}
              className={classes.textBox}
              InputProps={{readOnly: operation === operations.view}}
          />
          <Button 
            type="submit" 
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={onOperation}
          >{operation === operations.view? "Ok": "Save"}</Button>
        </ValidatorForm>
      </div>
      </Slide>
    </Modal>
  )
}

const mapStateToProps = state => ({
  //organizations: Object.keys(state.organizations).map(_id => state.organizations[_id]),
})

const mapDispatchToProps = {
  addOrganization,
  updateOrganization,
}

OrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  operation: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationModal)

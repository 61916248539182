import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from '../consts/action-types'
import { omit } from '../utils'

const initialState = Immutable({
  data: {}
})

export default handleActions({
  [actions.SET_WEBHOOKS]: (webhooks, { payload }) => webhooks.set('data', payload),
  [actions.DELETE_WEBHOOK]: (webhooks, { payload }) => webhooks.set('data', omit(webhooks.data, payload))
}, initialState)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import EditAsset from '../MiniForm/EditAsset'
import AssetsTools from '../../views/AssetsList/AssetsTools'
import { objectIsEmpty } from '../../utils'
import ActionButton from '../Buttons/ActionButton'
import CloseIcon from '@material-ui/icons/Close';

function getModalStyle () {
  const top = 30
  const left = 45
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate( -${top}% , -${left}%)`
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '3px'
  },
  close: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.54)",
    position: "absolute",
    right: "0",
    top: "0",
  }
}))

export function AssetsModal (props) {
  const classes = useStyles()
  // getModalStyle is not a pure function
  const [modalStyle] = React.useState(getModalStyle)
  const { open, selectedAsset, onClose } = props
  const handleClose = () => onClose()

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ActionButton handleClick={handleClose} customClass={classes.close}>
        <CloseIcon/>
      </ActionButton>
      { objectIsEmpty(selectedAsset) ? 
        <EditAsset selectedAsset={selectedAsset} ></EditAsset> :
        <AssetsTools/>
      }
    </div>
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      
      {body}
    </Modal>
  )
}
AssetsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedAsset: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

import { createSelector } from 'reselect'
import { sortImmutable } from '../utils'

const getAssets = (state) => state.assets
const getUI = state => state.ui
const getWallets = state => state.wallets

export const getAssetsToTable = createSelector(
  [getAssets],
  (assets) => {
    return Object.keys(assets.data).map(_id => assets.data[_id])
  }
)

export const getAssetsList = createSelector(
  [getAssets],
  (assets) => {
    return Object.keys(sortImmutable(assets.list)).map(_id => assets.list[_id])
  }
)

export const getAsset = createSelector(
  [getAssets],
  (assets) => {
    return assets.one
  }
)

export const getAssetsProps = createSelector(
  [getUI],
  (ui) => {
    return ui.assetsProps
  }
)

export const getModal = createSelector(
  [getUI],
  (ui) => {
    return ui.modal
  }
)

export const getDialog = createSelector(
  [getUI],
  (ui) => {
    return ui.dialog
  }
)

export const getAssetsUpdateStatus = createSelector(
  [getUI],
  (ui) => {
    return ui.addAssetStatus || ui.updateAssetStatus
  }
)

export const getAssetsOnlyUpdateStatus = createSelector(
  [getUI],
  (ui) => {
    return ui.updateAssetStatus
  }
)

export const getWalletsToTable = createSelector(
  [getWallets],
  (wallets) => {
    return Object.keys(wallets).map(_id => wallets[_id])
  }
)

import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from 'consts/action-types'

const initialState = Immutable({
  data: {},
  assetsIncident: {}
})

export default handleActions({
  [actions.SET_INCIDENTS]: (incidents, { payload }) => incidents.set('data', payload),
  [actions.SET_ASSETS_INCIDENT]: (incidents, { payload }) => incidents.set('assetsIncident', payload),
}, initialState)

export const titles = {
  add: 'Complete the form to create a new organization',
  edit: 'Edit the form to update the organization',
  view: 'The organization data'
}

export const operations = {
  add: 'add',
  edit: 'edit',
  view: 'view'
}

export const getFieldsOrganization = (data) => {
  return {
    id: data._id,
    estimatedAssetCount: data.estimatedAssetCount,
    estimatedEmployeeCount: data.estimatedEmployeeCount,
    name: data.name,
    accountsBatchSize: data.accountsBatchSize,
    accountsPerWallet: data.accountsPerWallet,
    unassignedWalletsPoolSize: data.unassignedWalletsPoolSize,
    walletsPerBatch: data.walletsPerBatch,
    maxCollisionTolerance: data.maxCollisionTolerance
  }
}

import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from '../consts/action-types'
import { omit } from '../utils'

const initialState = Immutable({
  data: {},
  list: {},
  one: {},
  paginationProps: {}
})

export default handleActions({
  [actions.SET_ASSETS_LIST]: (assets, { payload }) => assets.set('list', payload),
  [actions.SET_ASSETS]: (assets, { payload }) => assets.set('data', payload || {}),
  [actions.SET_ASSET]: (assets, { payload }) => assets.set('one', payload),
  [actions.DELETE_ASSET]: (assets, { payload }) => assets.set('data', omit(assets.data, payload)),
  [actions.ASSET_MERGE]: (assets, { payload }) => assets.merge(Immutable(payload)),
  [actions.SET_ASSETS_PAGINATION_PROPS]: (assets, { payload }) => assets.set('paginationProps', payload || {}),
  [actions.CLEAN_ASSET]: (assets) => assets.set('one', {})
}, initialState)

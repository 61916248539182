import React from 'react'
import { withStyles } from '@material-ui/styles'

const styles = {
  title: {
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#666',
    letterSpacing: '0.01071em',
    marginTop: '50px',
    paddingBottom: '8px',
    marginBottom: '10px'
  },
  help: {
    float: 'right',
    marginRight: '10px'
  },
}

const TitleWithHelp = ({title, description, classes}) => (
    <h6 className={classes.title}>
    {title}
  </h6>
)

export default withStyles(styles)(TitleWithHelp)

import React, { useState, useEffect } from 'react'
import { WEBHOOK_WAIT_AFTER_KEY_PRESS_DEFAULT } from '../../consts'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import { withStyles } from '@material-ui/styles'
import { Clear, Check } from '@material-ui/icons'

const styles = {
  tab: {
    color: '#666',
    display: 'block'
  },
  tabs: {
    '& .indicator': {
      height: '4px',
      marginBottom: '5px',
      backgroundColor: '#3657B7'
    },
    "& .flexContainer": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  label: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  ok: {
    color: 'green'
  },
  error: {
    color: 'red'
  }
}

const JSONInputStyles = {
  body: {
    fontSize: '15px',
    lineHeight: '22px',
    padding: '15px 0 10px 10px',
    boxSizing: 'border-box'
  },
  labelColumn: {
    color: 'black'
  },
  labels: {
    textAlign: 'center',
    backgroundColor:'#555'
  }
}

const TABS = {
  header: 0,
  body: 1,
  response: 2
}

const TabPanel = ({ children, value, index }) => (
  value === index && <div>{children}</div>
);

const WebhookTabs = ({headerData, handleHeaderChange, bodyData, handleBodyChange, responseData, classes}) => {
  const [tabState, setTabState] = useState(TABS.body)
  const handleTabChange = (event, newValue) => {
    setTabState(newValue);
  }

  const getIcon = (ok) => {
    return ok ? <Check className={classes.ok}/>:<Clear  className={classes.error}/>
  }
  useEffect(()=>{
    !responseData.data && tabState === TABS.response && setTabState(TABS.body)
    responseData.data && tabState !== TABS.response && setTabState(TABS.response)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData.data])

  return (
    <>
      <Tabs
        value={tabState}
        onChange={handleTabChange}
        aria-label="webhook tabs" 
        classes={{
          root: classes.tabs,
          flexContainer: 'flexContainer',
          indicator: 'indicator'
        }}
      >
        <Tab label={<div className={classes.label}>Header</div>} />
        <Tab label={<div className={classes.label}>Body</div>} />
        <Tab 
          label={<div className={classes.label}>Response {responseData.data && getIcon(responseData.ok)}</div>} 
          className={classes.tab} 
          disabled={!responseData.data}
        />
      </Tabs>
      <TabPanel value={tabState} index={TABS.header}>
        <JSONInput
          id = 'header'
          name = 'headers'
          height = '300px'
          width = '100%'
          style = {JSONInputStyles}
          confirmGood = { false }
          locale = { locale }
          placeholder = { headerData }
          waitAfterKeyPress = { WEBHOOK_WAIT_AFTER_KEY_PRESS_DEFAULT }
          onBlur={handleHeaderChange}
          onChange={handleHeaderChange}
        />
      </TabPanel>
      <TabPanel value={tabState} index={TABS.body}>
        <JSONInput
          id = 'body'
          name = 'body'
          height = '300px'
          width = '100%'
          style = {JSONInputStyles}
          confirmGood = { false }
          locale = { locale }
          placeholder = { bodyData }
          waitAfterKeyPress = { WEBHOOK_WAIT_AFTER_KEY_PRESS_DEFAULT }
          onBlur={handleBodyChange}
          onChange={handleBodyChange}
        />
      </TabPanel>
      <TabPanel value={tabState} index={TABS.response}>
        <JSONInput
          id = 'response'
          name = 'response'
          height = '300px'
          width = '100%'
          style = {JSONInputStyles}
          confirmGood = { false }
          locale = { locale }
          placeholder = { responseData?.data || {}}
          viewOnly = { true }
        />
      </TabPanel>
      </>
  )
}
export default withStyles(styles)(WebhookTabs)

import React from 'react'
import { withStyles } from '@material-ui/styles'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

const styles = {
  cardCategory: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardBody: {
    padding: '0.9375rem 30px!important'
  },
  cardTitle: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none'
  }
}

const SimplePage = ({title, subtitle, children, footer, classes}) => {
  return ( 
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={ classes.cardTitle } >{title}</h4>
              <p className={ classes.cardCategory}>{subtitle}</p>
            </CardHeader>
            <CardBody className={ classes.cardBody } >
              {children}
            </CardBody>
            <CardFooter>
              {footer}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
export default withStyles(styles)(SimplePage)

import auth from './components/Login/auth'

export default class API {
  constructor () {
    this.params = {
      headers: {
        authorization: null,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }

  request ({ url, method = 'GET', data } = {}) {
    this.params.method = method
    this.params.body = JSON.stringify(data)
    if (auth.isAuthenticated()) {
      this.params.headers.authorization = `Bearer ${auth.getIdToken()}`
      return fetch(url, this.params)
        .then(response => response.json())
        .catch(error => {
          console.error(error)
          throw error
        })
    } else {
      auth.signOut()
    }
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchIncidents } from 'actions/incidents'
import { fetchAssetsOfBearer } from 'actions/assets'
import EditAssetBearer from 'components/Form/EditAssetBearer'
import { DEFAULT_TIME_TO_REFETCH_INTERVAL, DEFAULT_DURATION_SNACKBAR } from '../../consts'
import { getAssetBearersUpdateStatus } from '../../selectors/assetBearers'
import { getAssetsUpdateStatus } from '../../selectors/assets'
import { setAddAssetBearerStatus, setUpdateAssetBearerStatus, setAddAssetStatus } from '../../actions/ui'
import Snackbar from '../../components/Snackbar/Snackbar'
import CheckIcon from '@material-ui/icons/Check'
import SimplePage from '../../components/Templates/SimplePage'

class AssetBearer extends Component {
  componentDidMount () {
    this.props.fetchAssetsOfBearer(this.props.match.params.id)
    this.intervalAssets = setInterval(() => {
      this.props.fetchAssetsOfBearer(this.props.match.params.id)
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)

    this.props.fetchIncidents()
    this.intervalIncidents = setInterval(() => {
      this.props.fetchIncidents()
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }

  componentWillUnmount () {
    clearInterval(this.intervalIncidents)
    clearInterval(this.intervalAssets)
  }

  closeSnackbar() {
    this.props.setAddAssetBearerStatus('')
    this.props.setUpdateAssetBearerStatus('')
    this.props.setAddAssetStatus('')
  }

  isStatusOk() {
    return this.props.statusMessage.indexOf('OK') !== -1
  }

  render () {
    this.props.fetchAssetsOfBearer(this.props.match.params.id)
    return <>
      {this.props.statusMessage && this.isStatusOk() && <Snackbar
        message={this.props.statusMessage}
        open={this.props.statusMessage !== '' }
        color={"success"}
        icon={ CheckIcon }
        closeNotification={() => this.closeSnackbar()}
        close
        autoHide={DEFAULT_DURATION_SNACKBAR}
      />}
      <SimplePage title='Person'>
        { this.props.match.params.id
          ? <EditAssetBearer selectedAssetBearerId={ this.props.match.params.id } ></EditAssetBearer>
          : null
        }
      </SimplePage>
    </>
  }
}

const mapStateToProps = state => ({
  statusMessage: getAssetBearersUpdateStatus(state) || getAssetsUpdateStatus(state),
})

const mapDispatchToProps = {
  fetchIncidents,
  fetchAssetsOfBearer,
  setAddAssetBearerStatus,
  setAddAssetStatus,
  setUpdateAssetBearerStatus
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetBearer)

import React from 'react'
import AssetBearersTable from 'components/AssetBearers/AssetBearersTable'
import SimplePage from '../../components/Templates/SimplePage'

const PeopleList = (props) => {

  return (
    <SimplePage title='People'>
      <AssetBearersTable status={ props.match.params.status } />
    </SimplePage>
  )
}

export default PeopleList

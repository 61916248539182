import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import Security from '@material-ui/icons/Security'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/icons/Menu'
import auth0Client from '../Login/auth'
import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.jsx'

function Header ({ ...props }) {
  const { classes, color } = props
  const appBarClasses = classNames({
    [' ' + classes[color]]: color
  })
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        {auth0Client.isAuthenticated() &&
          <div className={classes.flex}>
            <ListItem className={classes.accountItem} >
              <AccountCircle className={classes.accountIcon} />
              <ListItemText className={classes.accountText} primary={auth0Client.getProfile().nickname} />
              { /* <Security className= { classes.accountIcon } />
              <ListItemText className= { classes.accountText }  primary="Company"  /> */ }
            </ListItem>
          </div>
        }
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={ props.handleDrawerToggle }
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>

  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger'])
}

export default withStyles(headerStyle)(Header)

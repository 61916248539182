import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notifyInstall, deleteWallet } from 'actions/wallets'
import { fetchIncidents } from 'actions/incidents'
import EditAsset from '../../components/Form/EditAsset'
import SimplePage from '../../components/Templates/SimplePage'
import { DEFAULT_TIME_TO_REFETCH_INTERVAL, DEFAULT_TIMEOUT_FETCH } from '../../consts'
class Asset extends Component {
  componentDidMount () {
    setTimeout(() => {
      this.props.fetchIncidents()
      this.intervalIncidents = setInterval(() => {
        this.props.fetchIncidents()
      }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
    }, DEFAULT_TIMEOUT_FETCH)
  }

  componentWillUnmount () {
    clearInterval(this.intervalIncidents)
  }

  render () {
    return (
    <SimplePage title='Asset'>
      { this.props.match.params.id
        ? <EditAsset selectedAsset={ this.props.match.params.id } ></EditAsset>
        : null
      }
    </SimplePage>
    )
  }}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  fetchIncidents,
  notifyInstall,
  deleteWallet
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Asset)

export const titles = {
  add: 'Complete the form to add a person',
  edit: 'Edit the form to update the person´s information',
  view: 'The person´s information',
  addAsset: 'Complete the form to add an asset'
};

export const getFieldsAssetBearer = (data) => {
  return {
    id: data._id,
    email: data.email,
    name: data.name,
    surname: data.surname,
    address: data.address,
    phone: data.phone,
    estimatedDevices: data.estimatedDevices,
  }
}

import { createSelector } from 'reselect'
import { sortImmutable } from '../utils'

const getIncidents = (state) => state.incidents

export const getIncidentsToTable = createSelector(
  [getIncidents],
  (incidents) => {
    return Object.keys(sortImmutable(incidents.data)).map(_id => incidents.data[_id])
  }
)

export const getAssetsIncidentToTable = createSelector(
  [getIncidents],
  (incidents) => {
    return Object.keys(incidents?.assetsIncident).map(_id => incidents?.assetsIncident[_id])
  }
)

export const getAssetBearersIncidents = createSelector(
  [getIncidents],
  (incidents) => {
    return Object.keys(sortImmutable(incidents.data)).map(_id => incidents.data[_id])
  }
)

import * as actions from '../consts/action-types'
import * as schema from '../lib/schema'
import { setAddOrganizationStatus, setUpdateOrganizationStatus } from './ui'

const setStatus = (payload) => ({
  type: actions.SET_STATUS,
  payload
})

const setSettings = (payload) => ({
  type: actions.SET_ORGANIZATIONS_SETTINGS,
  payload
})

const setOrganizations = (payload) => ({
  type: actions.SET_ORGANIZATIONS,
  payload
})

export const setOrganization = (payload) => ({
  type: actions.SET_ORGANIZATION,
  payload
})

export const mergeOrganization = (payload) => ({
  type: actions.ORGANIZATION_MERGE,
  payload
})

export const fetchOrganizations = () => ({
  type: actions.API,
  payload: {
    url: '/api/organizations',
    method: 'GET',
    schema: [schema.organization],
    success: ({ entities }) => [
      setOrganizations(entities.organizations)
    ],
    label: 'getAll_organizations'
  },
  meta: {
    throttle: 1000
  }
})

export const getOrganization = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/organizations/${id}`,
    schema: [schema.organization],
    success: ({ entities }) => [
      setOrganization(entities.organizations)
    ],
    label: 'get_organization'
  },
  meta: {
    throttle: 1000
  }
})

export const updateOrganization = (organizationData) => ({
  type: actions.API,
  payload: {
    url: `/api/organizations/${organizationData.id}`,
    method: 'PUT',
    schema: schema.organization,
    success: ({ entities }) => [
      setUpdateOrganizationStatus('Organization updated ok')
    ],
    error: (message) => setUpdateOrganizationStatus(message),
    data: {
      ...organizationData
    },
    label: 'update_Organization'
  }
})

export const deleteOrganization = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/organizations/${id}`,
    method: 'DELETE',
    schema: schema.organization,
    success: ({ entities }) => [
      mergeOrganization(entities.organizations),
      setUpdateOrganizationStatus('Organization deleted ok')
    ],
    error: (message) => setUpdateOrganizationStatus(message),
    label: 'delete_Organization'
  }
})

export const addOrganization = (organizationData) => ({
  type: actions.API,
  payload: {
    url: '/api/organizations',
    method: 'POST',
    schema: schema.organization,
    success: ({ entities }) => [
      setAddOrganizationStatus('Organization created ok')
    ],
    error: (message) => setAddOrganizationStatus(message),
    label: 'add_organization',
    data: { ...organizationData }
  }
})

export const fetchSettings = () => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/settings',
    method: 'GET',
    success: (data, dispatch, getState) => {
      dispatch(setSettings(data))
    },
    label: 'Fetch settings'
  }
})

export const updateSettings = (formData) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/settings',
    method: 'PUT',
    success: (data, dispatch) => {
      dispatch(setSettings(data))
      dispatch(setStatus('Settings updated OK'))
    },
    error: (message, dispatch) => [
      dispatch(setStatus(message))
    ],
    data: {
      ...formData
    },
    label: 'Update settings'
  }
})

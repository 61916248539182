import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from '../consts/action-types'

const COLORS = ['#e30d40', '#0d75e3', '#e35f0d', '#3fe30d']

const initialState = Immutable({
  requests: {},
  theme: 0,
  modal: false,
  dialog: false,
  addAssetStatus: '',
  updateAssetStatus: '',
  addAssetBearerStatus: '',
  updateAssetBearerStatus: '',
  addOrganizationStatus: '',
  updateOrganizationStatus: '',
  updateOptionsTableOrganization: '',
  updateColumnsTableOrganization: {},
  addWebhookStatus: '',
  assetBearersProps: {},
  assetsProps: {},
  status: ''
})

export default handleActions({
  [actions.SWITCH_THEME]: (ui) => ui.update('theme', curr => (curr + 1) % COLORS.length),
  [actions.SET_ADDASSET_STATUS]: (ui, { payload = '' }) => ui.set('addAssetStatus', payload),
  [actions.SET_ADDASSETBEARER_STATUS]: (ui, { payload = '' }) => ui.set('addAssetBearerStatus', payload),
  [actions.SET_ADDORGANIZATION_STATUS]: (ui, { payload = '' }) => ui.set('addOrganizationStatus', payload),
  [actions.SET_UI_MODAL]: (ui, { payload = '' }) => ui.set('modal', payload),
  [actions.SET_UI_DIALOG]: (ui, { payload = '' }) => ui.set('dialog', payload),
  [actions.SET_UPDATEASSET_STATUS]: (ui, { payload = '' }) => ui.set('updateAssetStatus', payload),
  [actions.SET_UPDATEASSETBEARER_STATUS]: (ui, { payload = '' }) => ui.set('updateAssetBearerStatus', payload),
  [actions.SET_UPDATEORGANIZATION_STATUS]: (ui, { payload = '' }) => ui.set('updateOrganizationStatus', payload),
  [actions.SET_OPTIONS_TABLEORGANIZATION]: (ui, { payload = '' }) => ui.set('updateOptionsTableOrganization', payload),
  [actions.SET_COLUMNS_TABLEORGANIZATION]: (ui, { payload = {} }) => ui.set('updateColumnsTableOrganization', { ...ui.updateColumnsTableOrganization, ...payload }),
  [actions.SET_ASSETSPROPS]: (ui, { payload = '' }) => ui.set('assetsProps', payload),
  [actions.SET_ASSETBEARERS_PROPS]: (ui, { payload = '' }) => ui.set('assetBearersProps', payload),
  [actions.SET_ADDWEBHOOK_STATUS]: (ui, { payload = '' }) => ui.set('addWebhookStatus', payload),
  [actions.SET_STATUS]: (ui, { payload = '' }) => ui.set('status', payload),
  [actions.START_NETWORK]: (ui, { payload = 'global' }) => ui.updateIn(['requests', payload], counter => (counter || 0) + 1),
  [actions.END_NETWORK]: (ui, { payload = 'global' }) => ui.updateIn(['requests', payload], counter => (counter || 0) - 1)
}, initialState)

export const getRequests = (state, label = 'global') => state.ui.getIn(['requests', label]) || 0
export const getColor = (state) => COLORS[state.ui.theme]
export const getAddAssetStatus = (state) => state.ui.addAssetStatus
export const getUpdateAssetStatus = (state) => state.ui.updateAssetStatus
export const getAssetsProps = (state) => state.ui.assetsProps
export const getAddOrganizationStatus = (state) => state.ui.addOrganizationStatus
export const getUpdateOrganizationStatus = (state) => state.ui.updateOrganizationStatus
export const getUiModal = (state) => state.ui.modal
export const getUiDialog = (state) => state.ui.dialog
export const getOptionsTableOrganization = (state) => state.ui.updateOptionsTableOrganization
export const getColumnsTableOrganization = (state) => state.ui.updateColumnsTableOrganization
export const getAddWebhookStatus = (state) => state.ui.addWebhookStatus
export const getStatus = (state) => state.ui.status

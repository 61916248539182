import * as actions from '../consts/action-types'
import { normalize } from 'normalizr'
import API from '../api'
const api = new API()

const symbols = ({ dispatch, getState }) => next => action => {
  const actiosApi = [actions.API, actions.APICOMPLETE, actions.API_WITH_CONTROL]
  if (!actiosApi.includes(action.type)) return next(action)

  const { success, error, schema } = action.payload

  switch (action.type) {
  case actions.API:
    api.request(action.payload)
      .then(data => {
        if (!data.error) {
          if (schema) data = normalize(data, schema)
          dispatch(success(data))
        } else {
          dispatch(error(data.error.message))
        }
      })
      .catch(error => {
        console.error(error)
      })
    break
  case actions.APICOMPLETE:
    api.request(action.payload)
      .then(data => {
        if (!data.error) {
          if (schema) data.data = normalize(data.data, schema)
          success(data, dispatch, getState)
        } else {
          error(data.error.message)
        }
      })
      .catch(error => {
        console.error(error)
      })
    break
  case actions.API_WITH_CONTROL:
    api.request(action.payload)
      .then(data => {
        if (!data.error) {
          if (schema) data = normalize(data, schema)
          success(data, dispatch, getState)
        } else {
          error(data.error.message, dispatch)
        }
      })
      .catch(error => {
        console.error(error)
      })
    break
  default:
  }
}

export default symbols

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { addAsset } from '../../actions/assets'
import SnackbarContent from '../Snackbar/SnackbarContent'
import { setAddAssetStatus } from 'actions/ui'
import { getAddAssetStatus } from '../../reducers/ui'
import ErrorIcon from '@material-ui/icons/Error'
import CheckIcon from '@material-ui/icons/Check'
import { withStyles } from '@material-ui/styles'
import { default as SelectEdit } from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { osOptions } from '../../consts'
import { MSG_STATUS } from '../../consts/assets'
import { getAssetBearersList } from '../../selectors/assetBearers'
import { fetchAssetBearersList } from '../../actions/assetBearers'
const styles = {
  status: {
    display: 'inline-flex',
    fontSize: '12px!important',
    lineHeight: '12px',
    marginBotom: '10px'
  },
  splitter: {
    marginTop: '2rem',
    display: 'block',
    border: '0',
    background: 'transparent'
  },
  form:{
    maxWidth: '300px',
    margin: '0 auto'
  },
  margin: {
    width: '100%'
  }
}
class AddAsset extends React.Component {
    constructor(props) {
      super(props);
      ValidatorForm.addValidationRule('isOptionSelectedInList', (value) => {
        if (!osOptions.includes(value)) {
            return false;
        }
        return true;
      });
    }
    state = {
      formData: {
        name: '',
        bearer: '',
        bearerId: '',
        os: ''
      },
      snackbar: true,
      submitted: false,
    }

    componentDidMount () {
      this.props.setAddAssetStatus('')
      this._isMounted = true;
    }

    printAssetBearers = (event) => {
      this.handleChange(event)
      this.props.fetchAssetBearersList(5,this.state.formData.bearer)
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleChange = (event) => {
      const { formData } = this.state
      formData[event.target.name] = event.target.value
      this.setState({ formData })
    }

    callbackAddAsset = () => {
      this._isMounted && this.setState({ submitted: false })
      this.props.status === MSG_STATUS.CREATED_OK && this.props.onComplete && this.props.onComplete()
    }

    getAssetBearerId = (email) =>{
      return this.props.assetBearers.filter(assetBearer => assetBearer.email === email)[0]?._id
    }

    handleSubmit = () => {
      const { formData } = this.state
      formData['bearerId'] = this.props.assetBearerId || this.getAssetBearerId(formData.bearer)
      this.setState({ formData, submitted: true })
      this.props.addAsset(this.state.formData, () => this.callbackAddAsset)
    }

    render () {
      const { formData, submitted } = this.state
      const { classes, assetBearers } = this.props
      const icon = (
        (this.props.status === MSG_STATUS.CREATED_OK)
          ? CheckIcon : ErrorIcon
      )
      return (
        <span>
          <ValidatorForm
            onSubmit={this.handleSubmit}
            instantValidate={true}
            className={classes.form} >
            {this.props.status !== ''
              ? <SnackbarContent
                message={this.props.status}
                open={this.state.snackbar}
                color='primary'
                icon={ icon }
                closeNotification={() => this.props.setAddAssetStatus('')}
                close
              />
              : null
            }
            <hr className={classes.splitter} />
            <TextValidator
              label='New Asset Name'
              onChange={this.handleChange}
              name='name'
              value={formData.name}
              className={classes.margin}
              validators={['required']}
              errorMessages={['this field is required']}
            />
            {!this.props.assetBearerId &&
            <>
              <hr className={classes.splitter} />
              <SelectEdit
              value={this.state.formData.bearer || ''}
              options={assetBearers}
              getOptionLabel={(assetBearer) => assetBearer.email || formData?.bearer}
              onBlur={this.printAssetBearers}
              renderInput={(params) => (
              <TextValidator
              {...params} 
                label='Person email'
                onChange={this.printAssetBearers}
                name='bearer'
                value={this.state.formData.bearer|| ''}
                className={classes.margin}
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'this is not a valid email']}
              />
              )}  
              />
            </>
            }
            <hr className={classes.splitter} />
            <SelectEdit
              value={formData.os}
              options={osOptions.map((option) => option)}
              onBlur={this.handleChange}
              renderInput={(params) => (
                <TextValidator 
                  {...params} 
                  value={formData.os}
                  name='os'
                  label='OS' 
                  variant='standard' 
                  validators={['required', 'isOptionSelectedInList']}
                  errorMessages={['this field is required', 'Enter an os of the list']}
                />
              )}
            />
            <hr className={classes.splitter} />
            <Button
              className='RegularButton-primary-200 RegularButton-button-197'
              color='primary'
              variant='contained'
              type='submit'
              disabled={submitted}
            >
              {
                (submitted && 'Sending ...') ||
                            (!submitted && 'Add Asset')
              }
            </Button>
          </ValidatorForm>
        </span>
      )
    }
}


const mapStateToProps = state => ({
  assetBearers: getAssetBearersList(state),
  status: getAddAssetStatus(state)
})

const mapDispatchToProps = {
  addAsset,
  setAddAssetStatus,
  fetchAssetBearersList
}

AddAsset.propTypes = {
  classes: PropTypes.object.isRequired,
  assetBearerId: PropTypes.string,
  onComplete: PropTypes.func,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AddAsset)

import * as actions from 'consts/action-types'
import * as schema from 'lib/schema'
import { CompareObjects } from '../utils'

export const setIncidents = (payload) => ({
  type: actions.SET_INCIDENTS,
  payload
})

export const setAssetsIncident = (payload) => ({
  type: actions.SET_ASSETS_INCIDENT,
  payload
})

export const fetchIncidents = (callback) => ({
  type: actions.API_WITH_CONTROL,
  payload: {
    url: '/api/incidents',
    method: 'GET',
    success: ({ entities }, dispatch, getState) => {
      const { incidents } = getState()
      entities?.incidents && !CompareObjects(incidents?.data, entities?.incidents) && dispatch(setIncidents(entities?.incidents))
      entities?.assets && !CompareObjects(incidents?.assetsIncident, entities?.assets) && dispatch(setAssetsIncident(entities?.assets))
      callback && callback()
    },
    schema: [schema.incident],
    label: 'incidents'
  }
})

export const dismissIncident = (id) => ({
  type: actions.API,
  payload: {
    url: `/api/incidents/${id}/dismiss`,
    method: 'POST',
    success: ({ entities }) => [
      setIncidents(entities.incidents)
    ],
    schema: schema.incident,
    label: 'incidents'
  }
})

export const API = 'API'
export const APICOMPLETE = 'APICOMPLETE'
export const API_WITH_CONTROL = 'API_WITH_CONTROL'
export const SET_WALLETS = 'SET_WALLETS'
export const DELETE_ASSET = 'DELETE_ASSET'
export const SET_ASSETS = 'SET_ASSETS'
export const SET_ASSET = 'SET_ASSET'
export const CLEAN_ASSET = 'CLEAN_ASSET'
export const SET_ASSETS_LIST = 'SET_ASSETS_LIST'
export const SET_ASSETS_PAGINATION_PROPS = 'SET_ASSETS_PAGINATION_PROPS'
export const SET_ASSETSPROPS = 'SET_ASSETSPROPS'
export const ASSET_ID = 'ASSET_ID'
export const DELETE_ASSETBEARER = 'DELETE_ASSETBEARER'
export const SET_ASSETBEARERS = 'SET_ASSETBEARERS'
export const SET_ASSETBEARERS_LIST = 'SET_ASSETBEARERS_LIST'
export const SET_ASSETBEARER_PAGINATION_PROPS = 'SET_ASSETBEARER_PAGINATION_PROPS'
export const SET_ASSETBEARER = 'SET_ASSETBEARER'
export const SET_ASSETBEARERS_PROPS = 'SET_ASSETBEARERS_PROPS'
export const SET_INCIDENTS = 'SET_INCIDENTS'
export const SET_ASSETS_INCIDENT = 'SET_ASSETS_INCIDENT'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_ORGANIZATIONS_SETTINGS = 'SET_ORGANIZATIONS_SETTINGS'
export const SET_SUMMARY = 'SET_SUMMARY'
export const SET_UI_MODAL = 'SET_UI_MODAL'
export const SET_UI_DIALOG = 'SET_UI_DIALOG'
export const SET_STATUS = 'SET_STATUS'
export const ADD_ASSET = 'ADD_ASSET'
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION'
export const ORGANIZATION_MERGE = 'ORGANIZATION_MERGE'
export const ASSET_MERGE = 'ASSET_MERGE'
export const ASSETBEARERS_MERGE = 'ASSETBEARERS_MERGE'
export const WALLET_MERGE = 'WALLET_MERGE'
export const SET_ADDASSET_STATUS = 'SET_ADDASSET_STATUS'
export const GET_ADDASSET_STATUS = 'GET_ADDASSET_STATUS'
export const SET_ADDASSETBEARER_STATUS = 'SET_ADDASSETBEARER_STATUS'
export const GET_ADDASSETBEARER_STATUS = 'GET_ADDASSETBEARER_STATUS'
export const SET_UPDATEASSET_STATUS = 'SET_UPDATEASSET_STATUS'
export const GET_UPDATEASSET_STATUS = 'GET_UPDATEASSET_STATUS'
export const SET_UPDATEASSETBEARER_STATUS = 'SET_UPDATEASSETBEARER_STATUS'
export const GET_UPDATEASSETBEARER_STATUS = 'GET_UPDATEASSETBEARER_STATUS'
export const SET_ADDORGANIZATION_STATUS = 'SET_ADDORGANIZATION_STATUS'
export const GET_ADDORGANIZATION_STATUS = 'GET_ADDORGANIZATION_STATUS'
export const SET_UPDATEORGANIZATION_STATUS = 'SET_UPDATEORGANIZATION_STATUS'
export const GET_UPDATEORGANIZATION_STATUS = 'GET_UPDATEORGANIZATION_STATUS'
export const SET_OPTIONS_TABLEORGANIZATION = 'SET_OPTIONS_TABLEORGANIZATION'
export const GET_COLUMNS_TABLEORGANIZATION = 'GET_COLUMNS_TABLEORGANIZATION'
export const SET_COLUMNS_TABLEORGANIZATION = 'SET_COLUMNS_TABLEORGANIZATION'
export const START_NETWORK = 'START_NETWORK'
export const END_NETWORK = 'END_NETWORK'
export const SWITCH_THEME = 'SWITCH_THEME'
export const SESSION_AUTHENTICATION = 'SESSION_AUTHENTICATION'
export const DISPATCH = 'DISPATCH'
export const SET_ADDWEBHOOK_STATUS = 'SET_ADDWEBHOOK_STATUS'
export const SET_WEBHOOKS = 'SET_WEBHOOKS'
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK'

import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import MUIDataTable from 'mui-datatables'
import { Add as AddIcon, Check as CheckIcon, Settings, Visibility as VisibilityIcon, AccountBalanceWallet, Person as PersonIcon, CloudDownload } from '@material-ui/icons'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { setUiModal, setUiDialog, setAddAssetStatus, setUpdateAssetStatus  } from '../../actions/ui'
import { getAssetsToTable, getAssetsProps, getWalletsToTable, getModal, getDialog, getAssetsUpdateStatus } from '../../selectors/assets'
import { notifyInstall, notifyAssetInstall, deleteWallet, deleteTransaction } from 'actions/wallets'
import { fetchAssetsWithPagination } from '../../actions/assets'
import { AssetsDialog } from './AssetsDialog.js'
import { AssetsModal } from './AssetsModal.js'
import { Button, CircularProgress, Fab, LinearProgress } from '@material-ui/core'
import Icon from 'components/Icon/Icon'
import { capitalizeFirstLetter, formatDateTime, encodeSearchAndFilters, getRiskColor, parseAssetStatus, timeAgo, getTablesMuiTheme } from '../../utils'
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_TIME_TO_REFETCH_INTERVAL, DEFAULT_DURATION_SNACKBAR, osOptions, osOptionsList } from '../../consts'
import { debounceSearchRender } from '../TeblesCommon/DebounceSearchRender';
import ActionButton from '../Buttons/ActionButton'
import Snackbar from '../Snackbar/Snackbar'
import auth from 'components/Login/auth'
import { AssetsDialogV2 } from './AssetsDialogV2'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  progress: {
    margin: '0'
  },
  risk: {
    marginLeft: '15px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.64)'
  },
  filterMessage: {
    textTransform: 'none'
  },
  wrapper: {
    margin: '0px',
    position: 'relative'
  },
  iconProgress: {
    color: '#3f51b5',
  },
  iconInstalled: {
    color: '#3f51b5',
  },
  iconUninstalled: {
    color: '#d9c0ff'
  },
  fabProgress: {
    color: 'primary',
    position: 'absolute',
    top: -2,
    left: -2,
    zIndex: 1
  },
  button: {
    color: "#3f51b5",
    '& a': {
      color: "#3f51b5",
    }
  }
})

class AssetsTable extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortOrder: {
        direction: 'desc',
        name: 'createdAt'
      },
      data: null,
      count: null,
      open: false,
      openModal: null,
      filtersToApply: {},
      tableStatePersist: {
        searchText: '',
        filterList: [[],[], (this.props.status && [this.props.status]) || [],[],[],[],[],[]],
        columns: []
      },
      _await: true
    }
    this.selectedWallet = {}
    this.selectedAsset = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.assets !== this.props.assets) {
      this.setState({
        _await: false,
      })
    }
  };

  changePage = (page, sortOrder, rowsPerPage=DEFAULT_ROWS_PER_PAGE, searchText = '', filters={}) => {
    this.setState({
      _await: true,
      page,
      sortOrder,
      rowsPerPage,
      searchText,
      filtersToApply: filters
    });

    let searchAndFiltersEncoded = encodeSearchAndFilters(filters, searchText)

    this.props.fetchAssetsWithPagination(page, rowsPerPage,sortOrder.name, sortOrder.direction, searchAndFiltersEncoded, this.callbackFetchAssets)
  };

  callbackFetchAssets = () => {
    this._isMounted && this.setState({ _await: false })
  }

  handleChange = (action, tableState) => {
    switch (action) {
      case 'propsUpdate':
        return null
      case 'search':
      case 'changeRowsPerPage':
      case 'changePage':
      case 'sort':
        this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage, tableState.searchText, this.state.filtersToApply);
        break;
      default:
    }
    this.setState({
      tableStatePersist: {
        searchText: tableState.searchText,
        filterList: tableState.filterList,
        columns: tableState.columns
      }
    })
  }

  getColumns = () => {
    const classes = this.props.classes

    let columns = [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: 'os',
        label: 'OS',
        options: {
          filter: true,
          sort: true,
          filterOptions: {
            names: osOptions,
            fullWidth: true
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            let os = value ? value.toLowerCase() : null
            return (
              <Icon title={value} name={!osOptionsList[os] ? 'other' : os } color='#777' size='2em' />
            )
          }
        }
      },
      {
        name: 'status',
        label: 'STATUS',
        options: {
          filter: true,
          sort: true,
          filterOptions: {
            names: ['Unprotected', 'Compromised', 'Protected', 'Agent Lost', 'Partially Compromised'],
            fullWidth: true
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div title={capitalizeFirstLetter(value)} className={classes.risk} style={{ backgroundColor: this.setRiskColor(tableMeta.rowData[7]) }} />
            )
          }
        }
      },
      {
        name: 'createdAt',
        label:  'Created',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div title={formatDateTime(value)}>{ timeAgo(value)}</div>
            )
          }
        }
      },
      {
        name: '_id',
        label:  'Version',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const version = this.getAgentVersion(value)
            return (
              <div title={version}>{version}</div>
            )
          }
        }
      },
      {
        name: 'bearer',
        label: 'Person',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.root}>
              <div className={classes.wrapper}>
                <Fab
                  aria-label='save'
                  color={value ? 'primary' : 'default'}
                  size='small'
                  onClick={() => this.props.history.push(`/assetBearer/${value}`)}
                >
                  <PersonIcon />
                </Fab>
              </div>
            </div>
            )
          }
        }
      },
      {
        name: '_id',
        label: 'Settings',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.root}>
                <div className={classes.wrapper}>
                  <Fab
                    aria-label='save'
                    color={value ? 'primary' : 'default'}
                    size='small'
                    onClick={() => this.handleOpenModal(value)}
                  >
                    < Settings />
                  </Fab>
                </div>
              </div>
            )
          }
        }
      },
      {
        name: '_id',
        label: 'Wallet',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            let isV2 = this.isV2(value)
            if (!isV2) {
              let hasWallet = this.hasWallet(value)
              let isInstalled = this.isWalletInstalled(value)
              return (
                <div className={classes.root}>
                  <div className={classes.wrapper}>
                    <Fab
                      aria-label='save'
                      color={hasWallet ? 'primary' : 'default'}
                      size='small'
                      onClick={() => { this.handleOpen(value) }}
                    >
                      {hasWallet
                        ? <AccountBalanceWallet className={isInstalled ? null : classes.iconUninstalled} />
                        : <AccountBalanceWallet className={classes.iconProgress} />}
                    </Fab>
                    {!hasWallet && <CircularProgress size={46} className={classes.fabProgress} />}
                  </div>
                </div>
              )
              } else {
                let hasTransaction = this.hasTransaction(value)
                let isTransactionConfirmed = this.isTransactionConfirmed(value)
                return <div className={classes.root}>
                  <div className={classes.wrapper}>
                    <Fab
                      aria-label='save'
                      color={hasTransaction ? 'primary' : 'default'}
                      size='small'
                      onClick={() => { this.handleOpen(value) }}
                    >
                      {hasTransaction
                        ? <AccountBalanceWallet className={isTransactionConfirmed ? null : classes.iconUninstalled} />
                        : <AccountBalanceWallet className={classes.iconProgress} />}
                    </Fab>
                    {!hasTransaction && <CircularProgress size={46} className={classes.fabProgress} />}
                  </div>
                </div>
              }
          }
        }
      },
      {
        name: '_id',
        label: 'Detail',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.root}>
                <div className={classes.wrapper}>
                  <Link to={`/asset/${value}`} >
                    <Fab
                      aria-label='save'
                      color={value ? 'primary' : 'default'}
                      size='small'
                    >
                      <VisibilityIcon />
                    </Fab>
                  </Link>
                </div>
              </div>
            )
          }
        }
      }
    ]

    for (let i = 0; i < columns.length; i++) {
      columns[i].options.filterList = this.state.tableStatePersist.filterList[i]
      if (this.state.tableStatePersist.columns[i] !== undefined) {
        if (this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
          columns[i].options.display = this.state.tableStatePersist.columns[i].display
      }
    }
    return columns
  }

  getSearchText = () => {
    return this.state.tableStatePersist.searchText
  }

  setRiskColor = (val) => {
    let asset = this.getAsset(val)
    return getRiskColor(asset)
  }

  getAsset = (val) => {
    let filter = this.props.assets.filter(asset => asset._id === val)
    return filter[0] ? filter[0] : {}
  }

  setSelectedWallet = (val) => {
    let filter = this.props.wallets.filter(wallet => wallet._id === val)
    this.selectedWallet = filter[0] ? filter[0] : {}
  }

  setSelectedAsset = (val) => {
    this.selectedAsset = this.getAsset(val)
  }

  hasWallet = (val) => {
    let asset = this.getAsset(val)
    return !!((typeof asset.wallet !== 'undefined' && asset.wallet !== null))
  }

  hasTransaction = (val) => {
    let asset = this.getAsset(val)
    return (asset.transaction || asset.transaction !== null)
  }

  getAgentVersion = (val) => {
    let asset = this.getAsset(val)
    return asset?.agent?.version
  }
  
  isTransactionConfirmed = (val) => {
    let asset = this.getAsset(val)
    return !!(typeof asset.transactionConfirmed !== 'undefined' && asset.transactionConfirmed === true)
  }

  isV2 = (val) => {
    let asset = this.getAsset(val)
    return asset.version === 'v2'
  }

  isWalletInstalled = (val) => {
    let asset = this.getAsset(val)
    let wallet
    if (typeof asset.wallet !== 'undefined') {
      let filter = this.props.wallets.filter(wallet => wallet._id === asset.wallet)
      wallet = filter[0] ? filter[0] : {}
      return (typeof wallet.installed !== 'undefined') && wallet.installed
    }
  }

  downloadCSVUrl = () => {
    const {sortOrder, searchText, filtersToApply } = this.state

    let searchAndFiltersEncoded = encodeSearchAndFilters(filtersToApply, searchText)
    let url = `/api/assets/downloadcsv?`
            + `sortColumn=${sortOrder.name}`
            + `&sortDirection=${sortOrder.direction}`
            + `&searchAndFilters=${searchAndFiltersEncoded}`
    return url
  }

  setOpenModal = val => {
    this.props.setUiModal(val)
  }

  handleOpenModal = val => {
    this.setSelectedAsset(val)
    this.setOpenModal(true)
  };

  handleCloseModal = () => {
    this.setOpenModal(false)
  };

  setOpenDialog = val => {
    this.props.setUiDialog(val)
  }

  handleOpen = val => {
    let asset = this.getAsset(val)
    asset.wallet && this.setSelectedWallet(asset.wallet)
    this.setSelectedAsset(val)
    this.setOpenDialog(true)
  };

  handleClose = val => {
    let asset = this.getAsset(val)
    if (val) {
      if (asset.version === 'v1') {
        this.props.notifyInstall(val)
      } else {
        this.props.notifyAssetInstall(val)
      }
    }
    this.setOpenDialog(false)
  };

  componentDidMount () {
    this._isMounted = true;
    const {page, rowsPerPage, sortOrder} = this.state
    this.setState({
      filtersToApply: {status:this.props.status}
    });
    let searchAndFilters = encodeSearchAndFilters({status:this.props.status}) //add the filter of the dashboard cards of status

    this.props.fetchAssetsWithPagination(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchAndFilters, this.callbackFetchAssets)

    this.interval = setInterval(() => {
      if (this._isMounted === true){
        this.fetchAssetsCommon()
      }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval)
  }

  fetchAssetsCommon = () => {
    const {page, rowsPerPage, sortOrder, searchText, filtersToApply} = this.state
    let searchAndFiltersEncoded = encodeSearchAndFilters(filtersToApply, searchText)
    this.props.fetchAssetsWithPagination(page, rowsPerPage, sortOrder.name, sortOrder.direction, searchAndFiltersEncoded, this.callbackFetchAssets)
  }

  handleFilterSubmit = (applyFilters, columns) => {
    let filterList = applyFilters()
    let filtersToApply = {}
    for (let index = 0; index < filterList.length; ++index) {
      if (filterList[index][0] !== undefined){
        filtersToApply[columns[index].name] = filterList[index][0]
      }
    }

    const assetStatus = filtersToApply['status']
    filtersToApply['status'] = parseAssetStatus(assetStatus)

    const {sortOrder, rowsPerPage, searchText} = this.state
    this.changePage(0, sortOrder, rowsPerPage, searchText, filtersToApply)
  };

  isFirstLoadingData = () => {
    return !this.props.assetsProps.totalCount && this.props.assetsProps.totalCount !== 0
  }

  closeSnackbar = () => {
    this.fetchAssetsCommon()
    this.props.setAddAssetStatus('')
    this.props.setUpdateAssetStatus('')
  }

  isStatusOk() {
    return this.props.statusMessage.indexOf('OK') !== -1
  }

  render () {
    const { _await } = this.state
    const classes = this.props.classes
    const columns = this.getColumns()
    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      print: false,
      download: false,
      count: this.props.assetsProps.totalCount,
      rowsPerPage: this.props.assetsProps.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
      rowsPerPageOptions: [10, 25, 50, 100],
      serverSide: true,
      searchText: this.getSearchText(),
      selectableRows: 'none',
      sortOrder: this.state.sortOrder,
      confirmFilters: true,
      customToolbar: () => {
        return (
          <>
          <ActionButton handleClick={() => null} title={'Download CSV'} customClass={classes.button}>
            <a href={auth.getAuthenticatedUri(this.downloadCSVUrl())}>
              <CloudDownload/>
            </a>
          </ActionButton>
          <ActionButton handleClick={() => this.handleOpenModal(null)} title={'Add Asset'} customClass={classes.button}>
            <AddIcon/>
          </ActionButton>
          </>
        )
      },
      setFilterChipProps: (colIndex, colName, data) => {
        return {
          label: colName.toUpperCase()+': '+data
        };
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant='contained' onClick={() => this.handleFilterSubmit(applyNewFilters, columns)}>Apply Filters</Button>
          </div>
        );
      },

      onFilterChange: (column, filterList, type) => {
        if (type === 'chip') { //When close a chip
          let newFilters = () => (filterList)
          this.handleFilterSubmit(newFilters, columns)
        }
      },
      textLabels: {
        body: {
          noMatch: <span className={classes.filterMessage}>No Assets found</span>
        }
      },
      onTableChange: (action, tableState) => this.handleChange(action, tableState),
      customSearchRender: debounceSearchRender(500),
    }
    return (
      <>
        <MuiThemeProvider theme={getTablesMuiTheme()}>
          <div className='Assets'>
          {this.props.statusMessage && <Snackbar
            message={this.props.statusMessage}
            open={this.props.statusMessage !== '' }
            color={this.isStatusOk() ? "success": "danger"}
            icon={ CheckIcon }
            closeNotification={() => this.closeSnackbar()}
            close
            autoHide={DEFAULT_DURATION_SNACKBAR}
          />}
            <div style={{ maxWidth: '100%' }}>
              <LinearProgress className={ classes.progress } style={{visibility:!_await&&'hidden'}} />
              {
              this.isFirstLoadingData()
                ? <LinearProgress />
                : <MUIDataTable
                    title=''
                    options={options}
                    columns={columns}
                    data={this.props.assets}
                  />
             }
            </div>
            
            {this.selectedAsset.version === 'v1' && <AssetsDialog selectedWallet={this.selectedWallet} open={this.props.dialog} deleteWallet={this.props.deleteWallet} onClose={this.handleClose} />}
            {this.selectedAsset.version === 'v2' && <AssetsDialogV2 selectedAsset={this.selectedAsset} open={this.props.dialog} deleteTransaction={this.props.deleteTransaction} onClose={this.handleClose} />}
            <AssetsModal selectedAsset={this.selectedAsset} open={this.props.modal} onClose={this.handleCloseModal} />
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

const mapStateToProps = state => ({
  assets: getAssetsToTable(state),
  assetsProps: getAssetsProps(state),
  wallets: getWalletsToTable(state),
  modal: getModal(state),
  dialog: getDialog(state),
  statusMessage: getAssetsUpdateStatus(state)
})

const mapDispatchToProps = {
  fetchAssetsWithPagination,
  notifyInstall,
  setUiModal,
  setUiDialog,
  setUpdateAssetStatus,
  setAddAssetStatus,
  deleteWallet,
  deleteTransaction,
  notifyAssetInstall
}

AssetsTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withRouter, connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AssetsTable)

import React from 'react'
import AssetsTable from '../../components/Assets/AssetsTable.js'
import SimplePage from '../../components/Templates/SimplePage'

function AssetsList (props) {
  return (
    <SimplePage title='Assets'>
      <AssetsTable status={ props.match.params.status } />
    </SimplePage>
  )
}

export default AssetsList

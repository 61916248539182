import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import { withRouter } from 'react-router-dom'
import CardBody from '../Card/CardBody'
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle'
import BarsChart from '../Chart/BarsChart'
import moment from 'moment'

class HistogramCard extends Component {
  render () {
    const { classes, title, axisTitle, data } = this.props

    return (
      <Card chart>
        <CardHeader color="primary">
          <BarsChart data={this.mapHistograms(data)} axisTitle={axisTitle}/>
        </CardHeader>
        <CardBody>
          <h4 className={classes.cardTitle}>{ title }</h4>
        </CardBody>
      </Card>
    )
  }

  mapHistograms (data, interval = 7) {
    const histograms = [...new Array(interval)]
      .map((_, idx) => {
        return {
          date: moment().endOf('week').subtract(idx, 'days'),
          value: 0
        }
      }).reverse()

    data.forEach(function (element) {
      const index = histograms.findIndex(el => {
        return moment(element.createdAt)
          .startOf('day')
          .isSame(el.date.startOf('day'))
      })
      if (index > -1) histograms[index].value += 1
    })
    return histograms
  }
}

HistogramCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(connect()(withStyles(dashboardStyle)(HistogramCard)))

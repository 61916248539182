import React from 'react'
import WebhooksTable from '../../components/Webhooks/WebhooksTable.js'
import SimplePage from '../../components/Templates/SimplePage'

const WebhooksList = () => (
  <SimplePage title='Webhooks'>
    <WebhooksTable />
  </SimplePage>
)

export default WebhooksList

import React, { useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withStyles } from '@material-ui/styles'
import { Add as AddIcon } from '@material-ui/icons'
import TitleWithHelp from '../Titles/TitleWithHelp'
import EmailList from './EmailList'
import ActionButton from '../Buttons/ActionButton'

const styles = {
  form: {
    padding: 0,
    position: 'relative'
  },
  save:{
    width:'100px',
    marginTop: '70px'
  },
  input: {
    width: '82%',
    marginTop: '20px',
    position: 'absolute',
    top: '38px',
    left: 0
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    width: '3vw',
    height: '3vw',
    padding: 0,
    float: 'right',
    position: 'absolute',
    right: '5px',
    top: '110px',
    marginTop: '-45px',
    '&:hover': {
      backgroundColor: '#2f41a5',
      color: '#fff',
    },
    '& svg':{
      fontSize: '1.8vw'
    }
  },
  list: {
    marginTop: '65px',
    width: '96%',
    marginLeft: '2%',
    borderBottom: '1px solid #ccc',
  },
  item: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    borderTop: '1px solid #ccc',
    alignItems: 'center'
  },
  state: {
    flex: '12%',
    textAlign: 'center',
    marginTop: '6px',
    cursor: 'pointer',
    '& .check':{
      color: '#3CB34D',
    },
    '& svg.pause':{
      color: '#CAC9DE',
    }
  },
  mail: {
    flex: '75%',
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica',
  },
  delete: {
    width: '2.5vw',
    height: '2.5vw',
    textAlign: 'center'
  }
}

const EmailListForm = ({values, updateFunction, classes}) => {
  const initialEmailList = values
  const [emailList, setEmailList] = useState(initialEmailList)
  const [value, setValue] = useState('')

  useEffect(() =>{
    ValidatorForm.addValidationRule('isEmailInList', (value) => {
      if (emailList.filter(item => item.email.toLowerCase() === value.toLowerCase()).length) {
          return false;
      }
      return true;
    });
  }, [emailList])


  const addEmail = email => {
    const updatedEmailList = [ {email, enabled: true}, ...emailList]
    setEmailList(updatedEmailList);
    updateFunction({emails: updatedEmailList})
    setValue('')
  };

  const deleteEmail = email =>{
    const updateEmailList = emailList.filter(e => e.email !== email)
    setEmailList(updateEmailList);
    updateFunction({emails: updateEmailList})
  }

  const formRef = useRef(null);
  const submitForm = () => {
    formRef.current.submit();
  }

  return (
  <>
    <ValidatorForm
      ref={formRef}
      onSubmit={()=>addEmail(value)}
      className={classes.form}
      instantValidate={true}>

      <TitleWithHelp
        title='ALERTS LIST'
        description='Description of alerts list'
      />
      <TextValidator
        label='Email'
        onChange={(e) => setValue(e.target.value)}
        value={value}
        name='email'
        className={classes.input}
        validators={['required', 'isEmail', 'isEmailInList']}
        errorMessages={['this field is required', 'this email is not valid', 'this email already exist']}
      />
      <ActionButton
        handleClick={() => submitForm()}
        customClass={classes.button}
        title={'Add Email'}>
        <AddIcon />
      </ActionButton>
      <EmailList emailList={emailList} deleteEmail={deleteEmail} />
    </ValidatorForm>
  </>
  )
}

export default withStyles(styles)(EmailListForm)

import { handleActions } from 'redux-actions'
import Immutable from 'seamless-immutable'
import * as actions from 'consts/action-types'

const initialState = Immutable({})

export default handleActions({
  [actions.SET_ORGANIZATIONS]: (organizations, { payload }) => Immutable(payload),
  [actions.SET_ORGANIZATION]: (organization, { payload }) => Immutable(payload),
  [actions.ORGANIZATION_MERGE]: (organizations, { payload }) => organizations.merge(Immutable(payload))
}, initialState)

import * as icons from '@material-ui/icons'

import AssetsList from 'views/AssetsList/AssetsList.jsx'
import AssetBearersList from 'views/AssetBearersList/AssetBearersList'
import DashboardPage from 'views/Dashboard/Dashboard.jsx'
import WebhooksList from 'views/WebhooksList/WebhooksList'
import Webhook from 'views/Configuration/Webhook'
import Configuration from 'views/Configuration/Configuration'
import Asset from 'views/Asset/Asset.jsx'
import AssetBearer from 'views/AssetBearer/AssetBearer.jsx'
import IncidentsList from 'views/IncidentsList/IncidentsList.jsx'
import AdminConsole from 'views/Admin/AdminConsole.jsx'
import { ADMIN_ROLE, USER_ROLE } from 'consts'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.Dashboard,
    component: DashboardPage,
    params: '',
    menu: 'sidebar'
  },
  {
    path: '/assets',
    name: 'Assets',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.Dns,
    component: AssetsList,
    params: '/:status?',
    menu: 'sidebar'
  },
  {
    path: '/assetBearers',
    name: 'People',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.People,
    component: AssetBearersList,
    params: '/:status?',
    menu: 'sidebar'
  },
  {
    path: '/incidents',
    name: 'Incidents',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.NotificationsActive,
    component: IncidentsList,
    params: '',
    menu: 'sidebar'
  },
  {
    path: '/configuration',
    name: 'Configuration',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.Settings,
    component: Configuration,
    params: '',
    menu: 'sidebar'
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.DeviceHub,
    component: WebhooksList,
    params: '',
    menu: 'sidebar'
  },
  {
    path: '/webhook',
    name: 'Webhook',
    roles: [ADMIN_ROLE, USER_ROLE],
    component: Webhook,
    params: '/:id?',
    menu: ''
  },
  {
    path: '/asset',
    name: 'Asset',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.Settings,
    component: Asset,
    params: '/:id?',
    menu: ''
  },
  {
    path: '/assetBearer',
    name: 'Person',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.Settings,
    component: AssetBearer,
    params: '/:id?',
    menu: ''
  },
  {
    path: '/admin',
    name: 'Admin',
    roles: [ADMIN_ROLE],
    icon: icons.VerifiedUser,
    component: AdminConsole,
    params: '',
    menu: 'sidebar'
  },
  {
    path: 'logout',
    name: 'Exit',
    roles: [ADMIN_ROLE, USER_ROLE],
    icon: icons.PowerSettingsNew,
    component: DashboardPage,
    params: '',
    menu: 'sidebar'
  }
]

const getRoutes = function (role = USER_ROLE) {
  return routes.filter(r => { return r.roles.includes(role) })
}

export default getRoutes

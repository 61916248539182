import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fetchIncidents } from '../../actions/incidents'
import { fetchSummary } from '../../actions/summary'
import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx'
import StatsCard from '../../components/Dashboard/StatsCard'
import { percentage } from '../../utils'
import HistogramCard from '../../components/Dashboard/HistogramCard'
import TimelineCard from '../../components/Dashboard/TimelineCard'
import { DEFAULT_TIME_TO_REFETCH_INTERVAL } from '../../consts'
import { getIncidentsToTable } from  '../../selectors/incidents'
import { getAssetsSummary, getPeoplesSummary, getHistoryAssets, getHistoryPeople } from  '../../selectors/dashboard'

class Dashboard extends React.Component {
  
  componentDidMount () {
    this._isMounted = true;
    this.props.fetchSummary()
    this.props.fetchIncidents()

    this.intervalIncidents = setInterval(() => {
    if (this._isMounted === true){
      this.props.fetchIncidents()
    }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)

    this.intervalSummary = setInterval(() => {
      if (this._isMounted === true){
        this.props.fetchSummary()
      }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }

  componentWillUnmount () {
    this._isMounted = false;
    clearInterval(this.intervalIncidents)
    clearInterval(this.intervalSummary)
  }

  render () {
    const { classes, assets, people, history, incidents } = this.props
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <span className={classes.subtitle}>Assets</span>
          </GridItem>
          <GridItem xs={12} md={6} sm={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Estimated'
                  value={Math.max(assets.total, assets.estimated)}
                  icon='asset'
                  footerLink='/assets'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Protected'
                  value={assets.protected}
                  percentage={percentage(assets.protected, assets.total, assets.estimated)}
                  icon='wallet-protected'
                  footerLink='/assets/protected'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Unprotected'
                  value={assets.unprotected}
                  percentage={percentage(assets.unprotected, assets.total, assets.estimated)}
                  icon='wallet-unprotected'
                  footerLink='/assets/unprotected'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Compromised'
                  value={assets.compromised}
                  percentage={percentage(assets.compromised, assets.total, assets.estimated)}
                  icon='incident'
                  footerLink='/assets/compromised'
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12}>
                <TimelineCard
                  data={history.assets}
                  title='Protected Assets'
                  axisTitle='Assets (%)'
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12}>
                <span className={classes.subtitle}>Incidents</span>
              </GridItem>
              <GridItem xs={12}>
                <HistogramCard
                  data={this.props.incidents}
                  title='Weekly Incidents'
                  axisTitle='Incidents'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Total'
                  value={incidents.length}
                  icon='incident'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Unsolved'
                  value={incidents.filter(
                    obj => !obj.dismissedByUser
                  ).length}
                  icon='incident'
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12}>
                <span className={classes.subtitle}>People</span>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Estimated'
                  value={Math.max(people.total, people.estimated)}
                  icon='people'
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <StatsCard
                  title='Protected'
                  value={people.protected}
                  percentage={percentage(people.protected, people.total, people.estimated)}
                  icon='people-protected'
                />
              </GridItem>
              <GridItem xs={12}>
                <TimelineCard
                  data={history.people}
                  title='Protected People'
                  axisTitle='People (%)'
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = function(state) {
  return {
    incidents: getIncidentsToTable(state),
    assets: getAssetsSummary(state),
    people: getPeoplesSummary(state),
    history: {
      assets: getHistoryAssets(state),
      people: getHistoryPeople(state)
    }
  }
}

const mapDispatchToProps = {
  fetchIncidents,
  fetchSummary
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(dashboardStyle)
)(Dashboard)

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { default as IncidentIcon } from '@material-ui/icons/NotificationImportant'
import { default as DismissedIcon } from '@material-ui/icons/DoneAll'
import { default as AssetIcon } from '@material-ui/icons/Dns'
import tasksStyle from '../../assets/jss/material-dashboard-react/components/tasksStyle.jsx'
import { dismissIncident } from 'actions/incidents'
import { formatDateTime } from '../../utils'

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assetLink: this.props.assetLink,
      open: true
    };
  }
  render () {
    const { classes, incidents } = this.props
    const tableCellClasses = classnames(classes.tableCell, {
      [classes.tableCellRTL]: false
    })

    return (
      <Table className={classes.table}>
        <TableBody>
          {incidents.map((incident, i) => (
            <TableRow key={i} className={classes.tableRow}>
              <TableCell className={tableCellClasses}>
                <IncidentIcon style={{ color: '#af2cc5' }} />
              </TableCell>
              <TableCell className={tableCellClasses}>
                {incident._id}<br />
                {formatDateTime(incident.createdAt)}
              </TableCell>
              {this.props.assetLink
                ? <TableCell className={tableCellClasses}>
                  <div className={classes.wrapper}>
                    <a href={`/asset/${incident.asset}`} >
                      <Fab
                        aria-label="save"
                        color={'primary'}
                        size="small"
                      >
                        <AssetIcon />
                      </Fab>
                    </a>
                  </div>
                </TableCell>
                : null}
              <TableCell className={classes.tableActions}>
                {incident.dismissed === 'not dismissed'
                  ? <Tooltip
                    id="tooltip-top-start"
                    title="Mark as solved"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Fab
                      aria-label="save"
                      color={'primary'}
                      size="small"
                      onClick={() => this.props.dismissIncident(incident._id)}
                    >
                      <DismissedIcon />
                    </Fab>
                  </Tooltip>
                  : <div className={classes.fab}>
                    <div className={classes.fabInner} >
                      <DismissedIcon className={classes.primary} />
                    </div>
                  </div>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

Events.propTypes = {
  classes: PropTypes.object.isRequired,
  incidents: PropTypes.array.isRequired,
  assetLink: PropTypes.bool
}

const mapDispatchToProps = {
  dismissIncident
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(tasksStyle)
)(Events)

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updateAsset, deleteAsset, cleanAsset } from 'actions/assets'
import { setUiDialog, setUpdateAssetStatus } from 'actions/ui'
import { notifyInstall, notifyAssetInstall, deleteWallet } from '../../actions/wallets'
import SnackbarContent from '../Snackbar/SnackbarContent'
import { AssetsDialog } from 'components/Assets/AssetsDialog.js'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Fab, InputAdornment, LinearProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { formatDateTime, getRisk } from '../../utils'
import { AccountBalanceWallet, Check, Dns, Error, MailOutline } from '@material-ui/icons'
import { Alert as MuiAlert, Autocomplete as SelectEdit } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
//
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
//
import EventsList from '../Events/EventsList.jsx'
import { osOptions, DEFAULT_TIME_TO_REFETCH_INTERVAL } from '../../consts'
import { MSG_STATUS } from '../../consts/assets'
import { getIncidentsToTable } from '../../selectors/incidents'
import { getAssetsToTable, getWalletsToTable, getDialog, getAssetsOnlyUpdateStatus, getAsset } from '../../selectors/assets'
import { getAssetById } from '../../actions/assets'
import { AssetsDialogV2 } from 'components/Assets/AssetsDialogV2'
const styles = {
  root: {
    marginLeft: '30%',
    display: 'flex',
    alignItems: 'center'
  },
  risk: {
    marginLeft: '45%',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.64)'
  },
  walletDate: {
    textAlign: 'right',
    padding: '10px'
  },
  delete: {
    backgroundColor: '#9396bd',
    width: '40%',
    marginLeft: '5%',
    whiteSpace: 'nowrap',
    minWidth: '120px',
    marginTop: '10px',
    textShadow: '1px 1px 5px rgba(15,30,50,.3)'
  },
  save: {
    minWidth: '100px',
    marginRight: '5%',
    marginLeft: '5%',
    width: '40%',
    marginTop: '10px'
  },
  message: {
    fontWeight: '400',
    backgroundColor: '#f4f4f4',
    color: '#314091',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.1)'
  },
  title: {
    borderBottom: 'solid 2px rgba(0,0,0,0.15)',
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#666',
    letterSpacing: '0.01071em',
    marginTop: '50px',
    marginBottom: '40px',
    paddingBottom: '8px'
  },
  wrapper: {
    margin: '0px',
    position: 'relative',
    width: '20px'
  },
  status: {
    display: 'inline-flex',
    fontSize: '12px!important',
    lineHeight: '12px',
    marginBotom: '10px'
  },
  label: {
    fontSize: '12px!important',
    lineHeight: '1.5',
    marginBottom: '5px',
    color: '#777'
  },
  tableCell: {
    border: '0',
    paddingBottom: '25px'
  },
  margin: {
    width: '100%'
  },
  statusW: {
    paddingLeft: '30px'
  },
  alignCenter: {
    textAlign: 'center'
  },
  splitter: {
    marginTop: '2rem',
    display: 'block',
    border: '0',
    background: 'transparent'
  },
  iconProgress: {
    color: '#3f51b5'
  },
  iconInstalled: {
    color: '#3f51b5'
  },
  iconUninstalled: {
    color: '#d9c0ff'
  },
  icon: {
    color: '#3f51b5'
  },
  fabProgress: {
    color: 'primary',
    position: 'absolute',
    top: -2,
    left: -2,
    zIndex: 1
  }
}

function Message(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
class EditAsset extends React.Component {
  state = {
    formData: {
      id: null,
      os: '',
      name: '',
      wallet: null,
      bearer: {
        email: ''
      }
    },
    snackbar: true,
    submitted: false,
    _await: true,
    confirm: false
  }

  fieldRef = React.createRef()

  handleBlur = (event) => {
    this.fieldRef.current.validate(event.target.value)
  }

  setWallet = (val) => {
    let filter = this.props.wallets.filter(wallet => wallet._id === val)
    let _wallet = filter[0] ? filter[0] : {}
    this.setState({ wallet: _wallet })
  }

  onFocus(val) {
    this.setState({
      formData: { name: val }
    })
  }

  setAsset() {
    let asset = this.getAsset()
    asset._id && this.setState({
      formData: {
        id: asset._id,
        name: asset.name,
        os: asset.os,
        wallet: asset?.wallet,
        bearer: {
          email: asset.bearer ? asset.bearer.email : ''
        }
      }
    })
  }

  componentDidMount() {
    this.props.getAssetById(this.props.selectedAsset)
    this.props.setUpdateAssetStatus('')
    setTimeout(() => this.setState({ _await: false }), 1300)
    setTimeout(() => this.setAsset(), 500)
    this.interval = setInterval(() => {
      this.props.getAssetById(this.props.selectedAsset)
      this.getAsset()
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.formData.id === null) {
      this.setAsset()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.props.cleanAsset()
  }

  handleClose = val => {
    let asset = this.getAsset()
    if (val) {
      if (asset.version === 'v1') {
        this.props.notifyInstall(val)
      } else {
        this.props.notifyAssetInstall(val)
      }
    }
    this.setOpenDialog(false)
  };

  handleChange = (event) => {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleSelect = (event) => {
    const { formData } = this.state
    formData.os = event.target.value
    this.setState({ formData })
  }

  dialogConfirm = (bool) => this.setState({ confirm: bool })

  dialogConfirmDelete = () => {
    this.setState({ confirm: false, submitted: false })
    this.props.deleteAsset(this.state.formData.id)
  }

  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 1100)
      this.props.updateAsset(this.state.formData)
      this.props.setUpdateAssetStatus('')
    })
  }

  setOpenDialog = (val) => this.props.setUiDialog(val)

  handleOpen = () => this.setOpenDialog(true)

  isV2 = () => {
    let asset = this.getAsset()
    return asset.version === 'v2'
  }


  hasTransaction = () => {
    let asset = this.getAsset()
    return (asset.transaction || asset.transaction !== null)
  }

  isTransactionConfirmed = () => {
    let asset = this.getAsset()
    return !!(typeof asset.transactionConfirmed !== 'undefined' && asset.transactionConfirmed === true)
  }

  hasWallet = () => {
    let asset = this.getAsset()
    return !!((typeof asset.wallet !== 'undefined' && asset.wallet !== null))
  }

  isWalletInstalled = () => {
    let wallet = this.getWallet()
    return (wallet !== null && typeof wallet.installed !== 'undefined') && wallet.installed
  }

  getAsset = () => {
    return this.props.asset || {}
  }

  getWallet = () => {
    let asset = this.getAsset()
    if (asset?.wallet) {
      return asset?.wallet
    }
    return null
  }


  renderAssetStatus(classes) {
    let isV2 = this.isV2()
    if (!isV2) {
      let hasWallet = this.hasWallet()
      let isInstalled = this.isWalletInstalled()
      return (
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <Fab
              aria-label='save'
              color={hasWallet ? 'primary' : 'default'}
              size='small'
              onClick={() => { this.handleOpen(this.props.selectedAsset) }}
            >
              {hasWallet
                ? <AccountBalanceWallet className={isInstalled ? null : classes.iconUninstalled} />
                : <AccountBalanceWallet className={classes.iconProgress} />}
            </Fab>
            {!hasWallet && <CircularProgress size={46} className={classes.fabProgress} />}
          </div>
        </div>
      )
    } else {
      let hasTransaction = this.hasTransaction()
      let isTransactionConfirmed = this.isTransactionConfirmed()
      return <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            aria-label='save'
            color={hasTransaction ? 'primary' : 'default'}
            size='small'
            onClick={() => { this.handleOpen(this.props.selectedAsset) }}
          >
            {hasTransaction
              ? <AccountBalanceWallet className={isTransactionConfirmed ? null : classes.iconUninstalled} />
              : <AccountBalanceWallet className={classes.iconProgress} />}
          </Fab>
          {!hasTransaction && <CircularProgress size={46} className={classes.fabProgress} />}
        </div>
      </div>
    }
  }

  getIncidents = () => {
    let filter = this.props.incidents.filter(incident => incident.assets.includes(this.props.selectedAsset)).map(obj => ({ ...obj, dismissed: obj.dismissedByUser ? 'dismissed' : 'not dismissed' }))
    return filter.length > 0 ? filter : null
  }

  checkDeleted = (status) => status.indexOf('Deleted') >= 0 ? setTimeout(window.open('/assets', '_self'), 500) : null
  render() {
    const { formData, submitted, confirm, snackbar, _await } = this.state
    const classes = this.props.classes
    const icon = (
      (this.props.status === MSG_STATUS.CREATED_OK) ? Check : Error
    )
    this.checkDeleted(this.props.status)
    let { asset } = this.props
    let wallet = this.getWallet()
    let isV2 = this.isV2()
    let hasTransaction = this.hasTransaction()
    let incidents = this.getIncidents()
    let hasWallet = this.hasWallet()
    let isInstalled = this.isWalletInstalled()
    let risk = getRisk(asset)
    if (asset._id) {
      return (<GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ValidatorForm
            onSubmit={this.handleSubmit}
            instantValidate={false} >
            <h6 className={classes.title} >Asset Data</h6>
            {this.props.status !== ''
              ? <SnackbarContent
                message={this.props.status}
                open={snackbar}
                color="primary"
                icon={icon}
                closeNotification={() => this.props.setUpdateAssetStatus('')}
                close
              />
              : null
            }
            <TextValidator
              ref={this.fieldRef}
              name="name"
              value={formData.name}
              validators={['required']}
              errorMessages={['this field is required']}
              className={classes.margin}
              label="Name"
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: !!formData?.name }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Dns className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />
            <hr className={classes.splitter} />
            <TextValidator
              className={classes.margin}
              name='email'
              label="Contact Email"
              value={asset.bearer ? asset.bearer.email : ''}
              disabled={true}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: !!asset?.bearer?.email }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutline className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />
            <hr className={classes.splitter} />
            <TextValidator
              className={classes.margin}
              name='version'
              label="Agent Version"
              value={asset.agent ? asset.agent.version : ''}
              disabled={true}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: !!asset?.agent?.version }}
            />
            <hr className={classes.splitter} />
            <SelectEdit
              freeSolo
              name='os'
              value={formData.os}
              options={osOptions.map((option) => option)}
              onBlur={this.handleSelect}
              renderInput={(params) => (
                <TextValidator {...params} label="OS" variant="standard" />
              )}
            />
            <hr className={classes.splitter} />
            <Button
              className={`RegularButton-primary-200 RegularButton-button-197 ${classes.save}`}
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitted}
            >
              {'Save'}
            </Button>
            <Button
              className={`RegularButton-primary-200 RegularButton-button-197 ${classes.delete}`}
              color="primary"
              variant="contained"
              type="button"
              disabled={submitted}
              onClick={() => this.dialogConfirm(true)}
            >
              {'Delete Asset'}
            </Button>
            <hr className={classes.splitter} />
            <div className={classes.walletDate} >&bull; Created on {formatDateTime(asset.createdAt)}</div>
            <Dialog open={confirm} >
              <DialogContent>Really want to delete this asset?.</DialogContent>
              <DialogActions>
                <Button onClick={() => this.dialogConfirm(false)} color="primary">CANCEL</Button>
                <Button onClick={() => this.dialogConfirmDelete(false)} color="primary">CONFIRM</Button>
              </DialogActions>
            </Dialog>
          </ValidatorForm>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {typeof asset._id !== 'undefined'
            ? <>
              <h6 className={classes.title}>Asset Status</h6>
              <Table >
                <TableBody>
                  <TableRow >
                    <TableCell className={classes.tableCell} >
                      <div className={classes.risk} style={{ backgroundColor: risk.color }} />
                    </TableCell>
                    <TableCell className={classes.tableCell} >
                      <div className={classes.root}>
                        <div className={classes.wrapper}>
                          {this.renderAssetStatus(classes)}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Message severity={risk.type} className={classes.message} >{risk.text}</Message>
            </>
            : null
          }
          {isV2 === false && wallet !== null
            ? <>
              <AssetsDialog selectedWallet={wallet} open={this.props.dialog} deleteWallet={this.props.deleteWallet} onClose={this.handleClose} />
            </>
            : null
          }
          {isV2 === true ? <> <AssetsDialogV2 selectedAsset={asset} open={this.props.dialog} deleteTransaction={this.props.deleteTransaction} onClose={this.handleClose} /></> : null}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {typeof asset._id !== 'undefined'
            ? <>
              <h6 className={classes.title}>Incidents</h6>
              {_await
                ? <LinearProgress />
                : incidents !== null
                  ? <EventsList
                    assetLink={!1}
                    incidents={incidents}
                  />
                  : <Message severity="success" className={classes.message} >There are no associated incidents</Message>
              }
            </>
            : null
          }
        </GridItem>
      </GridContainer>)
    } else {
      return <LinearProgress />
    }
  }
}
const mapStateToProps = state => ({
  asset: getAsset(state),
  wallets: getWalletsToTable(state),
  incidents: getIncidentsToTable(state),
  status: getAssetsOnlyUpdateStatus(state),
  dialog: getDialog(state)
})

const mapDispatchToProps = {
  updateAsset,
  deleteAsset,
  deleteWallet,
  notifyInstall,
  notifyAssetInstall,
  setUiDialog,
  setUpdateAssetStatus,
  getAssetById,
  cleanAsset
}

EditAsset.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedAsset: PropTypes.string.isRequired
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(EditAsset)

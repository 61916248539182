import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogActions: {
    justifyContent: 'space-evenly',
    margin: '10px'
  },
}));

const ConfirmCalcelDialog = ({onConfirm, onCancel, showDialog, title}) => {
  const classes = useStyles();

  return (
    <Dialog open={showDialog} onClose={onCancel} TransitionComponent={Transition}>
      <DialogContent>{title}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => onCancel()} variant="contained" color="primary" >Cancel</Button>
        <Button onClick={() => onConfirm()} variant="contained" color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
)}
ConfirmCalcelDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showDialog:PropTypes.bool.isRequired,
  title:PropTypes.string.isRequired,
}
export default ConfirmCalcelDialog

import {
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  infoColor,
  successColor,
  dangerColor,
  roseColor,
  primaryColor,
  warningColor,
  hexToRgb
} from 'assets/jss/material-dashboard-react.jsx'

const snackbarStyle = {
  root: {
    ...defaultFont,
    flexWrap: 'unset',
    position: 'relative',
    padding: '20px 15px',
    lineHeight: '20px',
    marginBottom: '20px',
    fontSize: '14px',
    backgroundColor: whiteColor,
    color: grayColor[7],
    borderRadius: '3px',
    minWidth: 'unset',
    maxWidth: 'unset',
    boxShadow:
      '0 12px 20px -10px rgba(' +
      hexToRgb(whiteColor) +
      ', 0.28), 0 4px 20px 0px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 7px 8px -5px rgba(' +
      hexToRgb(whiteColor) +
      ', 0.2)'
  },
  info: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: infoColor[3],
      color: whiteColor,
      ...infoBoxShadow
    }
  },
  success: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: successColor[3],
      color: whiteColor,
      ...successBoxShadow
    }
  },
  warning: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: warningColor[3],
      color: whiteColor,
      ...warningBoxShadow
    }
  },
  danger: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: dangerColor[3],
      color: whiteColor,
      ...dangerBoxShadow
    }
  },
  primary: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: primaryColor[3],
      color: whiteColor,
      ...primaryBoxShadow
    }
  },
  rose: {
    "& .MuiSnackbarContent-root":{
      backgroundColor: roseColor[3],
      color: whiteColor,
      ...roseBoxShadow
    }
  },
  iconButton: {
    width: '24px',
    height: '24px',
    padding: '0px'
  },
  icon: {
    display: 'block',
    left: '15px',
    position: 'absolute',
    top: '50%',
    marginTop: '-15px',
    width: '30px',
    height: '30px'
  },
  iconMessage: {
    paddingLeft: '50px',
    display: 'block'
  },
}
export default snackbarStyle

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import EmailListForm from './EmailListForm'
import EmailForm from './EmailForm'
import VariablesForm from './VariablesForm'
import { updateSettings, fetchSettings } from '../../actions/organizations'
import { setStatus } from '../../actions/ui'
import { getStatus } from '../../reducers/ui'
import { CleanObjectOfState, objectIsEmpty, isStatusOk } from '../../utils'
import Snackbar from '../Snackbar/Snackbar'
import { Check, Error } from '@material-ui/icons'
import { DEFAULT_DURATION_SNACKBAR } from '../../consts'
import ClaimAWalletForm from './ClaimAWalletForm'

const claimAWalletFlag = process.env.REACT_APP_CLAIM_A_WALLET_ENABLED === 'true'

const OrganizationSettings = ({ fetchSettings, updateSettings, settings, status, setStatus }) => {
  const icon = (
    (isStatusOk(status)) ? Check : Error
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchSettings() }, [])
  const { alertTemplate = {}, emails = [], regex, claimAWalletEnabled, oktaIssuer, certificate, emailList, ...otherSettings } = settings
  return (
    objectIsEmpty(CleanObjectOfState(settings)) ?
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <VariablesForm values={otherSettings} updateFunction={updateSettings} />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <EmailListForm values={emails} updateFunction={updateSettings} />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <EmailForm values={alertTemplate} updateFunction={updateSettings} />
        </GridItem>
        {claimAWalletFlag &&
          <GridItem xs={12} sm={12} md={12}>
            <ClaimAWalletForm values={{ regex, claimAWalletEnabled, oktaIssuer, certificate, emailList }} updateFunction={updateSettings} />
          </GridItem>
        }
        {status && isStatusOk(status) && <Snackbar
          message={status}
          open={status !== ''}
          color={isStatusOk(status) ? "success" : 'error'}
          icon={icon}
          closeNotification={() => setStatus('')}
          close
          autoHide={DEFAULT_DURATION_SNACKBAR}
        />
        }
      </GridContainer> :
      null
  )
}
const mapStateToProps = state => ({
  settings: state.settings,
  status: getStatus(state)
})

const mapDispatchToProps = {
  updateSettings,
  fetchSettings,
  setStatus
}

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrganizationSettings)

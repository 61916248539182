import { schema } from 'normalizr'

export const organization = new schema.Entity(
  'organizations',
  {},
  { idAttribute: '_id' }
)
export const wallet = new schema.Entity(
  'wallets',
  {},
  { idAttribute: '_id' }
)
export const asset = new schema.Entity(
  'assets',
  { organization: organization },
  { idAttribute: '_id' }
)

export const assetBearer = new schema.Entity(
  'assetBearers',
  { organization: organization },
  { idAttribute: '_id' }
)

export const incident = new schema.Entity(
  'incidents',
  {},
  { idAttribute: '_id' }
)

export const webhook = new schema.Entity(
  'webhooks',
  {},
  { idAttribute: '_id' }
)

assetBearer.define({
  organization
})
asset.define({
  organization,
  wallet
})
wallet.define({
  organization,
  asset
})
incident.define({
  organization,
  assets: [asset]
})

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Icon from '@material-ui/core/Icon'
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx'
import auth0Client from 'components/Login/auth'
// import Login from "components/Login/Login";

import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle.jsx'

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute (routeName) {
    return props.location.pathname === routeName
  }
  auth0Client.isAuthenticated()
  const signOut = () => {
    auth0Client.signOut()
  }
  const { classes, color, logo, image, logoText, routes } = props
  const _routes = routes.filter(route => route.menu !== '')
  var links = (
    <List className={classes.list}>
      {_routes.map((prop, key) => {
        var listItemClasses
        listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.path)
        })
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.path)
        })
        if (prop.name === 'Exit') {
          return (
            <NavLink
              to={ '#'}
              className={ classes.item }
              activeClassName="active"
              key={ key }
            >
              <ListItem button className={ classes.itemLink + listItemClasses }
                onClick={() => { signOut() }}
              >
                { typeof prop.icon === 'string' ? (
                  <Icon
                    className={ classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL] : props.rtlActive
                    }) }
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={ classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL] : props.rtlActive
                    })}
                  />
                )}
                <ListItemText
                  primary={
                    props.rtlActive ? prop.rtlName : prop.name
                  }
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL] : props.rtlActive
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          )
        }

        return (
          <NavLink
            to={ prop.path}
            className={ classes.item }
            activeClassName="active"
            key={ key }
          >
            <ListItem button className={ classes.itemLink + listItemClasses }>
              { typeof prop.icon === 'string' ? (
                <Icon
                  className={ classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL] : props.rtlActive
                  }) }
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={ classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL] : props.rtlActive
                  })}
                />
              )}
              <ListItemText
                primary={
                  props.rtlActive ? prop.rtlName : prop.name
                }
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL] : props.rtlActive
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        )
      })}

    </List>
  )
  var brand = (
    <div className={classes.logo}>
      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(sidebarStyle)(Sidebar)

import React, { Component } from 'react'
import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'
import { fetchWebhooks } from '../../actions/webhooks'
import { DEFAULT_TIME_TO_REFETCH_INTERVAL } from '../../consts'
import { formatDateTime, timeAgo, getTablesMuiTheme } from '../../utils'
import { getWebhooksToTable } from '../../selectors/webhooks'
import { Add as AddIcon, Check as CheckIcon, NotInterested as NotInterestedIcon, Visibility as VisibilityIcon } from '@material-ui/icons'
import ActionButton from '../Buttons/ActionButton'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    backgroundColor: '#f4f4f4',
    color: '#314091',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,.1)',
    margin: '20px 0',
    fontSize: '1rem',
    fontWeight: '400'
  },
  progress: {
    margin: '30px 0'
  },
  filterMessage: {
    textTransform: 'none'
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    width: '40px',
    height: '40px',
    padding: 0,
    "&:hover": {
      backgroundColor: '#2f41a5',
      color: '#fff',
    }
  },
  addButton: {
    color: "#3f51b5",
    '& a': {
      color: "#3f51b5",
    }
  },
  icon: {
    marginLeft: '18px'
  }
})

class WebhooksTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      tableStatePersist: {
        searchText: '',
        filterList: [],
        columns: [],
        sortOrder:{
          name: "updatedAt",
          direction: "desc"
        }
      },
      _await: true
    }
  }

  handleChange = (action, tableState) => {
    if (action !== 'propsUpdate') {
      this.setState({
        tableStatePersist: {
          searchText: tableState.searchText,
          filterList: tableState.filterList,
          columns: tableState.columns,
          sortOrder: tableState.sortOrder,
        }
      })
    }
  }

  getWebhook = (id) => {
    let filter = this.props.webhooks.filter(webhook => webhook._id === id)
    return filter[0] ? filter[0] : {}
  }

  callbackWebhooksFetch = () => {
    this._isMounted && this.setState({ _await: false })
  }

  componentDidMount () {
    this._isMounted = true;
    this.props.fetchWebhooks(this.callbackWebhooksFetch)
    this.intervalWebhooks = setInterval(() => {
      if (this._isMounted === true){
        this.props.fetchWebhooks(this.callbackWebhooksFetch)
      }
    }, DEFAULT_TIME_TO_REFETCH_INTERVAL)
  }

  componentWillUnmount () {
    this._isMounted = false;
    clearInterval(this.intervalWebhooks)
  }

  getColumns = () => {
    const classes = this.props.classes
    let columns = [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>{value}</div>
            )
          }
        }
      },
      {
        name: 'enabled',
        label: 'Enabled',
        options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>{value ?
                <Tooltip title='Enabled'><CheckIcon className={classes.icon} style={{ color: 'green' }} /></Tooltip> :
                <Tooltip title='Disabled'><NotInterestedIcon className={classes.icon} style={{ color: 'red' }}/></Tooltip>}
              </>
            )
          }
        }
      },
      {
        name: 'updatedAt',
        label: 'Updated',
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div title={formatDateTime(value)}>{ timeAgo(value) }</div>
            )
          }
        }
      },
      {
        name: '_id',
        label: 'Detail',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <ActionButton
                  handleClick={() => this.props.history.push(`/webhook/${value}`)}
                  customClass={classes.button}
                  title={'Edit Webhook'}>
                  <VisibilityIcon/>
                </ActionButton>
            )
          }
        }
      }
    ]
    for (let i = 0; i < columns.length; i++) {
      columns[i].options.filterList = this.state.tableStatePersist.filterList[i]
      if (this.state.tableStatePersist.columns[i] !== undefined) {
        if (this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
          columns[i].options.display = this.state.tableStatePersist.columns[i].display
      }
    }
    return columns
  }

  render () {
    const { _await, tableStatePersist } = this.state
    const { classes, webhooks } = this.props
    const options = {
      filterType: 'dropdown',
      responsive: 'standard',
      print: false,
      rowsPerPageOptions: [10, 25, 50, 100],
      selectableRows: 'none',
      sortOrder: tableStatePersist.sortOrder,
      textLabels: {
        body: {
          noMatch: _await
            ? <LinearProgress />
            : <span className={classes.filterMessage}>No Webhooks found</span>
        }
      },
      onTableChange: (action, tableState) => this.handleChange(action, tableState),
      onDownload:(buildHead, buildBody, columns, data) => {
        // Remove _id field from CSV. #826
        const _columns = []
        const _data = [{data: []}]
        for (let i=0; i<columns.length; i++) {
          if (columns[i].label !== 'Detail') {
            _columns.push(columns[i])
            _data[0].data.push(data[0].data[i])
          }
        }
        return  buildHead(_columns) + buildBody(_data);
      },
      customToolbar: () => {
        return (
          <>
          <ActionButton handleClick={() => this.props.history.push(`/webhook/`)} title={'Add Webhook'} customClass={classes.addButton}>
            <AddIcon/>
          </ActionButton>
          </>
        )
      },
    }
    const columns = this.getColumns()
    return (
      <>
        <div className='Webhooks'>
          <div style={{ maxWidth: '100%' }}>
            <MuiThemeProvider theme={getTablesMuiTheme()}>
              <MUIDataTable
                title={''}
                data={webhooks}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    webhooks: getWebhooksToTable(state)
  }
}

const mapDispatchToProps = {
  fetchWebhooks
}

WebhooksTable.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withRouter, connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(WebhooksTable)

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Snack from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import snackbarStyle from '../../assets/jss/material-dashboard-react/components/snackbarStyle.jsx'

const Snackbar = ({ ...props }) => {
  const {
    classes,
    message,
    close,
    icon,
    open,
    color,
    closeNotification,
    autoHide
  } = props
  var action = []
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined
  })
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => closeNotification()}
      >
        <Close className={classes.close} />
      </IconButton>
    ]
  }

  return (
    <Snack
      open={open}
      className={classes[color]}
      onClose={closeNotification}
      autoHideDuration={autoHide}
      message={
        <div>
          {icon !== undefined ? <props.icon className={classes.icon} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      action={action}

    />
  )
}

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
  close: PropTypes.bool,
  icon: PropTypes.object,
  place: PropTypes.oneOf(['tl', 'tr', 'tc', 'br', 'bl', 'bc']),
  open: PropTypes.bool,
  rtlActive: PropTypes.bool
}

export default withStyles(snackbarStyle)(Snackbar)
